import { ChangeEvent, ReactElement, useContext } from "react";
import styles from "./GrossAmountInput.module.css";
import {
  amountInCents,
  getEstimatedValue,
} from "pages/employer-of-record/payments/utils";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { ADDITIONAL_PAY_FORM_ROUTES } from "pages/employer-of-record/payments/constants";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../Setup/types";
import PaymentAmountInput from "../PaymentDetails/components/PaymentAmountInput/PaymentAmountInput";
import { FormField } from "@justworkshr/milo-form";

type Props = {
  index: number;
  currencySymbol: string;
  currency: string;
  exchangeRate: number | undefined;
};

export const GrossAmountInput = ({
  index,
  currencySymbol,
  currency,
  exchangeRate,
}: Props): ReactElement => {
  const formik = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { values, setFieldValue, touched, errors, handleBlur } = formik;
  const additionalPayContext = useContext(AdditionalPayContext);

  if (index === -1) {
    additionalPayContext?.setErrorMessage(
      "Unexpected error, please try again later."
    );
    additionalPayContext?.setCurrentStep("set-up");
  }

  const { formType } = useParams();
  const isOtherRowFilled = () => {
    if (formType !== ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT) {
      return false;
    }

    return values.paymentDetails.some(
      (detail, idx) => idx !== index && detail?.grossAmount
    );
  };

  const touchedRow: FormikTouched<PaymentDetailsType> | null =
    touched.paymentDetails && touched.paymentDetails[index]
      ? touched.paymentDetails[index]
      : null;

  const errorRow: FormikErrors<PaymentDetailsType> | null =
    errors.paymentDetails && errors.paymentDetails[index]
      ? (errors.paymentDetails[index] as PaymentDetailsType)
      : null;

  const getUpdatedMemberDetail = (
    name: string,
    value: string,
    index: number
  ) => {
    const paymentDetails = [...values.paymentDetails];
    paymentDetails[index] = {
      ...paymentDetails[index],
      [name]: value,
    };
    return paymentDetails;
  };

  const onBlurHandler = (
    event: React.FocusEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = Number(values.paymentDetails[index]?.grossAmount);

    if (!isNaN(value)) {
      handleBlur(event);
    }
  };

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    handleBlur(event);
    const { value } = event.target;
    const sanitizedValue = value && Number(value.replace(/[^0-9.-]/g, ""));
    const grossAmount = sanitizedValue
      ? amountInCents(sanitizedValue, currency)
      : value;

    setFieldValue(
      `paymentDetails`,
      getUpdatedMemberDetail("grossAmount", grossAmount, index)
    );
  };

  const estimatedValue = getEstimatedValue(
    values.paymentDetails[index]?.grossAmount,
    exchangeRate
  );

  return (
    <FormField
      aria-labelledby="_"
      required
      name=""
      error={touchedRow?.grossAmount ? errorRow?.grossAmount : ""}
    >
      <>
        <div className={styles.grossAmountWrapper}>
          <div
            className={`${styles.currency} ${
              errorRow?.grossAmount && touchedRow?.grossAmount
                ? styles.error
                : ""
            }`}
          >
            <span>{currencySymbol}</span> <span>{currency}</span>
          </div>
          <div className={styles.inputWrap}>
            <PaymentAmountInput
              disabled={isOtherRowFilled()}
              name={`paymentDetails[${index}].grossAmount`}
              value={values.paymentDetails[index]?.grossAmount || undefined}
              className={`${styles.currencyInput} ${
                errorRow?.grossAmount && touchedRow?.grossAmount
                  ? styles.error
                  : ""
              }`}
              currency={currency}
              onBlur={(event) => onBlurHandler(event, index)}
              onChange={(event) => onChangeHandler(event, index)}
            />
          </div>
        </div>
        {!errorRow?.grossAmount && estimatedValue && (
          <div className={styles.estimatedValue}>{estimatedValue}</div>
        )}
      </>
    </FormField>
  );
};
