import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import { BONUS_INITIAL_VALUES as CANADA_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/CA/initialValues";
import { BONUS_INITIAL_VALUES as DEFAULT_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/DEFAULT/initialValues";
import { COMISSION_INITIAL_VALUES as CANADA_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/CA/initialValues";
import { COMISSION_INITIAL_VALUES as DEFAULT_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/DEFAULT/initialValues";
import { RETROACTIVE_INITIAL_VALUES as DEFAULT_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/DEFAULT/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as DEFAULT_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/DEFAULT/initialValues";

import { CountryConfigKeyType } from "./types";

const default_map_object = {
  [EmployeePayType.BONUS]: DEFAULT_BONUS_INITIAL_VALUES,
  [EmployeePayType.COMISSION]: DEFAULT_COMISSION_INITIAL_VALUES,
  [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_INITIAL_VALUES,
  [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_INITIAL_VALUES,
};

const CONFIG_MAPS = {
  [CountryCode.AU]: default_map_object,
  [CountryCode.BR]: default_map_object,
  [CountryCode.CA]: {
    [EmployeePayType.BONUS]: CANADA_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: CANADA_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.CL]: default_map_object,
  [CountryCode.CO]: default_map_object,
  [CountryCode.CR]: default_map_object,
  [CountryCode.DK]: default_map_object,
  [CountryCode.ES]: default_map_object,
  [CountryCode.GB]: default_map_object,
  [CountryCode.IE]: default_map_object,
  [CountryCode.IN]: default_map_object,
  [CountryCode.MX]: default_map_object,
  [CountryCode.MY]: default_map_object,
  [CountryCode.NL]: default_map_object,
  [CountryCode.PT]: default_map_object,
  [CountryCode.PH]: default_map_object,
  [CountryCode.SE]: default_map_object,
  [CountryCode.SG]: default_map_object,
};

export const loadFormInitialValues = (
  type: EmployeePayType,
  country: CountryConfigKeyType
) => {
  const configs = CONFIG_MAPS[country][type];
  return configs;
};
