import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";
import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, REQUIRED } from "./utils";
import * as FRANCE from "pages/employer-of-record/onboarding/profile-info/form/fr/constants.fr";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";

export const franceFields = {
  frTitle: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "frTitle",
    label: "Civilité du salarié (Title)",
    placeholder: "Select Title",
    options: FRANCE.TITLE_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("FR"),
    },
    value: () => "", // TODO: Expose frTitle
  }),
  frMaidenName: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "frMaidenName",
    label: "Nom de jeune fille (Maiden name)",
    scenarios: {
      onboarding: countryIs("FR"),
    },
    value: () => "", // TODO: Expose frMaidenName
  }),
  frSsn: field({
    type: FieldTypes.text,
    name: "frSsn",
    label: "Numéro de Sécurité Sociale (Social Security Number)",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 15,
          message: "Must be a valid SSN",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "identification",
    value: () => "", //TODO: Expose frSsn
  }),
  frBirthCountry: field({
    type: FieldTypes.select,
    name: "frBirthCountry",
    label: "Pays de naissance (Country of Birth)",
    validations: REQUIRED,
    placeholder: "Select Country",
    options: NATIONALITIES,
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "identification",
    value: () => "", //TODO: Expose frBirthCountry
  }),
  frBirthProvince: field({
    type: FieldTypes.select,
    name: "frBirthProvince",
    label: "Département de naissance (Province of Birth)",
    placeholder: "Select Province of Birth",
    options: [],
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "identification",
    hideWhen: {
      frBirthCountry: (value) => typeof value === "string" && value !== "FR",
    },
    value: () => "", //TODO: Expose frBirthProvince
  }),

  frBirthPlace: field({
    type: FieldTypes.text,
    name: "frBirthPlace",
    label: "Lieu de naissance (Place of Birth)",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "identification",
    value: () => "", //TODO: Expose frBirthPlace
  }),
  frEDoc: field({
    type: FieldTypes.boolean,
    name: "frEDoc",
    label: "Secure Digital Payslip",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "identification",
    value: () => false, //TODO: Expose frEDoc
  }),
  frHighestDegree: field({
    type: FieldTypes.select,
    name: "frHighestDegree",
    label: "Diplôme (Highest Degree of qualification)",
    validations: REQUIRED,
    placeholder: "Select Highest Degree",
    options: FRANCE.DEGREE_OPTIONS,
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "education",
    value: () => "", //TODO: Expose frHighestDegree
  }),
  frMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "frMaritalStatus",
    label: "Marital status",
    placeholder: "Select Marital status",
    validations: REQUIRED,
    options: FRANCE.MARITAL_STATUS_OPTIONS,
    scenarios: {
      onboarding: countryIs("FR"),
    },
    value: () => "", //TODO: Expose frMaritalStatus
  }),
  frNumDependents: field({
    type: FieldTypes.number,
    name: "frNumDependents",
    label: "Nombre à charge (Number of dependents)",
    validations: REQUIRED,
    placeholder: "Select Number of Dependents",
    scenarios: {
      onboarding: countryIs("FR"),
    },
    informationType: "family",
    value: () => "", //TODO: Expose frNumDependents
  }),
  frNif: identifierNumberFieldConfig("FR"),
  frConfirmNif: confirmIdentifierNumberFieldConfig("FR"),
};
