import { Dispatch, SetStateAction, useEffect } from "react";
import { loadCountryConfigFiles } from "../countryConfigLoader";
import { FormPayTypeValue } from "pages/employer-of-record/payments/constants";
import { addDisbursementDate } from "pages/employer-of-record/payments/utils";
import { AdditionalPayContextProps } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig } from "../../SetupFormGenerator";
import { CountryConfigKeyType } from "../types";
import { FormPayloadType } from "../../../types";

function useCountryConfigFetcher<T extends FormPayloadType = FormPayloadType>(
  type: FormPayTypeValue,
  additionalPayContext: AdditionalPayContextProps | null,
  setPaymentSettingsFields: Dispatch<SetStateAction<FieldConfig<T>[]>>,
  setFormFields?: Dispatch<SetStateAction<FieldConfig<T>[]>>
): void {
  const selectedCountry = additionalPayContext?.selectedCountry;
  useEffect(() => {
    const fetchCountryConfigs = async () => {
      if (!selectedCountry) return;
      const configs = await loadCountryConfigFiles(
        type,
        selectedCountry as CountryConfigKeyType
      );
      let paymentSettingsFormFields = configs.PAYMENT_SETTINGS_FORM_FIELDS;
      if (additionalPayContext?.disbursementDateOptions) {
        paymentSettingsFormFields = addDisbursementDate(
          paymentSettingsFormFields,
          Object.values(additionalPayContext.disbursementDateOptions)
        );
      }
      setPaymentSettingsFields(paymentSettingsFormFields);
      if (setFormFields && "TAX_FORM_FIELDS" in configs) {
        // This can either have complex type narrowing,
        // or we can just cast it to FieldConfig[]
        setFormFields(configs.TAX_FORM_FIELDS as FieldConfig<T>[]);
      }
    };

    fetchCountryConfigs();
  }, [
    selectedCountry,
    additionalPayContext?.disbursementDateOptions,
    setFormFields,
    setPaymentSettingsFields,
    type,
  ]);
}

export default useCountryConfigFetcher;
