import { PaymentInfoFormType } from "./types";

export const initialValues: PaymentInfoFormType = {
  paymentInfo: {
    accountNickname: "",
    accountNumber: "",
    accountType: "",
    countryCode: "",
    clearingNumber: "",
    currency: "",
    sortCode: "",
    institutionNumber: "",
    transitNumber: "",
    clabe: "",
    bankName: "",
    iban: "",
    bankCode: "",
    registrationNumber: "",
    ctsAccountNumber: "",
    ctsFinancialEntity: "",
    bankCertificate: undefined,
  },
};
