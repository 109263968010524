import * as Yup from "yup";
import { noEmojiTest } from "../../validations";

export const benefitsAndAllowancesSchema = Yup.object().shape({
  paymentSettings: Yup.object().shape({
    payPeriodId: Yup.string().required("Pay date is required"),
    benefitType: Yup.string().required("Benefit type is required"),
    notes: noEmojiTest,
  }),
});
