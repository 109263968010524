import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Card";
import { Box } from "@justworkshr/milo-core";
import { RequestItem } from "../RequestItem/RequestItem";
import { formatDate } from "../../utils";

const UpcomingRequests = () => {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const employeeName =
    profileInfo?.eorEmployeeProfile.personalInfo?.firstName ?? "This employee";
  const policies = profileInfo?.eorEmployeeProfile.activePtoPolicies ?? [];

  // parse our object policy array to [{policyName, ...request}] and sort by date
  const upcomingRequests = policies
    .flatMap((policy) => {
      const requests = policy.upcomingRequests ?? [];
      return requests.map((request) => ({
        policyName: policy.name,
        ...request,
      }));
    })
    .sort(
      (first, second) =>
        new Date(second.startDate).getTime() -
        new Date(first.startDate).getTime()
    );

  return (
    <Card title="Upcoming Time Off" cardIconName="suitcase">
      {upcomingRequests.length > 0 ? (
        upcomingRequests.map(
          ({ uuid, startDate, minutes, policyName, status }) => (
            <RequestItem
              key={uuid}
              effectiveDate={formatDate(startDate)}
              minutes={minutes}
              policyName={policyName}
              status={status.toLocaleUpperCase()}
              isAdjustment={false}
            />
          )
        )
      ) : (
        <Box backgroundColor="neutralSubtle" padding="lg">
          {`${employeeName} ${t("has no upcoming time off")}`}
        </Box>
      )}
    </Card>
  );
};

export { UpcomingRequests };
