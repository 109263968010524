import {
  FormField,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ConfigType, SchemaType } from "./config";

export default function FormFragment() {
  const { t } = useTranslation();
  const { errors, touched, handleChange, handleBlur, values, setFieldValue } =
    useFormikContext<SchemaType>();

  const handlePtoAllowanceTypeOnChange = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    setFieldValue("caPtoAllowanceDays", null);
    return handleChange(event);
  };

  const fieldErrorHandler = (name: keyof ConfigType["initialValues"]) =>
    errors[name] && touched[name] ? errors[name] : undefined;

  return (
    <>
      <FormField
        required
        name="caProbationPeriodDays"
        label={t("Probation Period")}
        error={fieldErrorHandler("caProbationPeriodDays")}
      >
        <Select
          name="caProbationPeriodDays"
          placeholder={t("Select probation period days")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.caProbationPeriodDays}
        >
          <SelectOption value={0}>{t("None")}</SelectOption>
          <SelectOption value={90}>{t("90 days")}</SelectOption>
        </Select>
      </FormField>
      <FormField
        name="caPtoAllowanceType"
        required
        label={t("PTO Allowance")}
        error={fieldErrorHandler("caPtoAllowanceType")}
      >
        <Select
          name="caPtoAllowanceType"
          placeholder={t("Select PTO allowance")}
          onChange={handlePtoAllowanceTypeOnChange}
          onBlur={handleBlur}
          value={values.caPtoAllowanceType}
        >
          <SelectOption value="statutory">{t("Statutory")}</SelectOption>
          <SelectOption value="unlimited">{t("Unlimited")}</SelectOption>
          <SelectOption value="custom">{t("Custom")}</SelectOption>
        </Select>
      </FormField>
      {values.caPtoAllowanceType === "custom" && (
        <FormField
          required
          name="caPtoAllowanceDays"
          label={t("PTO Allowance Days")}
          error={fieldErrorHandler("caPtoAllowanceDays")}
        >
          <TextInput
            required
            type="number"
            name="caPtoAllowanceDays"
            onChange={handleChange}
            onBlur={handleBlur}
            value={String(values.caPtoAllowanceDays)}
          />
        </FormField>
      )}
    </>
  );
}
