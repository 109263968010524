import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { getDescription } from "pages/employer-of-record/utils";
import { field, countryIs, personalInfo, REQUIRED } from "./utils";
import * as IRELAND from "pages/employer-of-record/onboarding/profile-info/form/ie/constants.ie";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const irelandFields = {
  ieMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "ieMaritalStatus",
    label: "Marital status",
    options: IRELAND.MARITAL_STATUS_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      editProfile: countryIs("IE"),
      viewProfile: countryIs("IE"),
      onboarding: countryIs("IE"),
    },
    value: personalInfo("ieMaritalStatus", ""),
    format: (value) =>
      getDescription(IRELAND.MARITAL_STATUS_OPTIONS, value) ?? value,
  }),
  iePps: identifierNumberFieldConfig("IE"),
  ieConfirmPps: confirmIdentifierNumberFieldConfig("IE"),
};
