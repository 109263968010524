import { name, localName, abbreviation, validate } from "stdnum/src/cl/rut";
import { strings } from "stdnum/src/util";

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  const [v, err] = strings.cleanUnicode(input, " -.");

  if (err) {
    return ["", err];
  }

  if (v.startsWith("CL")) {
    return [v.substr(2), null];
  }

  return [v, null];
}

const clTaxIdValidator = {
  name: name,
  localName: localName,
  abbreviation: abbreviation,
  compact(input: string): string {
    const [value, err] = clean(input);

    if (err) {
      throw err;
    }

    return value;
  },
  format(input: string): string {
    const [value] = clean(input);

    if (value.length === 8) {
      return value.replace(/(\d{7})([0-9a-zA-Z]{1})/, "$1-$2");
    } else if (value.length === 9) {
      return value.replace(/(\d{8})([0-9a-zA-Z]{1})/, "$1-$2");
    }
    return input;
  },
  validate,
};

export { clTaxIdValidator };
