import { FormField } from "@justworkshr/milo-form";
import styles from "../PaymentDetailsRow/PaymentDetailsRow.module.css";
import {
  DatePicker,
  formatDateToISO8601,
  handleInvalidDate,
  parseISO8601Date,
} from "pages/employer-of-record/components";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../Setup/types";
import { EorMembersType } from "pages/employer-of-record/payments/types";
import { isWithinEmploymentDates } from "pages/employer-of-record/payments/utils";
import ApplyToAllButton from "../ApplyToAllButton/ApplyToAllButton";

type Props = {
  index: number;
  member: EorMembersType;
  errorRow: FormikErrors<PaymentDetailsType> | null;
  touchedRow: FormikTouched<PaymentDetailsType> | null;
  hasApplyToAllButton?: boolean;
  getUpdatedMemberDetail: (
    name: string,
    value: string,
    index: number
  ) => PaymentDetailsType[];
  applyToAll?: (fields: string[]) => void;
  hasLabels?: boolean;
};

const DateRangePicker = ({
  index,
  errorRow,
  touchedRow,
  member,
  hasApplyToAllButton,
  getUpdatedMemberDetail,
  applyToAll,
  hasLabels = false,
}: Props) => {
  const { values, handleBlur, setFieldValue } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();

  const isValidDateHandler = (date: string) => {
    const startDate = member.workStartDate;
    const endDate = member.workEndDate;
    return isWithinEmploymentDates(date, startDate, endDate);
  };

  return (
    <>
      <td className={`${styles.td}`}>
        <div
          className={`${styles.dateRangeWrapper} ${
            !hasLabels ? styles.noLabelWrapper : ""
          }`}
        >
          <div className={styles.pickerWidth}>
            <FormField
              label={(hasLabels && "Work period start") || "_"}
              required
              name=""
              error={
                errorRow?.workPeriodStart && touchedRow?.workPeriodStart
                  ? errorRow?.workPeriodStart
                  : ""
              }
            >
              <DatePicker
                id={`startDate_${index}`}
                name={`paymentDetails[${index}].workPeriodStart`}
                value={parseISO8601Date(
                  values.paymentDetails[index]?.workPeriodStart
                )}
                onBlur={handleBlur}
                className={`${
                  errorRow?.workPeriodStart && touchedRow?.workPeriodStart
                    ? styles.error
                    : ""
                }`}
                placeholder="MM/DD/YY"
                onChange={(startDate: string) => {
                  const date = handleInvalidDate(startDate);
                  setFieldValue(
                    `paymentDetails`,
                    getUpdatedMemberDetail(
                      "workPeriodStart",
                      formatDateToISO8601(date),
                      index
                    )
                  );
                }}
                isValidDate={isValidDateHandler}
              />
            </FormField>
          </div>
        </div>
        {hasApplyToAllButton && applyToAll && (
          <ApplyToAllButton
            applyToAll={() => {
              applyToAll(["workPeriodStart"]);
            }}
          />
        )}
      </td>
      <td className={`${styles.td}`}>
        <div
          className={`${styles.dateRangeWrapper} ${
            !hasLabels ? styles.noLabelWrapper : ""
          }`}
        >
          <FormField
            label={(hasLabels && "Work period end") || "_"}
            required
            name=""
            error={
              errorRow?.workPeriodEnd && touchedRow?.workPeriodEnd
                ? errorRow?.workPeriodEnd
                : ""
            }
          >
            <DatePicker
              id={`endDate_${index}`}
              name={`paymentDetails[${index}].workPeriodEnd`}
              onBlur={handleBlur}
              className={`${
                errorRow?.workPeriodEnd && touchedRow?.workPeriodEnd
                  ? styles.error
                  : ""
              }`}
              value={parseISO8601Date(
                values.paymentDetails[index]?.workPeriodEnd
              )}
              placeholder="MM/DD/YY"
              onChange={(endDate: string) => {
                const date = handleInvalidDate(endDate);
                setFieldValue(
                  `paymentDetails`,
                  getUpdatedMemberDetail(
                    "workPeriodEnd",
                    formatDateToISO8601(date),
                    index
                  )
                );
              }}
              isValidDate={isValidDateHandler}
            />
          </FormField>
        </div>
        {hasApplyToAllButton && applyToAll && (
          <ApplyToAllButton
            applyToAll={() => {
              applyToAll(["workPeriodEnd"]);
            }}
          />
        )}
      </td>
    </>
  );
};

export default DateRangePicker;
