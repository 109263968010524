const DEGREE_OPTIONS = [
  {
    value: "no_diploma",
    description: "Aucun diplôme (No diploma)",
  },
  {
    value: "middle_school_diploma",
    description: "Brevet des collèges (Middle school diploma)",
  },
  {
    value: "cap",
    description:
      "Certificat d’Aptitude Professionnelle (Vocational Training Certificate)",
  },
  {
    value: "bep",
    description:
      "Brevet d’Études Professionnelles (Professional Studies Certificate)",
  },
  {
    value: "baccalaureat",
    description: "Baccalauréat (High School Diploma)",
  },
  {
    value: "bts",
    description:
      "Brevet de Technicien Supérieur (Advanced Technician Certificate)",
  },
  {
    value: "dut",
    description:
      "Diplôme Universitaire de Technologie (University Diploma of Technology)",
  },
  {
    value: "licence",
    description: "Licence (Bachelor’s Degree)",
  },
  {
    value: "licence_professionnelle",
    description: "Licence Professionnelle (Professional Bachelor's Degree)",
  },
  {
    value: "maitrise",
    description: "Maîtrise (Master's Degree)",
  },
  {
    value: "master_1",
    description: "Master 1 (First Year of Master's Degree)",
  },
  {
    value: "master_2",
    description: "Master 2 (Master's Degree)",
  },
  {
    value: "diplome_ingenieur",
    description: "Diplôme d’Ingénieur (Engineering Degree)",
  },
  {
    value: "mba",
    description: "MBA (Master of Business Administration)",
  },
  {
    value: "doctorat",
    description: "Doctorat (PhD (Doctorate Degree))",
  },
  {
    value: "diplome_etat",
    description: "Diplôme d'État (State Diploma)",
  },
  {
    value: "titre_professionnel",
    description: "Titre professionnel (Professional Certification)",
  },
];

const TITLE_OPTIONS = [
  {
    value: "mr",
    description: "Mr",
  },
  {
    value: "ms",
    description: "Ms",
  },
];
const MARITAL_STATUS_OPTIONS = [
  {
    value: "single",
    description: "Single",
  },
  {
    value: "married",
    description: "Married",
  },
  {
    value: "divorced",
    description: "Divorced",
  },
  {
    value: "separated",
    description: "Separated",
  },
  {
    value: "widowed",
    description: "Widowed",
  },
];

export { DEGREE_OPTIONS, TITLE_OPTIONS, MARITAL_STATUS_OPTIONS };
