import { buildClockworkWebPath, useNeonPath } from "lib/resource-finder";
import styles from "./LegacyTabList.module.css";
import {
  CompanyPlan,
  useGetCompanyInfoQuery,
} from "types/generated/operations";
const { tabList, tab, activeTab, tabLink } = styles;

const LegacyTabList = () => {
  const companyInfo = useGetCompanyInfoQuery();
  const companyPlanType =
    companyInfo.data?.authenticatedMember.company.companyPlanType;
  const employeesPath = useNeonPath("/members/employees");
  const contractorsPath = useNeonPath("/members/contractors");

  const getVendorTab = () => {
    if (companyPlanType === CompanyPlan.InternationalStandalone) {
      return <></>;
    }
    return (
      <div className={tab}>
        <a className={tabLink} href={buildClockworkWebPath("/members/vendors")}>
          Vendors
        </a>
      </div>
    );
  };

  return (
    <div className={tabList}>
      <div className={tab}>
        <a className={tabLink} href={employeesPath}>
          Employees
        </a>
      </div>
      <div className={tab}>
        <a className={tabLink} href={contractorsPath}>
          Contractors
        </a>
      </div>
      {getVendorTab()}
      <div className={`${tab} ${activeTab}`}>Third-Party</div>
    </div>
  );
};

export default LegacyTabList;
