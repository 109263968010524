import { ReactElement, useContext, useMemo, useState } from "react";
import styles from "./Payments.module.css";
import { useParams } from "react-router-dom";
import {
  getFormValidationSchema,
  recursivelyConvertFilesToBase64Objects,
} from "./utils";
import { Form, Formik, FormikHelpers } from "formik";
import { PayNavigation } from "./components/PayNavigation";
import { AdditionalPayContextProvider } from "./contexts/additionalPayContext";
import { Edit } from "./components/Edit/Edit";
import { useGetDisbursementOrderQuery } from "types/generated/operations";
import { DisbursementOrderTypeMapping } from "./constants";
import { useEorUpdateDisbursementOrdersMutation } from "types/generated/operations";
import { buildEditFormValues } from "./utils/buildEditFormValues";
import { Alert, Spinner } from "@justworkshr/milo-core";
import { ERROR_MESSAGE } from "./components/Alert";
import { SupportedCountriesContext } from "../contexts/supportedCountriesContext";
import { Confirmation } from "./components/Confirmation";

export const EditPayments = (): ReactElement => {
  const { id, formType } = useParams();
  const { data: orderData, loading } = useGetDisbursementOrderQuery({
    variables: { id: id || "" },
  });
  const [updateDisbursementOrders] = useEorUpdateDisbursementOrdersMutation();
  const [error, setError] = useState<string>("");
  const { loading: countryConfigsLoading } = useContext(
    SupportedCountriesContext
  );
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (
    values: NonNullable<typeof formInitialValues>,
    { setSubmitting }: FormikHelpers<NonNullable<typeof formInitialValues>>
  ) => {
    try {
      await recursivelyConvertFilesToBase64Objects(values.setUp);
    } catch (e) {
      const message = e instanceof Error ? e.message : ERROR_MESSAGE;
      setError(message);
      window.scrollTo(0, 0);
      return;
    }

    const submitPayload = {
      ...values.setUp.payload,
      paymentDetails: values.paymentDetails[0],
    };

    await updateDisbursementOrders({
      variables: {
        id: id || "",
        payload: submitPayload,
      },
      onCompleted: () => {
        if (error) {
          setError("");
        }
        setSubmitting(false);
        setFormSubmitted(true);
      },
      onError: () => {
        setError(ERROR_MESSAGE);
        setSubmitting(false);
        window.scrollTo(0, 0);
      },
    });
  };

  const formInitialValues = useMemo(() => {
    if (!orderData) return null;
    const paymentType =
      DisbursementOrderTypeMapping[orderData.disbursementOrder.paymentType];
    return buildEditFormValues(orderData, paymentType);
  }, [orderData]);

  const formValidationSchema = useMemo(() => {
    if (!orderData) return null;

    return getFormValidationSchema(formType || "");
  }, [orderData, formType]);

  const showForm = (
    <div
      className={`${styles.contentWrapper} ${
        formSubmitted ? `${styles.confirmation}` : ""
      }`}
    >
      <PayNavigation />
      <div className={styles.layout}>
        <AdditionalPayContextProvider>
          {orderData &&
          !loading &&
          formInitialValues &&
          !countryConfigsLoading ? (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={handleSubmit}
            >
              <>
                {!formSubmitted && (
                  <Form>
                    <Edit memberData={orderData.disbursementOrder.member} />
                  </Form>
                )}
                {formSubmitted && <Confirmation />}
              </>
            </Formik>
          ) : (
            <div className={styles.spinnerWrapper}>
              <Spinner size="extraLarge" />
            </div>
          )}
        </AdditionalPayContextProvider>
      </div>
    </div>
  );

  return (
    <>
      {error && <Alert color="destructive">{error}</Alert>}
      {showForm}
    </>
  );
};
