import { ReactElement, useContext } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import {
  formatDate,
  getEmployeeLegalName,
} from "pages/employer-of-record/employee-profile/utils";
import Row from "../../../Row/Row";
import EditAction from "../../../EditAction/EditAction";
import { Card } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import ProfileInfoCard, { shouldHide } from "../../ProfileInfoCard";
import BadgeTitle from "../BadgeTitle";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import ProfileInfoRow from "../../ProfileInfoRow";
import { useFields } from "pages/employer-of-record/configs";

type Props = {
  editable: boolean;
};

export default function GeneralInformationDefault(props: Props): ReactElement {
  const { editable } = props;
  const { getCountryName } = useContext(SupportedCountriesContext);
  const { profileInfo } = useProfileContextData();
  const { t } = useTranslation();

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const preferredName = personalInfo?.preferredFirstName
    ? ` (${personalInfo.preferredFirstName})`
    : "";
  const fullName = getEmployeeLegalName(personalInfo) + preferredName;
  const birthDay = formatDate(personalInfo?.dateOfBirth);
  const workCountry = profileInfo?.eorEmployeeProfile?.employment?.workCountry;
  const countryName = getCountryName(workCountry);

  const identificationFields = useFields({
    scenario: "viewProfile",
    informationType: ["identification", "identifierNumber"],
    overrides: {
      auTfn: {
        label: "TFN",
      },
      mxTin: { label: "RFC (TIN)" },
      nlBsn: { label: "BSN (Burger Service Number)" },
    },
  });

  const familyFields = useFields({
    scenario: "viewProfile",
    informationType: "family",
  });

  const educationFields = useFields({
    scenario: "viewProfile",
    informationType: "education",
  });

  return (
    <>
      <Card
        title={t("Identification")}
        actions={[<EditAction target="identification" enabled={editable} />]}
      >
        <Row label="Full Name" value={fullName} required />
        <Row
          label="Preferred name"
          value={personalInfo?.preferredFirstName}
          required={false}
        />
        <Row
          label="Date of birth"
          value={birthDay}
          required
          showHideButton
          hiddenValueFormat="•• •• ••••"
        />
        {identificationFields
          .filter(
            (field) => !shouldHide(field, profileInfo!.eorEmployeeProfile)
          )
          .map((field) => (
            <ProfileInfoRow {...field} />
          ))}
      </Card>
      {!!familyFields.length && (
        <ProfileInfoCard
          title={
            (
              <BadgeTitle title="Family" badgeText={countryName} />
            ) as unknown as string
          }
          editable
          informationType="family"
          fields={familyFields}
        />
      )}
      {!!educationFields.length && (
        <ProfileInfoCard
          title={
            (
              <BadgeTitle title="Education" badgeText={countryName} />
            ) as unknown as string
          }
          editable
          informationType="education"
          fields={educationFields}
        />
      )}
    </>
  );
}
