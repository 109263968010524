import React, { createContext, ReactNode } from "react";
import { useGetAllFeatureFlagsQuery } from "../../types/generated/operations";
import { asyncThrow } from "@justworkshr/fe-utility-kit";

export type NeonSkyFFValueTypes = string | number | boolean | object;

export type NeonSkyFFContextType = {
  featureFlags: NeonSkyFeatureFlags;
};

export type NeonSkyFlagIds =
  | "neonmoose_global_manage_employees"
  | "neonmoose_global_company_benefits"
  | "neonmoose_global_benefits_health_plan_selection"
  | "neonmoose_global_vendors"
  | "neonmoose_vendor_users"
  | "neonmoose_global_more_services"
  | "neonmoose_global_account_settings"
  | "neonmoose_global_show_quoting"
  | "neonmoose_global_one_justworks_marketplace"
  | "neonmoose_global_w4_exemption_renewal"
  | "neonmoose_global_company_settings"
  | "neonmoose_global_directory";

type NeonSkyFeatureFlags = {
  [key in NeonSkyFlagIds]: NeonSkyFFValueTypes;
};

export const NeonSkyContext = createContext<NeonSkyFFContextType>({
  featureFlags: {} as NeonSkyFeatureFlags,
});

export const NeonSkyProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queryResult = useGetAllFeatureFlagsQuery();
  const { data, loading, error } = queryResult;

  if (error) {
    asyncThrow(
      `An error occurred while fetching feature flags: ${error.message}`
    );
  }

  if (loading || !data) {
    return <></>;
  }

  const featureFlags = data.allFeatureFlags as NeonSkyFeatureFlags;

  return (
    <NeonSkyContext.Provider value={{ featureFlags }}>
      {children}
    </NeonSkyContext.Provider>
  );
};
