import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GetActiveEoRMembersByCompanyUuidQuery,
  useGetActiveEoRMembersByCompanyUuidQuery,
} from "types/generated/operations";
import { compareMembersByName } from "../utils";
import { AdditionalPayContext } from "./additionalPayContext";
import { ApolloError } from "@apollo/client";

type MemberByCountryType = {
  [key: string]: number;
};

interface MemberDataContextProps {
  membersData: GetActiveEoRMembersByCompanyUuidQuery["eorMembers"];
  memberCountryCount: MemberByCountryType;
  loadingMemberData: boolean;
  memberFetchError?: ApolloError;
}

export const MemberDataContext = createContext<MemberDataContextProps | null>(
  null
);

interface MemberDataProviderProps {
  children: ReactNode;
}

export const MemberDataProvider = ({ children }: MemberDataProviderProps) => {
  const [membersData, setMembersData] = useState<
    GetActiveEoRMembersByCompanyUuidQuery["eorMembers"]
  >([]);
  const [memberCountryCount, setMembersCountryCount] =
    useState<MemberByCountryType>({});

  const additionalPayContext = useContext(AdditionalPayContext);
  const {
    data: allMembers,
    loading: loadingMemberData,
    error: memberFetchError,
  } = useGetActiveEoRMembersByCompanyUuidQuery();

  useEffect(() => {
    if (!allMembers?.eorMembers) return;

    const filteredSortedMembers = allMembers.eorMembers
      .filter(
        (member) =>
          member.workCountry &&
          additionalPayContext?.selectedCountry &&
          member.workCountry?.includes(additionalPayContext?.selectedCountry)
      )
      .sort(compareMembersByName);

    const counts = allMembers.eorMembers.reduce<{ [key: string]: number }>(
      (acc, member) => {
        if (member.workCountry) {
          acc[member.workCountry] = (acc[member.workCountry] || 0) + 1;
        }
        return acc;
      },
      {}
    );

    setMembersCountryCount(counts);
    setMembersData(filteredSortedMembers);
  }, [additionalPayContext?.selectedCountry, allMembers]);

  return (
    <MemberDataContext.Provider
      value={{
        membersData,
        memberCountryCount,
        loadingMemberData,
        memberFetchError,
      }}
    >
      {children}
    </MemberDataContext.Provider>
  );
};

// Custom hook to use MemberDataContext
export const useMemberData = () => {
  const context = useContext(MemberDataContext);
  return context;
};
