import { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./Payments.module.css";
import { Outlet, useParams } from "react-router-dom";
import { AdditionalPayContext } from "./contexts/additionalPayContext";
import usePayStepNavigation from "./hooks/usePayStepNavigation";
import {
  calculateStepNumber,
  extendFormData,
  getFormValidationSchema,
  getFormInitialValues,
  recursivelyConvertFilesToBase64Objects,
  removeEntriesWithNoGrossAmount,
} from "./utils";
import { StepperLayout } from "./components/StepperLayout";
import { Form, Formik, FormikHelpers } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
} from "./components/Setup/types";
import { Confirmation } from "./components/Confirmation/Confirmation";
import { PayNavigation } from "./components/PayNavigation";
import PaymentsAlerts from "./components/Alert/Alert";
import { Spinner } from "@justworkshr/milo-core";
import { SUBPATHS } from "./constants";
import { useLocation } from "react-router-dom";
import {
  useEorCreateDisbursementOrdersMutation,
  useGetCompanyDisplayQuery,
} from "types/generated/operations";
import { ERROR_MESSAGE } from "./components/Alert";
import { useMemberData } from "./contexts/memberDataContext";

export const Payments = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const memberDataContext = useMemberData();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { formType } = useParams();

  const formIntialValues = getFormInitialValues(formType || "");
  const formValidationSchema = getFormValidationSchema(formType || "");

  const { navigateToStep } = usePayStepNavigation();
  const { pathname } = useLocation();
  const { data } = useGetCompanyDisplayQuery();
  const companyUuid = data?.authenticatedMember.company.uuid;

  const [createDisbursementOrders] = useEorCreateDisbursementOrdersMutation();
  const handleSubmit = async <T extends FormPayloadType>(
    values: AdditionalPayFormData<T>,
    { setSubmitting }: FormikHelpers<AdditionalPayFormData<T>>
  ) => {
    if (pathname.includes(SUBPATHS.REVIEW)) {
      const extendedData = extendFormData(
        values.setUp,
        additionalPayContext?.disbursementDateOptions || {},
        companyUuid
      );

      const validPaymentEntries = removeEntriesWithNoGrossAmount(
        values.paymentDetails
      );

      try {
        await recursivelyConvertFilesToBase64Objects(extendedData);
      } catch (e) {
        const message = e instanceof Error ? e.message : ERROR_MESSAGE;
        additionalPayContext?.setErrorMessage(message);
        window.scrollTo(0, 0);
        return;
      }

      await createDisbursementOrders({
        variables: {
          setup: extendedData,
          paymentDetails: validPaymentEntries,
        },
        onCompleted() {
          setFormSubmitted(true);
        },
        onError() {
          additionalPayContext?.setErrorMessage(ERROR_MESSAGE);
        },
      });
      window.scrollTo(0, 0);
    } else {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (additionalPayContext?.currentStep) {
      navigateToStep(additionalPayContext.currentStep);
    }
  }, [additionalPayContext?.currentStep, navigateToStep]);

  const showSpinner = memberDataContext?.loadingMemberData && (
    <div className={styles.spinnerWrapper}>
      <Spinner size="extraLarge" />
    </div>
  );

  const showForm = (
    <div
      className={`${styles.contentWrapper} ${
        formSubmitted ? `${styles.confirmation}` : ""
      }`}
    >
      <PayNavigation />

      <div className={styles.layout}>
        {showSpinner}
        {!memberDataContext?.loadingMemberData && (
          <Formik
            initialValues={formIntialValues}
            onSubmit={handleSubmit}
            validationSchema={formValidationSchema}
          >
            <>
              {!formSubmitted && (
                <Form>
                  <StepperLayout
                    step={calculateStepNumber(
                      additionalPayContext?.currentStep
                    )}
                    loading={false}
                  >
                    <Outlet />
                  </StepperLayout>
                </Form>
              )}

              {formSubmitted && <Confirmation />}
            </>
          </Formik>
        )}
      </div>
    </div>
  );

  return (
    <>
      {<PaymentsAlerts />}
      {showForm}
    </>
  );
};
