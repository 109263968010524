const myPhoneRegex =
  /^\+?\d{1,3}?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const GENDER_OPTIONS = [
  {
    value: "male",
    description: "Male",
  },
  {
    value: "female",
    description: "Female",
  },
];

const DISABILITY_OPTIONS = [
  {
    value: "yes",
    description: "Yes",
  },
  {
    value: "no",
    description: "No",
  },
];

const INCOME_TAX_STATUS_OPTIONS = [
  {
    value: "resident",
    description: "Resident",
  },
  {
    value: "non-resident",
    description: "Non-resident",
  },
];

const MARITAL_STATUS_OPTIONS = [
  {
    value: "single",
    description: "Single",
  },
  {
    value: "married",
    description: "Married",
  },
  {
    value: "divorced",
    description: "Divorced",
  },
  {
    value: "widowed",
    description: "Widowed",
  },
];

const CITIZENSHIP_OPTIONS = [
  {
    value: "yes",
    description: "Yes",
  },
  {
    value: "no",
    description: "No",
  },
];

const RACE_OPTIONS = [
  {
    value: "malay",
    description: "Malay",
  },
  {
    value: "indian",
    description: "Indian",
  },
  {
    value: "chinese",
    description: "Chinese",
  },
  {
    value: "other",
    description: "Other",
  },
];

export {
  GENDER_OPTIONS,
  DISABILITY_OPTIONS,
  INCOME_TAX_STATUS_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  CITIZENSHIP_OPTIONS,
  RACE_OPTIONS,
  myPhoneRegex,
};
