import * as Yup from "yup";
import { TFunction } from "i18next";
import { FieldData, FieldDataType, FieldTypes, ValidationType } from "./types";
import { MixedSchema, ObjectShape } from "yup";
import { parsePhoneNumber } from "libphonenumber-js";

type FieldValidationSchema =
  | Yup.StringSchema
  | Yup.NumberSchema
  | InstanceType<typeof MixedSchema>
  | Yup.BooleanSchema
  | object;

function generateTextValidations(
  schema: Yup.StringSchema,
  validations: NonNullable<ValidationType["text"]>
) {
  const bankCodeRegEx = /^[0-9]{3}$/;
  const bsbCodeRegEx = /^\s*(\d{3}(-?|\s*)\d{3})\s*$/;
  const clabeRegEx = /^\d{18,18}$/;
  const ifsCodeRegEx = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const numberRegEx = /^[0-9]+$/;
  const routingNumberRegEx = /^[0-9]{3}$/;
  const sortCodeRegEx = /^([\d]{2} ?-?){3}$/;
  const swiftCodeRegEx =
    /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[A-Z0-9]{3}$/;

  if (validations.max) {
    schema = schema.max(validations.max.value, validations.max.message);
  }

  if (validations.min) {
    schema = schema.min(validations.min.value, validations.min.message);
  }

  if (validations.length) {
    schema = schema.length(
      validations.length.value,
      validations.length.message
    );
  }

  if (validations.number?.enabled) {
    schema = schema.matches(numberRegEx, validations.number.message);
  } else if (validations.sortCode?.enabled) {
    schema = schema.matches(sortCodeRegEx, validations.sortCode.message);
  } else if (validations.bankCode?.enabled) {
    schema = schema.matches(bankCodeRegEx, validations.bankCode.message);
  } else if (validations.bsbCode?.enabled) {
    schema = schema.matches(bsbCodeRegEx, validations.bsbCode.message);
  } else if (validations.routingNumber?.enabled) {
    schema = schema.matches(
      routingNumberRegEx,
      validations.routingNumber.message
    );
  } else if (validations.clabe?.enabled) {
    schema = schema.matches(clabeRegEx, validations.clabe.message);
  } else if (validations.ifsCode?.enabled) {
    schema = schema.matches(ifsCodeRegEx, validations.ifsCode.message);
  } else if (validations.swiftCode?.enabled) {
    schema = schema.matches(swiftCodeRegEx, validations.swiftCode.message);
  } else if (validations.email?.enabled) {
    schema = schema.email(validations.email.message);
  }

  if (validations.matches) {
    schema = schema.matches(
      validations.matches.value,
      validations.matches.message
    );
  }

  if (validations.fn) {
    schema = schema.test({
      message: validations.fn.message,
      test: validations.fn.value,
    });
  }

  return schema;
}

function generateNumberValidations(
  schema: Yup.NumberSchema,
  validations: NonNullable<ValidationType["number"]>
) {
  if (validations.max) {
    schema = schema.max(validations.max.value, validations.max.message);
  }

  if (validations.min) {
    schema = schema.min(validations.min.value, validations.min.message);
  }

  return schema;
}

function generatePhoneNumberValidations() {
  return Yup.object({
    type: Yup.string(),
    value: Yup.string().test("valid", "Invalid Phone Number", (value) => {
      try {
        const number = parsePhoneNumber(value || "");
        return number?.isValid() || false;
      } catch {
        return false;
      }
    }),
  });
}

function generateArrayValidation(schema: ValidationSchema) {
  return Yup.array().of(schema);
}

function generateAddressValidations() {
  return Yup.object().shape({
    address1: Yup.string().required("This field is required"),
    address2: Yup.string(),
    city: Yup.string().required("This field is required"),
    countryCode: Yup.string().required("This field is required"),
    postalCode: Yup.string().required("This field is required"),
    zoneCode: Yup.string().required("This field is required"),
  });
}

function generateMultiSelectValidations(validations?: ValidationType) {
  let schema = Yup.array().of(Yup.string().required("This field is required."));

  if (validations?.array?.min) {
    schema = schema.min(
      validations.array.min.value,
      validations.array.min.message
    );
  }

  return schema;
}

function generateFileValidations() {
  return Yup.object().shape({
    blobFile: Yup.string().required("This field is required"),
    contentType: Yup.string().required("This field is required"),
    originalFilename: Yup.string().required("This field is required"),
  });
}

type ValidationSchema =
  | Yup.StringSchema
  | Yup.NumberSchema
  | Yup.MixedSchema
  | ReturnType<typeof generatePhoneNumberValidations>
  | ReturnType<typeof generateAddressValidations>
  | ReturnType<typeof generateFileValidations>;

const generateFieldValidation = (
  field: FieldDataType
): FieldValidationSchema => {
  const { type: fieldType, validations, hideWhen } = field;

  let yupValidation:
    | ValidationSchema
    | ReturnType<typeof generateArrayValidation>;

  if (fieldType === FieldTypes.text || fieldType === FieldTypes.sensitiveText) {
    yupValidation = Yup.string();

    if (validations?.text) {
      yupValidation = generateTextValidations(yupValidation, validations.text);
    }
  } else if (fieldType === FieldTypes.number && validations?.number) {
    yupValidation = Yup.number();

    if (validations.number) {
      yupValidation = generateNumberValidations(
        yupValidation,
        validations.number
      );
    }
  } else if (fieldType === FieldTypes.phoneNumber) {
    yupValidation = generatePhoneNumberValidations();
  } else if (fieldType === FieldTypes.address) {
    yupValidation = generateAddressValidations();
  } else if (fieldType === FieldTypes.multiSelect) {
    yupValidation = generateMultiSelectValidations(field.validations);
  } else if (fieldType === FieldTypes.file) {
    yupValidation = generateFileValidations();
  } else {
    yupValidation = Yup.mixed();
  }

  if (validations?.required) {
    if ("enabled" in validations.required) {
      if (validations.required.enabled) {
        yupValidation = yupValidation.required(validations.required.message);
      }
    } else {
      for (const [name, isRequired] of Object.entries(
        validations.required.when
      )) {
        yupValidation = (yupValidation as Yup.MixedSchema).when(name, {
          is: isRequired,
          then: (schema) => schema.required(`This field is required.`),
          otherwise: (schema) => schema.notRequired(),
        });
      }
    }
  }

  if (hideWhen) {
    for (const [name, shouldHide] of Object.entries(hideWhen)) {
      yupValidation = (yupValidation as Yup.MixedSchema).when(name, {
        is: shouldHide,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema,
      });
    }
  }

  if ("array" in field && field.array) {
    yupValidation = Yup.array().of(yupValidation);
  }

  return yupValidation;
};

type ObjectSchemaType = { [key: string]: FieldValidationSchema };

type FormSchemaShape = {
  [key: string]: FieldValidationSchema | Yup.ObjectSchema<ObjectSchemaType>;
};

const generateFormValidations = (fields: FieldData[], t: TFunction) => {
  const validationShape = fields.reduce<FormSchemaShape>((acc, curr) => {
    if (curr.fields?.length) {
      acc[curr.name] = generateFormValidations(curr.fields, t);
    } else if (
      curr.validations ||
      curr.type === FieldTypes.phoneNumber ||
      curr.type === FieldTypes.address
    ) {
      acc[curr.name] = generateFieldValidation({
        ...curr,
        validations: curr.validations,
      });
    }

    return acc;
  }, {});

  return Yup.object().shape(validationShape as ObjectShape);
};

export default generateFormValidations;
