import { formatToISODate } from "pages/employer-of-record/utils";

export const scrollToTop = () => window.scrollTo(0, 0);
export const today = formatToISODate(new Date());
export const tomorrow = formatToISODate(
  new Date(Date.now() + 24 * 60 * 60 * 1000)
);

export const filterObject = <T extends object, K extends keyof T>(
  source: T,
  keysToFilter: K[]
): Partial<T> =>
  Object.entries(source).reduce((acc, [key, value]) => {
    if (!keysToFilter.includes(key as K)) {
      acc[key as K] = value;
    }
    return acc;
  }, {} as Partial<T>);

export const convertNumberToPennies = (numberString: string) => {
  const cleanedString = numberString.replace(/[$,]/g, "");

  return Math.round(parseFloat(cleanedString) * 100);
};
