import { useGetEoRSupportedCountriesQuery } from "../../../types/generated/operations";
import type { ApolloError } from "@apollo/client";
import { createContext, FC, ReactNode } from "react";

export type SupportedCountriesData = Record<string, SupportedCountriesDataType>;

export interface SupportedCountriesContextType {
  loading: boolean;
  error?: ApolloError;
  supportedCountriesData: SupportedCountriesData;
  getCountryName(countryCode?: string): string;
  getSubdivisionName(countryCode: string, subdivisionCode: string): string;
  getCurrency(countryCode?: string): CurrencyType;
  getSupportedProvinces(countryCode?: string): SubdivisionType[];
}

const initialCurrencyContext = {
  currencyCode: "",
  symbol: "",
  subunitToUnit: 0,
};
const initialContext: SupportedCountriesContextType = {
  loading: true,
  supportedCountriesData: {},
  getCountryName: () => "",
  getSubdivisionName: () => "",
  getCurrency: () => {
    return initialCurrencyContext;
  },
  getSupportedProvinces: () => [],
};

export const SupportedCountriesContext =
  createContext<SupportedCountriesContextType>(initialContext);

interface SupportedCountriesContextProviderProps {
  children: ReactNode;
}

export type CurrencyType = {
  currencyCode: string;
  subunitToUnit: number;
  symbol: string;
};

type SubdivisionType = {
  code: string;
  name: string;
};

export type SupportedCountriesDataType = {
  code: string;
  commonName: string;
  currencies: CurrencyType[];
  emojiFlag: string;
  subdivisions: SubdivisionType[];
  underwritingSubdivisionGroups: string[][];
};

export const SupportedCountriesContextProvider: FC<
  SupportedCountriesContextProviderProps
> = ({ children }) => {
  const { loading, error, data } = useGetEoRSupportedCountriesQuery();

  const supportedCountriesData: { [key: string]: SupportedCountriesDataType } =
    Object.fromEntries(
      data?.eorSupportedCountries.map((country) => [
        country.code,
        { ...country.config, code: country.code },
      ]) || []
    );

  const getCountryName = (countryCode?: string): string => {
    if (countryCode && countryCode in supportedCountriesData) {
      return supportedCountriesData[countryCode].commonName;
    }
    return "Unknown Country";
  };

  // right now each country only has 1 supported currency, so we will just take the first one
  const getCurrency = (countryCode?: string): CurrencyType => {
    if (countryCode && countryCode in supportedCountriesData) {
      return (
        supportedCountriesData[countryCode].currencies[0] ??
        initialCurrencyContext
      );
    }
    return initialCurrencyContext;
  };

  const getSupportedProvinces = (countryCode?: string) => {
    if (countryCode && countryCode in supportedCountriesData) {
      return supportedCountriesData[countryCode].subdivisions;
    }
    return [];
  };

  const getSubdivisionName = (
    subdivisionCode: string,
    countryCode: string
  ): string => {
    const subdivisionName = getSupportedProvinces(countryCode).find(
      (value) => value.code === subdivisionCode
    )?.name;
    return subdivisionName || "";
  };

  return (
    <SupportedCountriesContext.Provider
      value={{
        loading,
        error,
        supportedCountriesData,
        getCurrency,
        getCountryName,
        getSubdivisionName,
        getSupportedProvinces,
      }}
    >
      {children}
    </SupportedCountriesContext.Provider>
  );
};
