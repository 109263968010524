import { ReactElement } from "react";
import {
  FilePlaceholder,
  FormField,
  FileInput as MiloFileInput,
} from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import styles from "../../../../SetupFormGenerator.module.css";
import { GeneratedComponentPropWithSet } from "../../../../types";
import {
  AdditionalPayFormData,
  FormPayloadType,
} from "../../../../../../types";
import { useFormikContext } from "formik";
import { ReimbursementPayloadType } from "../../../../../Reimbursement";

export function FileInput<T extends FormPayloadType = FormPayloadType>({
  field,
  accessor,
  touchedField,
  errorField,
  setFieldValue,
}: GeneratedComponentPropWithSet<T>): ReactElement {
  const { values } =
    useFormikContext<AdditionalPayFormData<ReimbursementPayloadType>>();
  const previouslyUploadedFiles =
    values.setUp.payload.expenseDetails.receipts?.map((receipt) => {
      return {
        id: receipt.name,
        name: receipt.name,
      };
    });

  const removeFile = (fileToRemove: File | FilePlaceholder | undefined) => {
    const receipts = values.setUp.payload.expenseDetails.receipts || [];
    const updatedReceipts = receipts.filter(
      (file) => file.name !== fileToRemove?.name
    );
    setFieldValue(`setUp.${accessor}`, updatedReceipts);
  };

  const addFile = (file: File | File[] | undefined) => {
    const receipts = values.setUp.payload.expenseDetails.receipts || [];
    setFieldValue(`setUp.${accessor}`, [...(file as File[]), ...receipts]);
  };

  return (
    <FormField
      label={field.label}
      required={field.required}
      error={errorField && touchedField ? errorField : ""}
    >
      <>
        {field.message && (
          <div className={styles.description}>
            <MessageWithLink {...field.message} />
          </div>
        )}
        <MiloFileInput
          multiple
          className={`${
            errorField && touchedField ? styles.fileInputError : ""
          }`}
          aria-required
          data-testid="fileinput"
          previouslyUploadedFiles={previouslyUploadedFiles}
          accept=".pdf, .jpeg, .png"
          onFileRemove={removeFile}
          onFileAdd={addFile}
        />
      </>
    </FormField>
  );
}
