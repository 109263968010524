import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, REQUIRED } from "./utils";
import * as singapore from "pages/employer-of-record/onboarding/profile-info/form/sg/constants.sg";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const singaporeFields = {
  sgGender: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "sgGender",
    label: "Gender",
    placeholder: "Select Gender",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: singapore.GENDER_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("SG"),
    },
    value: () => "", // TODO: Add expose in EOR EE profile GQL
  }),
  sgCitizenshipStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "sgCitizenshipStatus",
    label: "Citizenship Status",
    placeholder: "Select Citizenship status",
    options: singapore.CITIZENSHIP_STATUS_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("SG"),
    },
    value: () => "", // TODO: Add expose in EOR EE profile GQL
  }),
  sgPermanentResidenceStartDate: field({
    type: FieldTypes.date,
    informationType: "identification",
    name: "sgPermanentResidenceStartDate",
    label: "Date permanent residence began",
    validations: REQUIRED,
    hideWhen: {
      sgCitizenshipStatus: (value) =>
        typeof value === "string" && value !== "permanent-resident",
    },
    scenarios: {
      onboarding: countryIs("SG"),
    },
    value: () => "",
    serialize: (value) => (value === "" ? null : value), // TODO: Add expose in EOR EE profile GQL
  }),
  sgRace: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "sgRace",
    label: "Race (as listed on your ID)",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("SG"),
    },
    value: () => "", // TODO: Add expose in EOR EE profile GQL
  }),
  sgShgFunds: field({
    type: FieldTypes.multiSelect,
    informationType: "identification",
    name: "sgShgFunds",
    options: singapore.SHG_FUNDS_OPTIONS,
    label: "Self Help Group Funds (SHG)",
    message:
      "Employers in Singapore are expected to deduct contributions to the appropriate SHG fund from their employees' wages, based on the employees race or religion as indicated on their National Registration Identity Card. For more information or to change your contributions, visit the Central Provident Fund Board (CPFB) website.",
    scenarios: {
      onboarding: countryIs("SG"),
    },
    value: () => [], // TODO: Add expose in EOR EE profile GQL
  }),
  sgNric: identifierNumberFieldConfig("SG"),
  sgConfirmNric: confirmIdentifierNumberFieldConfig("SG"),
};
