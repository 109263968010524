import { FormField, TextInput } from "@justworkshr/milo-form";
import { PayBasis } from "../invite/types";
import { useContext } from "react";
import {
  SupportedCountriesContext,
  SupportedCountriesData,
} from "../contexts/supportedCountriesContext";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { fieldErrorHandler } from "./utils/fieldErrorHandler";

type Props = {
  payBasis: PayBasis;
};

const getCurrencySymbol = (
  supportedCountriesData: SupportedCountriesData,
  country: string
) => {
  const currencies = supportedCountriesData[country]?.currencies;
  return currencies?.length ? `${currencies[0].symbol}` : "";
};

export const schema = (
  supportedCountriesData: SupportedCountriesData,
  previousPay?: number
) =>
  Yup.object({
    workCountry: Yup.string()
      .oneOf(["", ...Object.keys(supportedCountriesData)])
      .required("Required"),
    pay: Yup.number()
      .typeError("Must be a number.")
      .test("is-greater-than-1", `Must be $1 or greater.`, function (number) {
        if (!number) return true;

        const valid = !isNaN(number) && number >= 1;
        const country = this.resolve(Yup.ref("workCountry")) as string;
        const symbol = getCurrencySymbol(supportedCountriesData, country);
        if (!valid && symbol) {
          return this.createError({
            message: `Must be ${symbol}1 or greater.`,
          });
        }
        return valid;
      })
      .test(
        "is-greater-than-previous-pay",
        `Must be greater than the previous pay`,
        (number) => {
          if (!previousPay || !number) return true;
          return !isNaN(number) && number > previousPay;
        }
      )
      .test(
        "not-alone",
        `Must provide at least one of pay, title, or responsibilities`,
        function (number) {
          const jobTitle = this.resolve(Yup.ref("jobTitle")) as string;
          const roleResponsibilities = this.resolve(
            Yup.ref("roleResponsibilities")
          ) as string;

          return !!jobTitle || !!roleResponsibilities || !!number;
        }
      ),
  });

export type Schema = Yup.InferType<ReturnType<typeof schema>>;

export default function PayRateField({ payBasis }: Props) {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<Schema>();
  const { getCurrency } = useContext(SupportedCountriesContext);

  function payAmountLabel() {
    const currency = getCurrency(values.workCountry).currencyCode;
    const formattedCurrency = currency ? ` (${currency})` : "";
    const basis =
      payBasis === PayBasis.Hourly
        ? t(`Pay amount per hour`)
        : t(`Pay amount per year`);
    return basis + formattedCurrency;
  }

  return (
    <FormField
      required
      name="pay"
      label={payAmountLabel()}
      error={fieldErrorHandler("pay", errors, touched)}
    >
      <TextInput
        name="pay"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.pay?.toString()}
      />
    </FormField>
  );
}
