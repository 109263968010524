import React, { FC } from "react";
import { FileInput } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Photo } from "types/generated/operations";

interface FileUploadProps {
  name: string;
  accept?: string;
  maxSize?: number;
}

const readAsDataURL = async (file: File): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result?.toString());
    };
    fr.readAsDataURL(file);
  });
};

export const FileUpload: FC<FileUploadProps> = ({
  name,
  accept = "application/pdf",
  maxSize = 10 * 1024 * 1024, // 10MB default
}) => {
  const { t } = useTranslation();
  const { setFieldValue, setFieldError } =
    useFormikContext<Record<string, Photo | undefined>>();

  const validateAndSelectFile = async (file?: File | File[]) => {
    if (!file) {
      setFieldValue(name, undefined);
      return;
    }

    const singleFile = Array.isArray(file) ? file[0] : file;
    if (!singleFile) {
      setFieldValue(name, undefined);
      return;
    }

    if (
      !accept
        .split(",")
        .map((type) => type.trim())
        .includes(singleFile.type)
    ) {
      setFieldError(
        name,
        t("Only specified file types are accepted, please try again.")
      );
      return;
    }

    if (singleFile.size > maxSize) {
      setFieldError(name, t("File size exceeds the maximum allowed size."));
      return;
    }

    try {
      const processedFile = {
        blobFile: await readAsDataURL(singleFile),
        contentType: singleFile.type,
        originalFilename: singleFile.name,
      };
      setFieldValue(name, processedFile);
      setFieldError(name, undefined);
    } catch (error) {
      setFieldError(name, t("Error processing file. Please try again."));
    }
  };

  return <FileInput accept={accept} onFileChange={validateAndSelectFile} />;
};
