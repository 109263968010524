import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "@justworkshr/milo-core";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import styles from "../../Setup.module.css";
import { AdditionalPayFormData } from "../../types";
import { useFormikContext } from "formik";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig, SetupFormGenerator } from "../SetupFormGenerator";
import {
  BenefitsAndAllowancesPayloadType,
  TaxableBenefitsPaymentSettings,
} from "./types";
import CountrySelect from "../CountrySelect/CountrySelect";
import useCountryConfigFetcher from "./hooks/useCountryConfigFetcher";
import { DecoratedMessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";
import { DecoratedMessageWithLink } from "pages/employer-of-record/components/MessageWithLink";

export const BenefitsAndAllowances = (): ReactElement => {
  const { handleChange, errors, touched, values, setFieldValue, handleBlur } =
    useFormikContext<AdditionalPayFormData<BenefitsAndAllowancesPayloadType>>();
  const setUpData = values.setUp;

  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig[]
  >([]);
  const [taxableBenefitsValues, setTaxableBenefitsValues] = useState<string[]>(
    []
  );
  const [link, setLink] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<DecoratedMessageWithLinkType | null>(
    null
  );

  const additionalPayContext = useContext(AdditionalPayContext);

  useCountryConfigFetcher(
    additionalPayContext,
    setPaymentSettingsFields,
    setTaxableBenefitsValues,
    setLink,
    setMessage
  );

  const width = additionalPayContext?.isEdit ? "100%" : "50%";

  const hasErrorForField = (
    fieldName: keyof TaxableBenefitsPaymentSettings
  ) => {
    const hasError =
      errors.setUp &&
      errors.setUp.payload &&
      errors.setUp.payload.paymentSettings?.[fieldName];
    const isTouched =
      touched.setUp &&
      touched.setUp.payload &&
      touched.setUp.payload.paymentSettings?.[fieldName];
    return hasError && isTouched;
  };

  const disbursementDateOptions = additionalPayContext?.disbursementDateOptions;

  useEffect(() => {
    const isNonCashIncome =
      values.setUp.payload.paymentSettings.formOfIncome === "non-cash";
    const dates = disbursementDateOptions
      ? Object.values(disbursementDateOptions)
      : [];

    if (isNonCashIncome && dates.length > 0) {
      const currentDate = new Date();

      let date = dates.find((date) => new Date(date.label) > currentDate);

      if (!date) {
        date = dates[0];
      }

      setFieldValue("setUp.payload.paymentSettings.payPeriodId", date.value);
      setFieldValue(
        "setUp.payload.paymentSettings.disbursementDate",
        date.label
      );
    }
  }, [
    values.setUp.payload.paymentSettings.formOfIncome,
    disbursementDateOptions,
    setFieldValue,
  ]);

  const BenefitTypeField = () => {
    return (
      <div
        className={`${styles.inputField} ${
          hasErrorForField("benefitType") ? styles.error : ""
        }`}
        style={{ width }}
      >
        <FormField
          label="Taxable benefit type"
          required
          error={
            hasErrorForField("benefitType")
              ? errors.setUp?.payload?.paymentSettings?.benefitType
              : ""
          }
        >
          <>
            {link && (
              <div className={styles.description}>
                <MessageWithLink
                  text="Not sure which benefits apply? Learn about benefit types"
                  link={link}
                  linkText="Learn about benefit types"
                />
              </div>
            )}

            <Select
              name="setUp.payload.paymentSettings.benefitType"
              placeholder="Select..."
              value={setUpData.payload.paymentSettings.benefitType}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {taxableBenefitsValues.map((type) => (
                <SelectOption key={type} value={type}>
                  {type}
                </SelectOption>
              ))}
            </Select>
          </>
        </FormField>
        {message && <DecoratedMessageWithLink {...message} />}
      </div>
    );
  };

  const CountrySelectField = () => {
    const message = !additionalPayContext?.isEdit
      ? "You’ll choose employees to pay in the next step."
      : "";
    return (
      <div className={styles.inputField} style={{ width }}>
        <FormField label="Work country" required message={message}>
          <CountrySelect />
        </FormField>
      </div>
    );
  };

  const BenefitTypeAndCountryFields = () => {
    const isEdit = additionalPayContext?.isEdit;
    const content = (
      <>
        <CountrySelectField />
        <BenefitTypeField />
      </>
    );

    return isEdit ? (
      content
    ) : (
      <div className={styles.card}>
        <Card title="Pay type">{content}</Card>
      </div>
    );
  };

  return (
    <>
      <BenefitTypeAndCountryFields />
      {additionalPayContext?.selectedCountry && (
        <div className={styles.card}>
          <SetupFormGenerator<BenefitsAndAllowancesPayloadType>
            title="Payment settings"
            fields={paymentSettingsFields}
            formikFieldName="paymentSettings"
          />
        </div>
      )}
    </>
  );
};
