import * as Yup from "yup";
import {
  noEmojiTest,
  maxFileSize,
  supportedFileFormats,
} from "../../validations";
import {
  MAX_REIMBURSEMENT_FILE_SIZE,
  REIUMBURSEMENT_SUPPORTED_FILE_FORMATS,
} from "./constants";

export const expensePayloadSchema = Yup.object().shape({
  expenseDetails: Yup.object().shape({
    distance: Yup.lazy((_, { context }) => {
      if (context.setUp.payload.paymentSettings.expenseType === "Mileage") {
        return Yup.string()
          .required("Distance is required")
          .test(
            "is-number",
            "This field must contain only numbers",
            (value) => {
              if (value) {
                const numberWithoutCommas = value.replace(/,/g, "");
                return !isNaN(Number(numberWithoutCommas));
              }
              return true;
            }
          );
      }

      return Yup.object();
    }),
    transactionDate: Yup.string().required("Transaction date is required"),

    receipts: Yup.array()
      .required("Receipt is required")
      .min(1, "Receipt is required")
      .of(maxFileSize(MAX_REIMBURSEMENT_FILE_SIZE))
      .of(supportedFileFormats(REIUMBURSEMENT_SUPPORTED_FILE_FORMATS)),
    additionalInformation: noEmojiTest.required(
      "Additional information is required"
    ),
  }),
  paymentSettings: Yup.object().shape({
    payPeriodId: Yup.string().required("Pay date is required"),
    expenseType: Yup.string().required("Expense type is required"),
    notes: noEmojiTest,
  }),
});
