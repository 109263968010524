import { useFormikContext } from "formik";
import { ReactElement, useContext, useState } from "react";
import { AdditionalPayFormData } from "../../types";
import { Card } from "@justworkshr/milo-core";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import styles from "../../Setup.module.css";
import { FieldConfig, SetupFormGenerator } from "../SetupFormGenerator";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { ReimbursementPayloadType } from "./types";
import CountrySelect from "../CountrySelect/CountrySelect";
import useCountryConfigFetcher from "./hooks/useCountryConfigFetcher";
import { DecoratedMessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";
import { DecoratedMessageWithLink } from "pages/employer-of-record/components/MessageWithLink";

export const Reimbursement = (): ReactElement => {
  const formikContext =
    useFormikContext<AdditionalPayFormData<ReimbursementPayloadType>>();
  const { handleChange, errors, touched, values, handleBlur } = formikContext;
  const setUpData = values.setUp;

  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig[]
  >([]);
  const [expenseDetailsFields, setExpenseDetailsFields] = useState<
    FieldConfig[]
  >([]);
  const [expenseTypes, setExpenseTypes] = useState<string[]>([]);
  const [message, setMessage] = useState<DecoratedMessageWithLinkType | null>(
    null
  );

  const additionalPayContext = useContext(AdditionalPayContext);

  useCountryConfigFetcher(
    additionalPayContext,
    setPaymentSettingsFields,
    setExpenseDetailsFields,
    setExpenseTypes,
    setMessage
  );

  const width = additionalPayContext?.isEdit ? "100%" : "50%";

  const ExpenseTypeField = () => {
    return (
      <>
        <div
          className={`${styles.inputField} ${
            errors.setUp?.payload?.paymentSettings?.expenseType &&
            touched.setUp?.payload?.paymentSettings?.expenseType
              ? styles.error
              : ""
          }`}
          style={{ width }}
        >
          <FormField
            label="Expense type"
            required
            error={
              errors.setUp?.payload?.paymentSettings?.expenseType &&
              touched.setUp?.payload?.paymentSettings?.expenseType
                ? errors.setUp?.payload?.paymentSettings?.expenseType
                : ""
            }
          >
            <Select
              name="setUp.payload.paymentSettings.expenseType"
              placeholder="Select..."
              value={setUpData.payload.paymentSettings.expenseType}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {expenseTypes.map((type) => (
                <SelectOption key={type} value={type}>
                  {type}
                </SelectOption>
              ))}
            </Select>
          </FormField>
        </div>

        {message && <DecoratedMessageWithLink {...message} />}
      </>
    );
  };

  const CountrySelectField = () => {
    const message = !additionalPayContext?.isEdit
      ? "You’ll choose an employee to pay in the next step. You can schedule one expense reimbursement for one employee at a time."
      : "";
    return (
      <div
        className={`${styles.inputField} ${
          errors.setUp?.workCountry && touched.setUp?.workCountry
            ? styles.error
            : ""
        }`}
        style={{ width }}
      >
        <FormField
          label="Work country"
          required
          error={
            errors.setUp?.workCountry && touched.setUp?.workCountry
              ? errors.setUp?.workCountry
              : ""
          }
          message={message}
        >
          <CountrySelect />
        </FormField>
      </div>
    );
  };

  const ExpenseTypeAndCountryFields = () => {
    const content = (
      <>
        <CountrySelectField />
        <ExpenseTypeField />
      </>
    );

    return additionalPayContext?.isEdit ? (
      content
    ) : (
      <>
        <div className={styles.card}>
          <Card title="Pay type">{content}</Card>
        </div>
      </>
    );
  };

  return (
    <>
      <ExpenseTypeAndCountryFields />
      {additionalPayContext?.selectedCountry && (
        <>
          <div className={styles.card}>
            <SetupFormGenerator<ReimbursementPayloadType>
              title="Expense details"
              fields={expenseDetailsFields}
              formikFieldName="expenseDetails"
            />
          </div>

          <div className={styles.card}>
            <SetupFormGenerator<ReimbursementPayloadType>
              title="Payment settings"
              fields={paymentSettingsFields}
              formikFieldName="paymentSettings"
            />
          </div>
        </>
      )}
    </>
  );
};
