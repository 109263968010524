import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import onboardingStyles from "../../../Onboarding.module.css";
import styles from "./ProfileInfoContent.module.css";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../BaseFormFragment/BaseFormFragment";
import {
  initialValues,
  PROFILE_FIELDS,
  useFields,
} from "pages/employer-of-record/configs";
import {
  AutoFormField,
  generateFormValidations,
} from "pages/employer-of-record/components/AutoForm";
import { eraseSensitiveValues } from "pages/employer-of-record/employee-profile/utils";
import { australiaFields } from "pages/employer-of-record/configs/countries/auFields";
import { franceFields } from "pages/employer-of-record/configs/countries/frFields";
import partition from "lodash/partition";
import { EorEmployeeProfile } from "types/employer-of-record";
import mapValues from "lodash/mapValues";
import { Dictionary } from "lodash";

const state_key = "profile-info";
const PRE_BASE_FIELDS = [
  australiaFields.auTitle.name,
  franceFields.frTitle.name,
];

type Props = { profile: EorEmployeeProfile };

function ProfileInfoContent({ profile }: Props) {
  const { t } = useTranslation();
  const allFields = useFields({
    scenario: "onboarding",
    overrides: {
      ptCc: {
        label: "Cartão de cidadão number (Citizen card number)",
      },
      ptCcIssDate: {
        label: "Cartão de cidadão Issue date",
      },
      ptCcExpDate: {
        label: "Cartão de cidadão expiration date",
      },
      ptNiss: {
        label: "Instituto Nacional do Seguro Social (NISS)",
      },
    },
  });
  const [preBaseFields, fields] = partition(allFields, (field) =>
    PRE_BASE_FIELDS.includes(field.name)
  );
  const {
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: { profileInfo },
  } = useOnboardingForm(state_key);

  const schemas = {
    base: baseSchema(t),
    country: generateFormValidations(allFields, t),
  };

  const validationSchema = schemas.base.concat(schemas.country);

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const DEFAULT_VALUES: ValidationSchema = {
    ...BASE_INITIAL_VALUES,
    ...eraseSensitiveValues(initialValues(allFields, profile)),
    ...profileInfo?.profileInfo,
  };

  const onFormSubmit = (values: ValidationSchema) => {
    const serializedValues: Dictionary<unknown> = mapValues(
      values,
      (value: string, key: keyof typeof PROFILE_FIELDS) => {
        const field = allFields.find((field) => field.name === key);
        return field?.serialize ? field.serialize(value) : value;
      }
    );

    if (workCountry == "ES") {
      const birthDatesAsArray =
        serializedValues.esChildrensBirthDates instanceof Array
          ? serializedValues.esChildrensBirthDates
          : [];
      const filteredBirthDates = birthDatesAsArray.filter(Boolean);

      serializedValues.esChildrensBirthDates = filteredBirthDates;
      serializedValues.esNumChildren = filteredBirthDates.length;
    }

    // Some countries do not have an identifier number field, so do not send identifier number values when that is the case
    const identifierNumberField = allFields.find(
      (field) => field.name === "identifierNumber"
    );

    if (!identifierNumberField) {
      delete serializedValues.identifierNumber;
      delete serializedValues.confirmIdentifierNumber;
    }

    onSubmit({
      profileInfo: serializedValues,
    });
  };

  return (
    <>
      <Alert color="destructive" visible={showErrorAlert}>
        {errorAlertMessage ||
          t("An error occurred while submitting your profile information.")}
      </Alert>

      <PageHeader title="Complete your profile" />

      <Formik
        initialValues={DEFAULT_VALUES}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Card title={t("Personal information")}>
                {!!preBaseFields.length && (
                  <div className={styles.fields}>
                    {preBaseFields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </div>
                )}
                <BaseFormFragment />
                <div className={styles.fields}>
                  {fields.map((field) => (
                    <AutoFormField key={field.name} {...field} />
                  ))}
                </div>
              </Card>

              <ActionFooter
                className={onboardingStyles.footer}
                actions={[
                  <Button
                    color="brand"
                    key="submit-btn"
                    loading={submitting}
                    type="submit"
                  >
                    {t("Save & continue")}
                  </Button>,
                ]}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ProfileInfoContent;
