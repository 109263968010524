import { PageHeader, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useNeonPath } from "lib/resource-finder";
import styles from "pages/company-settings/components/ReconnectPlaid.module.css";
import usePlaidBankAccountFlow from "../hooks/usePlaidBankAccountFlow";

const { page, container, subheader, footer } = styles;

export default function ReconnectPlaid() {
  const title = "Reconnect your bank account";
  const companyPath = useNeonPath("/company");
  const linkPrevious = <a href={companyPath}>{"Back to settings"}</a>;

  const { openPlaidModal, isPending: isPlaidPending } =
    usePlaidBankAccountFlow("update");

  return (
    <div className={page}>
      <PageHeader title={title} linkPrevious={linkPrevious} />
      <div className={container}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <h2 className={subheader}>Avoid delays to payroll</h2>
          <p>
            We use Plaid-a payments platform-to securely connect to your bank
            for 2-day payroll processing. For some reason, we weren’t able to
            connect to your bank and need you to reconnect using Plaid.
          </p>
        </div>
        <ActionFooter
          className={footer}
          actions={[
            <Button onClick={openPlaidModal} loading={isPlaidPending}>
              Connect your bank
            </Button>,
          ]}
        />
      </div>
    </div>
  );
}
