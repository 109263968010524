import { ReactElement, useContext, useEffect } from "react";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import styles from "./Edit.module.css";
import SummaryCard from "../Review/components/SummaryCard/SummaryCard";
import { Details } from "./Details";
import { useFormikContext } from "formik";
import { AdditionalPayFormData, FormPayloadType } from "../Setup/types";
import { GetDisbursementOrderQuery } from "types/generated/operations";
import OrderSettingsContent from "../Setup/components/OrderSettingsContent/OrderSettingsContent";
import { useParams } from "react-router-dom";
import { Card } from "@justworkshr/milo-core";
import { formatDateToISO8601 } from "pages/employer-of-record/components/DatePicker";

interface EditProps {
  memberData: GetDisbursementOrderQuery["disbursementOrder"]["member"];
}

export const Edit = ({ memberData }: EditProps): ReactElement => {
  const { values } = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const additionalPayContext = useContext(AdditionalPayContext);
  const companyDisbursementDates =
    additionalPayContext?.disbursementDateOptions;
  additionalPayContext?.setIsEdit(true);
  additionalPayContext?.setSelectedCountry(values.setUp.workCountry || "");
  const { formType } = useParams();

  useEffect(
    () => {
      const payPeriodId = values.setUp.payload.paymentSettings.payPeriodId;
      if (!payPeriodId || !companyDisbursementDates) return;
      const selectedDisbursementDate = companyDisbursementDates[payPeriodId];
      values.setUp.payload.paymentSettings.disbursementDate =
        formatDateToISO8601(selectedDisbursementDate?.label);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.setUp.payload.paymentSettings.payPeriodId, companyDisbursementDates]
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.cardWrapper}>
          <div className={styles.column}>
            <Details member={memberData} />

            <Card title="Settings">
              <OrderSettingsContent
                formType={formType}
                handleDisclaimersChecked={() => {}}
              />
            </Card>
          </div>

          <div className={styles.column}>
            <SummaryCard isEdit />
          </div>
        </div>
      </div>
    </>
  );
};
