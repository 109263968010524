import { ReactElement, useContext } from "react";
import styles from "./PayNavigation.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  ADDITIONAL_PAY_FORM_ROUTES,
  CLOCKWORK_WEB_PATH,
} from "../../constants";
import { useParams } from "react-router";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";

const getFormTitle = (formType: string) => {
  switch (formType) {
    case ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT:
      return "Expense Reimbursement";
    case ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES:
      return "Taxable benefits & allowances";
    case ADDITIONAL_PAY_FORM_ROUTES.EMPLOYEE_PAY:
      return "Employee Pay";
    default:
      return "Edit payment details";
  }
};

export const PayNavigation = (): ReactElement => {
  const { formType } = useParams();
  const additionalPayContext = useContext(AdditionalPayContext);
  const isEdit = additionalPayContext?.isEdit;

  return (
    <>
      <div className={styles.nav}>
        <SystemIcon iconName="arrow-left" size="small" color="brand" />
        <a href={buildClockworkWebPath(CLOCKWORK_WEB_PATH.PAYMENTCENTER)}>
          Back to Payment center
        </a>
      </div>

      <h1>
        {!isEdit && getFormTitle(formType || "")}{" "}
        {isEdit && "Edit payment details"}
      </h1>
    </>
  );
};
