import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, REQUIRED } from "./utils";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const netherlandsFields = {
  nlWajongBenefitsEligible: field({
    type: FieldTypes.boolean,
    informationType: "identification",
    name: "nlWajongBenefitsEligible",
    label: "Are you eligible for Wajong benefits?",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("NL"),
    },
    value: () => false, // TODO: IEORN-3827 personalInfo("nlWajongBenefitsEligible", false),
    format: (value) => (value.toString() === "true" ? "Yes" : "No"),
  }),

  nlArbeidsgehandicapte: field({
    type: FieldTypes.boolean,
    informationType: "identification",
    name: "nlArbeidsgehandicapte",
    label:
      "Do you have a disability for which you may require reasonable accommodations (Arbeidsgehandicapte)?",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("NL"),
    },
    value: () => false, // TODO: IEORN-3827 personalInfo("nlArbeidsgehandicapte", false),
    format: (value) => (value.toString() === "true" ? "Yes" : "No"),
  }),
  nlBsn: identifierNumberFieldConfig("NL"),
  nlConfirmBsn: confirmIdentifierNumberFieldConfig("NL"),
};
