import * as Yup from "yup";
import {
  getTotalGrossAmount,
  hasWorkPeriod,
  validateOffCycleRanges,
} from "../../utils";
import { PaymentDetailsType } from "../Setup/types";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { EmployeePayType } from "../../constants";

const grossAmountValidation = Yup.lazy((_, { context }) => {
  const paymentDetails = context?.paymentDetails || [];
  const isSinglePaymentDetail = paymentDetails.length === 1;
  const maximumFractionDigist =
    paymentDetails[0]?.currency === null
      ? 2
      : Intl.NumberFormat("en-us", {
          style: "currency",
          currency: paymentDetails[0].currency,
        }).resolvedOptions().maximumFractionDigits;
  const schema = Yup.string()
    .nullable()
    .test(
      "does-number-have-correct-decimal-places",
      `${paymentDetails[0]?.currency} does not support decimal places`,
      (value) => {
        if (maximumFractionDigist === 0) {
          return !value || !value.toString().includes(".");
        }
        return true;
      }
    )
    .test("is-number", "This field must contain only numbers", (value) => {
      return !value || !isNaN(Number(value));
    })
    .test("is-greater-than-1", "Amount must be greater than zero", (value) => {
      return !value || parseFloat(value) >= 1;
    })
    .test(
      "validation-for-single-rows",
      "Enter a payment amount to continue",
      (value) => {
        return isSinglePaymentDetail ? value != undefined : true;
      }
    );

  return schema;
});

const workPeriodStartValidation = Yup.lazy((_, { context, parent }) => {
  const { workStartDate, workEndDate } =
    context?.validationData?.members.find(
      (member: { id: string }) => member.id === parent.memberId
    ) || {};

  let message = `Work period needs to fall within their contract term of ${formatDate(
    workStartDate,
    "MM/DD/YY"
  )} - ${formatDate(workEndDate, "MM/DD/YY")}`;

  if (!workEndDate) {
    message = `Work period start needs to fall on or after ${formatDate(
      workStartDate,
      "MM/DD/YY"
    )}`;
  }

  const schema = Yup.string();
  return parent.grossAmount && hasWorkPeriod(context?.setUp)
    ? schema
        .required("Work period start is required")
        .test("is-after-contract-start", message, function (val) {
          if (!val) {
            return true;
          }
          if (context?.setUp.type === EmployeePayType.OFF_CYCLE) {
            const { workPeriodEnd } = parent;
            return validateOffCycleRanges(workStartDate, workPeriodEnd);
          }

          return new Date(val).getTime() >= new Date(workStartDate).getTime();
        })
    : schema;
});

const workPeriodEndValidation = Yup.lazy((_, { context, parent }) => {
  const { workStartDate, workEndDate } =
    context?.validationData?.members.find(
      (member: { id: string }) => member.id === parent.memberId
    ) || {};

  const schema = Yup.string();

  return parent.grossAmount && hasWorkPeriod(context?.setUp)
    ? schema
        .required("Work period end is required")
        .test(
          "is-after-start",
          "Select an end date that's after your selected start date",
          function (value) {
            return (
              new Date(value).getTime() >
              new Date(parent.workPeriodStart).getTime()
            );
          }
        )
        .test(
          "is-before-contract-end",
          `Work period needs to fall within their contract term of ${formatDate(
            workStartDate,
            "MM/DD/YY"
          )} - ${formatDate(workEndDate, "MM/DD/YY")}`,
          function (value) {
            if (!workEndDate) {
              return true;
            }

            return new Date(value).getTime() < new Date(workEndDate).getTime();
          }
        )
    : schema;
});

const paymentDetailsSchema = Yup.object().shape({
  grossAmount: grossAmountValidation,
  workPeriodStart: workPeriodStartValidation,
  workPeriodEnd: workPeriodEndValidation,
});

let paymentDetailValidation = Yup.array().of(paymentDetailsSchema);

export const paymentValidationSchema = Yup.array()
  .of(paymentDetailsSchema)
  .when(["setUp.payload.taxes.bonusType"], ([], tBonusType, schema) => {
    const { paymentDetails } = schema?.context || {};
    if (paymentDetails.length > 1) {
      paymentDetailValidation = paymentDetailValidation.test(
        "at-least-one-gross-amount",
        "Enter a payment amount to continue.",
        function (value) {
          const grossAmount = getTotalGrossAmount(
            value as PaymentDetailsType[]
          );
          return (Number(grossAmount) || 0) > 0;
        }
      );
    }
    return paymentDetailValidation;
  });
