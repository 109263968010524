import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, REQUIRED } from "./utils";
import * as ITALY from "pages/employer-of-record/onboarding/profile-info/form/it/constants.it";

export const italyFields = {
  itCodiceFiscale: field({
    type: FieldTypes.text,
    name: "itCodiceFiscale",
    label: "Codice Fiscale (Tax Code)",
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: ITALY.itCodiceFiscaleRegex,
          message: "Must be a valid Personal ID.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("IT"),
    },
    informationType: "identifierNumber",
    value: () => "", //TODO: Expose itCodiceFiscale
  }),
  itBirthplace: field({
    type: FieldTypes.text,
    name: "itBirthplace",
    label: "Luogo Di Nascita (Place of Birth)",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("IT"),
    },
    informationType: "identification",
    value: () => "", //TODO: Expose itBirthplace
  }),
  itEducationalLevel: field({
    type: FieldTypes.select,
    name: "itEducationalLevel",
    label: "Titolo Di Studio (Education Level)",
    placeholder: "Select Education Level",
    validations: REQUIRED,
    options: ITALY.EDUCATION_LEVEL,
    scenarios: {
      onboarding: countryIs("IT"),
    },
    informationType: "identification",
    value: () => "", //TODO: Expose itEducationalLevel
  }),
};
