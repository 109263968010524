import { Avatar } from "@justworkshr/milo-core";
import { ReactElement, useContext } from "react";
import styles from "./PaymentDetailsRow.module.css";
import {
  getActiveCurrency,
  getEmployeeName,
} from "pages/employer-of-record/payments/utils";
import { AdditionalPayFormData, FormPayloadType } from "../../../Setup/types";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import { SupportedCountriesDataType } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { GrossAmountInput } from "../../../GrossAmountInput/GrossAmountInput";
import { PayDateComponent } from "./PayDateComponent";

type Props = {
  countryInfo: SupportedCountriesDataType;
  member: EorMembersType;
  hasDatePeriod: boolean;
  exchangeRate: number | undefined;
  hasApplyToAllButton: boolean;
  applyToAll: (fields: string[]) => void;
};

export const PaymentDetailsRow = ({
  member,
  countryInfo,
  hasDatePeriod,
  exchangeRate,
  hasApplyToAllButton,
  applyToAll,
}: Props): ReactElement => {
  const formik = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { values } = formik;
  const additionalPayContext = useContext(AdditionalPayContext);

  const index = values.paymentDetails.findIndex(
    (paymentDetail) => paymentDetail.memberId === member.id
  );

  if (index === -1) {
    additionalPayContext?.setErrorMessage(
      "Unexpected error, please try again later."
    );
    additionalPayContext?.setCurrentStep("set-up");
  }

  const rowStarted =
    !!values.paymentDetails[index]?.grossAmount ||
    (hasDatePeriod &&
      (!!values.paymentDetails[index]?.workPeriodStart ||
        !!values.paymentDetails[index]?.workPeriodEnd));

  const fullName = getEmployeeName(member);
  const currencyData = getActiveCurrency(countryInfo.currencies);
  const currency = currencyData?.currencyCode || "";
  const currencySymbol = currencyData?.symbol || "";

  return (
    <>
      <tr className={`${rowStarted ? styles.rowStarted : ""}`}>
        <td className={styles.td}>
          <div className={styles.nameWrapper}>
            <Avatar name={fullName} />
            <div className={styles.name}>
              <Link
                to={buildClockworkWebPath(`members/${member.memberUuid}`)}
                target="_blank"
              >
                {fullName}
              </Link>
            </div>
          </div>
        </td>

        <td className={`${styles.td} ${styles.tdForm} `}>
          <GrossAmountInput
            index={index}
            currencySymbol={currencySymbol}
            currency={currency}
            exchangeRate={exchangeRate}
          />
        </td>

        <PayDateComponent
          member={member}
          countryInfo={countryInfo}
          hasDatePeriod={hasDatePeriod}
          hasApplyToAllButton={hasApplyToAllButton}
          applyToAll={applyToAll}
        />
      </tr>
    </>
  );
};
