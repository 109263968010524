import { ReactElement, useContext } from "react";
import styles from "./PersonalInfo.module.css";
import GeneralInformationDefault from "./components/GeneralInformation/GeneralInformationDefault";
import ContactInformation from "./components/ContactInformation/ContactInformation";
import AddressInformation from "./components/AddressInformation/AddressInformation";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import ProfileInfoCard from "./ProfileInfoCard";
import BadgeTitle from "./components/BadgeTitle";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useFields } from "pages/employer-of-record/configs";

type Props = {
  editable: boolean;
};

export default function PersonalInfo({ editable }: Props): ReactElement {
  const { getCountryName } = useContext(SupportedCountriesContext);
  const { profileInfo } = useProfileContextData();

  const cartaoFields = useFields({
    scenario: "viewProfile",
    informationType: "cartao",
  });

  const rgFields = useFields({
    scenario: "viewProfile",
    informationType: "rg",
  });

  const benefitsFields = useFields({
    scenario: "viewProfile",
    informationType: "benefits",
  });

  const workCountry = profileInfo?.eorEmployeeProfile?.employment?.workCountry;
  const countryName = getCountryName(workCountry);

  return (
    <>
      <div className={styles.cardSection}>
        <GeneralInformationDefault editable={editable} />
        <AddressInformation editable={editable} />
        <ContactInformation editable={editable} />

        {!!cartaoFields.length && (
          <ProfileInfoCard
            title={
              (
                <BadgeTitle title="Cartão de Cidadão" badgeText={countryName} />
              ) as unknown as string
            }
            editable
            informationType="pension"
            fields={cartaoFields}
          />
        )}

        {!!rgFields.length && (
          <ProfileInfoCard
            title={
              (
                <BadgeTitle
                  title="Carteira de identidade Nacional"
                  badgeText={countryName}
                />
              ) as unknown as string
            }
            editable
            informationType="rg"
            fields={rgFields}
          />
        )}

        {!!benefitsFields.length && (
          <ProfileInfoCard
            title={
              (
                <BadgeTitle title="Benefits" badgeText={countryName} />
              ) as unknown as string
            }
            editable={false}
            informationType="benefits"
            fields={benefitsFields}
          />
        )}
      </div>
    </>
  );
}
