import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import * as COSTA_RICA from "pages/employer-of-record/onboarding/profile-info/form/cr/constants.cr";

export const costaRicaFields = {
  crCcss: field({
    type: FieldTypes.text,
    name: "crCcss",
    label: "CCSS code (Caja Costarricense de Seguro Social)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("CR"),
      onboarding: countryIs("CR"),
    },
    informationType: "identification",
    value: personalInfo("crCcss", ""),
  }),
  crCedulaDeIdentidad: field({
    type: FieldTypes.text,
    name: "crCedulaDeIdentidad",
    label: "Cédula de Identidad",
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: COSTA_RICA.crCedulaDeIdentidadRegEx,
          message: "Must be a valid Personal ID.",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("CR"),
      onboarding: countryIs("CR"),
    },
    informationType: "identification",
    value: personalInfo("crCedulaDeIdentidad", ""),
  }),
  crIns: field({
    type: FieldTypes.text,
    name: "crIns",
    label: "INS code (Instituto Nacional de Seguros)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("CR"),
      onboarding: countryIs("CR"),
    },
    informationType: "identification",
    value: personalInfo("crIns", ""),
  }),
  crMaritalStatus: field({
    type: FieldTypes.select,
    name: "crMaritalStatus",
    label: "Marital Status",
    validations: REQUIRED,
    options: COSTA_RICA.MARITAL_STATUS_OPTIONS,
    scenarios: {
      viewProfile: countryIs("CR"),
      onboarding: countryIs("CR"),
    },
    informationType: "identification",
    value: personalInfo("crMaritalStatus", ""),
  }),
  crNumChildren: field({
    type: FieldTypes.number,
    name: "crNumChildren",
    label: "Number of Children",
    validations: {
      ...REQUIRED,
      number: {
        min: {
          value: 0,
          message: "Must be a positive number",
        },
        max: {
          value: 10,
          message: "Must be less than 10",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("CR"),
      onboarding: countryIs("CR"),
    },
    informationType: "identification",
    value: (profile) => profile.personalInfo?.crNumChildren?.toString() || "0",
  }),
  crSpouse: field({
    type: FieldTypes.boolean,
    name: "crSpouse",
    label: "Spouse",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("CR"),
      onboarding: countryIs("CR"),
    },
    informationType: "identification",
    value: personalInfo("crSpouse", false),
    format: (value) => (value.toString() === "true" ? "Yes" : "No"),
  }),
};
