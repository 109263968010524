import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, REQUIRED } from "./utils";
import * as PERU from "pages/employer-of-record/onboarding/profile-info/form/pe/constants.pe";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";

export const peruFields = {
  peGender: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "peGender",
    label: "Sexo / Gender",
    placeholder: "Select Gender",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: PERU.GENDER_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
  peMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "peMaritalStatus",
    label: "Estado Civil / Marital Status",
    placeholder: "Select Marital Status",
    options: PERU.MARITAL_STATUS_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
  peCountryOfBirth: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "peBirthCountry",
    label: "Pais Nacimiento / Country of Birth",
    placeholder: "Select Country of Birth",
    options: NATIONALITIES,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
  pePlaceOfBirth: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "peBirthPlace",
    label: "Lugar De Nac / Place of Birth (as in passport)",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
  pePensionFund: field({
    type: FieldTypes.select,
    informationType: "pension",
    name: "pePensionFund",
    label: "Codigo AFP / Pension Fund",
    placeholder: "Select Pension Fund",
    options: PERU.PENSION_FUND_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
  peAfpNumber: field({
    type: FieldTypes.text,
    informationType: "pension",
    name: "pePensionFundNumber",
    label: "Numero AFP / AFP Number",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
    hideWhen: {
      pePensionFund: (value) => value === "no_pension",
    },
  }),
  peIdType: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "peIdType",
    label: "Tipo Doc Identidad / National ID Type",
    placeholder: "Select ID Type",
    options: PERU.ID_TYPE_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
  peIdNumber: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "peIdNumber",
    label: "Numero Doc Identidad / National ID Number",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("PE"),
    },
    value: () => "",
  }),
};
