import { Alert, PageHeader } from "@justworkshr/milo-core";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useInviteEorMutation } from "types/generated/operations";
import { SupportedCountriesContext } from "../contexts/supportedCountriesContext";
import styles from "./InvitePage.module.css";
import Form, {
  COUNTRY_EXTENDED_CONFIG,
  SchemaType,
  isCountryExtendedConfig,
  type InviteFormProps,
} from "./form";
import {
  ContractTerm,
  EmailTypes,
  InviteView,
  PayBasis,
  type AlertState,
} from "./types";
import {
  convertNumberToPennies,
  filterObject,
  scrollToTop,
  today,
} from "./utils";
import { InviteLandingForm } from "./components/InviteLandingForm/InviteLandingForm";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { InviteUnderwritingForm } from "./components/InviteUnderwritingForm/InviteUnderwritingForm";

const initialValues: SchemaType = {
  firstName: "",
  lastName: "",
  homeEmail: "",
  workEmail: "",
  contractTerm: ContractTerm.Indefinite,
  jobTitle: "",
  workCountry: "",
  payBasis: PayBasis.Salary,
  sendInvitationTo: EmailTypes.Work,
  startDate: "",
  honoredStartDate: "",
  payRate: "",
  managerUuid: "",
  departmentUuid: "",
  roleResponsibilities: "",
};

export default function InvitePage() {
  const { t } = useTranslation();
  const [inviteEorMutation, { loading }] = useInviteEorMutation();

  const { getFeatureFlag } = useGetFeatureFlag();
  const underwritingEnabled = !!getFeatureFlag(
    "release-automated-eor-country-underwriting"
  );

  const [alert, setAlert] = useState<AlertState>();
  const [view, setView] = useState<InviteView>(
    underwritingEnabled
      ? { mode: "landing" }
      : { mode: "invite", hiringGeography: { countryCode: "" } }
  );
  const { supportedCountriesData } = useContext(SupportedCountriesContext);

  const handleUnderwritingFormSubmit = () => {
    if (view.mode !== "underwriting") {
      throw new Error("Should only submit underwriting from underwriting");
    }

    setView({ mode: "invite", hiringGeography: view.hiringGeography });
  };

  const handleUnderwritingFormCancel = () => {
    // The underwriting form is longer than the landing page, so when we go back to the landing page
    // we scroll to the top
    window.scrollTo({ top: 0, behavior: "smooth" });
    setView({ mode: "landing" });
  };

  const handleFormSubmit: InviteFormProps["onFormSubmit"] = (
    { sendInvitationTo, ...formValues },
    formActions
  ) => {
    const sendInviteTo =
      sendInvitationTo === EmailTypes.Home
        ? formValues.homeEmail ?? formValues.workEmail
        : formValues.workEmail;

    const currency = () => {
      const currencies =
        supportedCountriesData[formValues.workCountry]?.currencies;

      return currencies != null ? currencies[0].currencyCode : "";
    };

    const buildCountryDTO = () => {
      const workCountry = formValues.workCountry;
      const countryConfig = isCountryExtendedConfig(workCountry)
        ? COUNTRY_EXTENDED_CONFIG[workCountry]
        : undefined;

      if (countryConfig?.initialValues) {
        const keysToExclude = countryConfig.keysToExclude;
        const filteredEntries = Object.entries(formValues).filter(
          ([key]) => key in countryConfig.initialValues
        );
        const countryValues = Object.fromEntries(filteredEntries);

        return keysToExclude
          ? filterObject(countryValues, keysToExclude)
          : countryValues;
      }
    };

    const filteredBaseEntries = Object.entries(formValues).filter(
      ([key]) => key in initialValues
    );

    const baseValues = Object.fromEntries(
      filteredBaseEntries
    ) as typeof initialValues;

    const handleOnCompleted = () => {
      formActions.resetForm();
      setAlert({
        visible: true,
        message: t(
          "Thanks! Our team will review your request to add {{employeeName}} and get back to you with any next steps.",
          {
            employeeName: `${formValues.firstName} ${formValues.lastName}`,
          }
        ),
        type: "additive",
      });
      scrollToTop();
    };

    const handleOnError = () => {
      setAlert({
        visible: true,
        message: t("An error occurred while sending the invitation"),
        type: "destructive",
      });
      scrollToTop();
    };

    if (baseValues.honoredStartDate?.length === 0) {
      baseValues.honoredStartDate = undefined;
    }

    inviteEorMutation({
      variables: {
        formDetails: {
          ...baseValues,
          currency: currency(),
          payRate: convertNumberToPennies(formValues.payRate),
          sendInviteAt: today,
          sendInviteTo,
          ...buildCountryDTO(),
        },
      },
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    });
  };

  const handleFormError = (message: string) => {
    setAlert({
      type: "destructive",
      message,
      visible: true,
    });
  };

  return (
    <div className={styles.pageContainer}>
      {alert && (
        <Alert
          className={styles.alert}
          color={alert.type}
          dismissible
          visible={alert.visible}
        >
          {alert.message}
        </Alert>
      )}

      <PageHeader
        title={t("Add person")}
        linkPrevious={
          <Link to="/invitation-center">{t("Back to Invitation Center")}</Link>
        }
      />
      {view.mode === "landing" && <InviteLandingForm onSubmit={setView} />}
      {view.mode === "underwriting" && (
        <InviteUnderwritingForm
          hiringGeography={view.hiringGeography}
          onError={handleFormError}
          onSubmit={handleUnderwritingFormSubmit}
          onCancel={handleUnderwritingFormCancel}
        />
      )}
      {view.mode === "invite" && (
        <Form
          onFormSubmit={handleFormSubmit}
          isLoading={loading}
          initialValues={{
            ...initialValues,
            workCountry: view.hiringGeography
              .countryCode as SchemaType["workCountry"],
          }}
        />
      )}
    </div>
  );
}
