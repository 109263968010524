import {
  TextInput,
  RadioButtonGroup,
  RadioButton,
  SingleDatePicker,
  Select,
  SelectOption,
  CheckboxInput,
  MultiSelect,
  MultiSelectItem,
} from "@justworkshr/milo-form";
import { getIn, useFormikContext } from "formik";
import { FieldDataType, FieldTypes } from "./types";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";
import TypedInternationalPhoneNumberInput from "../TypedInternationalPhoneNumberInput/TypedInternationalPhoneNumberInput";
import {
  addYears,
  formatToISODate,
  formatToISODateTime,
} from "pages/employer-of-record/utils";
import { SensitiveTextInput } from "pages/employer-of-record/onboarding/profile-info/components/SensitiveTextInput/SensitiveTextInput";
import MyChildrenInformationInput from "pages/employer-of-record/components/MyChildrenInformationInput/MyChildrenInformationInput";
import { FileUpload } from "./components/FileUpload";

type Props = FieldDataType;

const FAR_IN_THE_FUTURE = addYears(new Date(), 100);

export default function AutoFormInput(props: Props) {
  const { type, name, placeholder, validations, format, compact } = props;

  const { t } = useTranslation();
  const {
    values,
    setFieldValue,
    setFieldTouched,
    handleChange: defaultHandleChange,
    handleBlur,
  } = useFormikContext<Record<string, unknown>>();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    event.target.value = formatInput(event.target.value);
    if (type === FieldTypes.boolean) {
      setFieldValue(event.target.name, event.target.value === "true");
    } else {
      defaultHandleChange(event);
    }
  }

  function handleDateChange(date: Date | null) {
    setFieldValue(name, date ? formatToISODate(date) : "");
  }

  function handleDateBlur() {
    setFieldTouched(name);
  }

  function handleMultiSelectChange(selectedItems: MultiSelectItem[]) {
    const selectedValues = selectedItems.map((item) => item.value as string);
    setFieldValue(props.name, selectedValues);
  }

  function handleSensitiveTextChange(newValue: string) {
    setFieldValue(props.name, newValue);
  }

  function formatInput(value: string) {
    return format && compact ? format(compact(value)) : value;
  }

  switch (type) {
    case "text":
    case "number":
      return (
        <TextInput
          id={name}
          name={name}
          type={type}
          onChange={handleChange}
          onBlur={handleBlur}
          value={formatInput(getIn(values, name))}
          minLength={validations?.text?.min?.value}
          maxLength={
            validations?.text?.max?.value || validations?.text?.length?.value
          }
          placeholder={placeholder}
        />
      );
    case "file":
      return <FileUpload name={name} />;
    case "sensitiveText":
      return (
        <SensitiveTextInput
          id={name}
          name={name}
          onChange={handleSensitiveTextChange}
          onBlur={handleBlur}
          value={getIn(values, name)}
          format={props.format}
          compact={props.compact}
          validations={props.validations}
        />
      );
    case "boolean":
      return (
        <RadioButtonGroup
          id={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={String(getIn(values, name))}
        >
          <RadioButton value="true" label={t("Yes")} />
          <RadioButton value="false" label={t("No")} />
        </RadioButtonGroup>
      );
    case "date":
      return (
        <SingleDatePicker
          id={name}
          name={name}
          onBlur={handleDateBlur}
          onChange={handleDateChange}
          value={formatToISODateTime(getIn(values, name))}
          minDate={validations?.date?.min?.value}
          maxDate={validations?.date?.max?.value ?? FAR_IN_THE_FUTURE}
        />
      );
    case "select":
      const { options: selectOptions } =
        props as FieldDataType<FieldTypes.select>;

      return (
        <Select
          id={name}
          name={name}
          onChange={defaultHandleChange}
          onBlur={handleBlur}
          defaultValue={getIn(values, name)}
          placeholder={getIn(values, name)?.length ? undefined : placeholder}
        >
          {Object.values(selectOptions).map((option) => (
            <SelectOption value={option.value} key={option.value}>
              {option.description}
            </SelectOption>
          ))}
        </Select>
      );
    case "group":
      const { options: groupOptions } =
        props as FieldDataType<FieldTypes.group>;

      return (
        <RadioButtonGroup
          id={name}
          name={name}
          onChange={defaultHandleChange}
          onBlur={handleBlur}
          value={getIn(values, name)}
          defaultChecked
        >
          {Object.values(groupOptions).map((option) => (
            <RadioButton
              value={String(option.value)}
              label={option.description}
              key={option.value}
            />
          ))}
        </RadioButtonGroup>
      );
    case "phoneNumber":
      const { withoutPhoneNumberType } =
        props as FieldDataType<FieldTypes.phoneNumber>;

      return (
        <TypedInternationalPhoneNumberInput
          name={name}
          withoutPhoneNumberType={withoutPhoneNumberType}
          onChange={defaultHandleChange}
          onBlur={handleBlur}
          value={getIn(values, name) ?? { type: "", value: "" }}
        />
      );
    case "checkbox":
      const { description } = props as FieldDataType<FieldTypes.checkbox>;
      return (
        <CheckboxInput
          name="sameAddress"
          label={description}
          onChange={defaultHandleChange}
          checked={getIn(values, name)}
          required={false}
          value={getIn(values, name) ? 1 : 0}
        />
      );
    case "multiSelect":
      const { options: multiSelectOptions } =
        props as FieldDataType<FieldTypes.multiSelect>;

      return (
        <MultiSelect
          values={getIn(values, name) ?? []}
          onChange={handleMultiSelectChange}
          onBlur={handleBlur}
        >
          {multiSelectOptions.map((option) => (
            <SelectOption value={option.value} key={option.value}>
              {option.description}
            </SelectOption>
          ))}
        </MultiSelect>
      );
    case "myChildrenInformation":
      return (
        <MyChildrenInformationInput
          name={name}
          value={getIn(values, name) ?? []}
          onChange={defaultHandleChange}
          onBlur={handleBlur}
        />
      );
    default:
      return null;
  }
}
