const pluralize = (count: number, noun: string, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

const minutesToHours = (minutes: number) => minutes / 60;
const minutesToDays = (minutes: number) => minutesToHours(minutes) / 8;

const format = (time: number, unit: "day" | "hour") =>
  `${time.toFixed(2)} ${pluralize(time, unit)}`;

const formatMinutesAsHours = (minutes: number) =>
  format(minutesToHours(minutes), "hour");

const formatMinutesAsDays = (
  minutes: number,
  condition = true,
  defaultValue = "Unlimited"
) => (condition ? format(minutesToDays(minutes), "day") : defaultValue);

const enOrdinalRules = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
const formatOrdinals = (number: number) => {
  const rule = enOrdinalRules.select(number);
  const suffix = suffixes.get(rule);
  return `${number}${suffix}`;
};

const formatDate = (date: string) => {
  return new Date(`${date}T00:00:00Z`).toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });
};

export {
  formatMinutesAsDays,
  formatMinutesAsHours,
  formatOrdinals,
  formatDate,
};
