import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { formatDate } from "../../utils";

export type PtoEvent = {
  effectiveDate: string;
  minutes: number;
  policyName: string;
  isAdjustment: boolean;
  responsibleAdminName?: string;
  status?: string;
  uuid: string;
};

const getDateDisplay = (startDate: string, endDate?: string) => {
  const formattedStartDate = formatDate(startDate);
  if (!endDate || startDate === endDate) {
    return formattedStartDate;
  }
  const formattedEndDate = formatDate(endDate);
  return `${formattedStartDate} - ${formattedEndDate}`;
};

const sortEventsByMostRecent = (
  firstEvent: PtoEvent,
  secondEvent: PtoEvent
): number => {
  const firstEventTimestamp = new Date(firstEvent.effectiveDate).getTime();
  const secondEventTimestamp = new Date(secondEvent.effectiveDate).getTime();
  return secondEventTimestamp - firstEventTimestamp;
};

export const usePtoEventHistory = () => {
  const { profileInfo } = useProfileContextData();

  const createCombinedPtoEventList = () => {
    if (!profileInfo?.eorEmployeeProfile.activePtoPolicies) return [];

    const eventList: PtoEvent[] = [];

    profileInfo.eorEmployeeProfile.activePtoPolicies.forEach((policy) => {
      policy.adjustments?.forEach((adjustment) => {
        eventList.push({
          effectiveDate: adjustment.effectiveDate
            ? getDateDisplay(adjustment.effectiveDate)
            : "No Date",
          minutes: adjustment.minutes ?? 0,
          policyName: policy.name ?? "Unknown Policy",
          isAdjustment: true,
          responsibleAdminName: `${adjustment.adjustmentByMember.firstName} ${adjustment.adjustmentByMember.lastName}`,
          uuid: adjustment.uuid ?? "",
        });
      });

      policy.requests?.forEach((request) => {
        eventList.push({
          effectiveDate: getDateDisplay(request.startDate, request.endDate),
          minutes: request.minutes,
          policyName: policy.name ?? "Unknown Policy",
          isAdjustment: false,
          status: request.status.toUpperCase(),
          uuid: request.uuid,
        });
      });
    });
    return eventList.sort(sortEventsByMostRecent);
  };

  return { createCombinedPtoEventList };
};
