export const AFP_AGENCY_OPTIONS = [
  { value: "afpCapital", description: "AFP Capital" },
  { value: "afpCuprum", description: "AFP Cuprum" },
  { value: "afpHabitat", description: "AFP Habitat" },
  { value: "afpModelo", description: "AFP Modelo" },
  { value: "afpPlanVital", description: "AFP PlanVital" },
  { value: "afpProvida", description: "AFP Provida" },
  { value: "afpUno", description: "AFP UNO" },
];

export const HEALTH_AGENCY_OPTIONS = [
  { value: "banmedica", description: "Banmédica S.A." },
  {
    value: "colmenaGoldenCross",
    description: "Colmena Golden Cross S.A.",
  },
  { value: "consalud", description: "Consalud S.A." },
  { value: "cruzBlanca", description: "Cruz Blanca S.A." },
  { value: "nuevaMasvida", description: "Nueva Masvida S.A." },
  { value: "vidaTres", description: "Vida Tres S.A." },
  { value: "isalud", description: "Isalud Ltda." },
  { value: "cruzDelNorte", description: "Cruz del Norte Ltda." },
  { value: "isapreFundacion", description: "Isapre Fundación Ltda." },
  { value: "esencial", description: "Esencial S.A." },
];

export const HEALTH_PLAN_OPTIONS = [
  { value: "isapre", description: "Isapre" },
  { value: "fonasa", description: "Fonasa (Public health system)" },
];
