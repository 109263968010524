import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { ChangeEventHandler, FocusEventHandler, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGetEorCountryUnderwritingSubmissionsQuery } from "types/generated/operations";

type Props = {
  value?: string;
  error?: string;
  handleBlur?: FocusEventHandler<HTMLSelectElement>;
  handleChange?: ChangeEventHandler<HTMLSelectElement>;
};

export default function WorkCountrySelectField(props: Props) {
  const { value, error, handleBlur, handleChange } = props;
  const { t } = useTranslation();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const { data } = useGetEorCountryUnderwritingSubmissionsQuery({
    fetchPolicy: "network-only",
  });
  const { getFeatureFlag } = useGetFeatureFlag();

  const underwritingEnabled = !!getFeatureFlag(
    "release-automated-eor-country-underwriting"
  );

  const allCountryOptions = Object.values(supportedCountriesData || []).map(
    (country) => ({
      value: country.code,
      description: country.commonName ?? country.code,
    })
  );

  const enabledCountryCodes = data?.underwritingSubmissions.map(
    ({ countryCode }) => countryCode
  );

  const enabledCountryOptions = underwritingEnabled
    ? allCountryOptions.filter(({ value }) =>
        enabledCountryCodes?.includes(value)
      )
    : allCountryOptions;

  return (
    <FormField
      name="workCountry"
      required
      label={t("Work country")}
      error={error}
    >
      <Select
        required
        name="workCountry"
        placeholder={t("Select country")}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      >
        {enabledCountryOptions.map(({ value, description }) => (
          <SelectOption value={value} key={value}>
            {description}
          </SelectOption>
        ))}
      </Select>
    </FormField>
  );
}
