import { format, parseISO } from "date-fns";

export const formatDatePickerDate = (value: string) => {
  const date = new Date(value);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const parseISO8601Date = (value: string) => {
  if (!value) return "";
  const date = parseISO(value);
  if (isNaN(date.getTime())) {
    return "";
  }
  return format(date, "MM/dd/yyyy");
};

export const formatDateToISO8601 = (value: string) => {
  const date = new Date(value);

  if (isNaN(date.getTime())) {
    return "";
  }
  return date.toISOString().split("T")[0];
};

// formatDatePickerDate function will return "Invalid Date" if
// the passed value is not formatted correctly
// using this function you can catch that output and set it to an empty string when needed
export const handleInvalidDate = (date: string) => {
  return date !== "Invalid Date" ? date : "";
};
