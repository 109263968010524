import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import { getDescription } from "pages/employer-of-record/utils";

import * as INDIA from "pages/employer-of-record/onboarding/profile-info/form/in/constants.in";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const indiaFields = {
  inPfUan: field({
    type: FieldTypes.text,
    informationType: "benefits",
    name: "inPfUan",
    label: "Provident Fund Universal Account Number (PF UAN)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("IN"),
      onboarding: countryIs("IN"),
    },
    value: personalInfo("inPfUan", ""),
  }),
  inEsic: field({
    type: FieldTypes.text,
    informationType: "benefits",
    name: "inEsic",
    label: "Employee’s State Insurance Corporation (ESIC) Number",
    scenarios: {
      viewProfile: countryIs("IN"),
      onboarding: countryIs("IN"),
    },
    value: personalInfo("inEsic", ""),
  }),
  inYearToDateTaxableSalary: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "inYearToDateTaxableSalary",
    label: "Year-to-date taxable salary (INR)",
    scenarios: {
      onboarding: countryIs("IN"),
    },
    value: () => "", // TODO: IEORN-3827 personalInfo("inYearToDateTaxableSalary", ""),
    serialize: (value) => (value === "" ? null : value),
  }),
  inYearToDateExemptions: field({
    type: FieldTypes.multiSelect,
    informationType: "identification",
    name: "inYearToDateExemptions",
    label: "Year-to-date exemptions (INR)",
    options: INDIA.TAX_OPTIONS,
    scenarios: {
      onboarding: countryIs("IN"),
    },
    value: () => [], // TODO: IEORN-3827 personalInfo("inYearToDateExemptions", ""),
    format: (value) => getDescription(INDIA.TAX_OPTIONS, value) ?? value,
  }),
  inYearToDateTds: field({
    type: FieldTypes.text,
    informationType: "benefits",
    name: "inYearToDateTds",
    label: "Year-to-date TDS (INR)",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("IN"),
    },
    value: () => "", // TODO: IEORN-3827 personalInfo("inYearToDateTds", ""),
  }),
  inTaxRegime: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "inTaxRegime",
    label: "Tax regime",
    placeholder: "Select Tax Regime",
    validations: REQUIRED,
    options: INDIA.TAX_REGIME,
    scenarios: {
      onboarding: countryIs("IN"),
    },
    value: () => "", // TODO: IEORN-3827 personalInfo("inTaxRegime", ""),
    format: (value) => getDescription(INDIA.TAX_OPTIONS, value) ?? value,
  }),
  inPan: identifierNumberFieldConfig("IN"),
  inConfirmPan: confirmIdentifierNumberFieldConfig("IN"),
};
