export const GENDER_OPTIONS = [
  {
    value: "male",
    description: "Male",
  },
  {
    value: "female",
    description: "Female",
  },
];

export const MARITAL_STATUS_OPTIONS = [
  {
    value: "married",
    description: "Casado(a) / Married",
  },
  {
    value: "cohabiting",
    description: "Conviviente / Cohabiting",
  },
  {
    value: "divorced",
    description: "Divorciado(a) / Divorced",
  },
  {
    value: "single",
    description: "Soltero(a) / Single",
  },
  {
    value: "widowed",
    description: "Viudo(a) / Widowed",
  },
];

export const PENSION_FUND_OPTIONS = [
  {
    value: "afp_habitat",
    description: "AFP Habitat",
  },
  {
    value: "afp_horizonte",
    description: "AFP Horizonte",
  },
  {
    value: "afp_integra",
    description: "AFP Integra",
  },
  {
    value: "snp",
    description: "SNP / National Pension System",
  },
  {
    value: "afp_prima",
    description: "AFP Prima",
  },
  {
    value: "afp_profuturo",
    description: "AFP Profuturo",
  },
  {
    value: "no_pension",
    description: "Sin Régimen Pensionario / No Pension System",
  },
];

export const ID_TYPE_OPTIONS = [
  {
    value: "foreigner_id",
    description: "Carné de Extranjería / Foreigner's ID Card",
  },
  {
    value: "dni",
    description: "DNI / National Identity Document",
  },
  {
    value: "passport",
    description: "Pasaporte / Passport",
  },
  {
    value: "ptp",
    description: "PTP / Temporary Permit of Permanence",
  },
];
