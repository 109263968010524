import * as Yup from "yup";
import CAFragment from "./CAFragment";

export type ConfigType = {
  component: JSX.Element;
  schema: typeof schema;
  initialValues: SchemaType;
  keysToExclude?: Partial<keyof SchemaType>[];
};

export type SchemaType = Yup.InferType<typeof schema>;

const schema = Yup.object({
  workCountry: Yup.string().oneOf(["CA"]).required("Required"),
  caProbationPeriodDays: Yup.string()
    .required()
    .oneOf(["0", "90"], "Must be none or 90 days."),
  caPtoAllowanceType: Yup.string()
    .oneOf(["statutory", "unlimited", "custom"], "Invalid selection")
    .required("Required"),
  caPtoAllowanceDays: Yup.number()
    .nullable()
    .when("caPtoAllowanceType", (caPtoAllowanceType, schema) => {
      return String(caPtoAllowanceType) === "custom"
        ? schema
            .nonNullable()
            .required("Required")
            .min(10, "Must be 10 or greater.")
        : schema;
    }),
});

const CONFIG: ConfigType = {
  component: <CAFragment />,
  schema: schema,
  initialValues: {
    workCountry: "CA",
    caPtoAllowanceType: "statutory",
    caPtoAllowanceDays: null,
    caProbationPeriodDays: "0",
  },
  keysToExclude: [],
};

export default CONFIG;
