import {
  FLAG_NAMES,
  VARIABLE_NAMES,
  flagIsEnabled,
  getEnvVariable,
} from "lib/environment";
import { useContext } from "react";
import { NeonSkyContext, NeonSkyFlagIds } from "lib/neon-sky/neon-sky-provider";
import { useGetCompanyInfoQuery } from "types/generated/operations";

let graphqlHost = flagIsEnabled(FLAG_NAMES.ENABLE_MESH)
  ? getEnvVariable(VARIABLE_NAMES.MESH_HOST)
  : import.meta.env.VITE_APP_GRAPHQL_HOST;

let clockworkwebHost = import.meta.env.VITE_APP_CLOCKWORK_WEB_HOST;
let staticAssetHost = import.meta.env.VITE_APP_CLOCKWORK_STATIC_ASSET_HOST;
const isPureLocal = import.meta.env.VITE_APP_MSW_ENABLED === "true";
const isProduction = window.location.hostname === "secure.justworks.com";
const isStagingReadOnly =
  window.location.hostname === "staging-read-only.justworks.com";
const isSandboxReadOnly =
  window.location.hostname === "sandbox-read-only.justworks.com";

function getHostFromPageHost(): string {
  const { port, protocol, hostname } = window.location;
  const portToUse = port ? `:${port}` : "";
  return `${protocol}//${hostname}${portToUse}`;
}

function buildPath(base: string, path: string): string {
  const normalizeSlashPath = path.replace("//", "/");
  const cleanPath = normalizeSlashPath.startsWith("/")
    ? normalizeSlashPath
    : `/${normalizeSlashPath}`;
  return `${base}${cleanPath}`;
}

function buildGraphQLPath(path: string): string {
  if (!graphqlHost || isPureLocal) {
    graphqlHost = getHostFromPageHost();
  }
  return buildPath(graphqlHost, path);
}

function buildWebPath(path: string): string {
  return path.includes("/cf/") ? path : buildClockworkWebPath(path);
}

function buildClockworkWebPath(path: string): string {
  if (!clockworkwebHost || isPureLocal) {
    clockworkwebHost = getHostFromPageHost();
  }
  return buildPath(clockworkwebHost, path);
}

function buildStaticAssetPath(path: string): string {
  if (!staticAssetHost || isPureLocal) {
    staticAssetHost = getHostFromPageHost();
  }
  return buildPath(staticAssetHost, `/${path}`);
}

function buildCsToolsPath(): string {
  if (isProduction) {
    return "https://cstools.justworks.com/internal";
  } else if (isStagingReadOnly) {
    return "https://staging.justworks.com/internal";
  } else if (isSandboxReadOnly) {
    return "https://sandbox.justworks.com/internal";
  } else {
    return buildWebPath("/internal");
  }
}

function useNeonPath(path: string): string {
  const { featureFlags } = useContext(NeonSkyContext);
  const companyInfo = useGetCompanyInfoQuery();

  const { data: companyInfoData, error: companyInfoError } = companyInfo;

  if (companyInfoError) {
    return buildClockworkWebPath(path);
  }

  const NEON_PATH_MAPPING: Record<string, NeonSkyFlagIds> = {
    "/members/employees": "neonmoose_global_manage_employees",
    "/members/contractors": "neonmoose_global_manage_employees",
    "/firms": "neonmoose_global_manage_employees",
    "/firms/invitation/new": "neonmoose_global_manage_employees",
    "/company": "neonmoose_global_company_settings",
    // Add other path mappings here
  };

  const clockfaceOriginalPaths = ["/firms", "/firms/invitation/new"];

  const flagId = NEON_PATH_MAPPING[path];

  if (flagId && featureFlags[flagId]) {
    const cdmsCanonicalCompanyId =
      companyInfoData?.authenticatedMember?.company?.cdmsCanonicalCompanyId;
    if (path === "/members/employees") {
      if (cdmsCanonicalCompanyId) {
        return isProduction
          ? `https://neon.justworks.com/manage/${cdmsCanonicalCompanyId}/employees`
          : `https://neon.dev.justworks.com/manage/${cdmsCanonicalCompanyId}/employees`;
      }
    }
    if (path === "/members/contractors") {
      if (cdmsCanonicalCompanyId) {
        return isProduction
          ? `https://neon.justworks.com/manage/${cdmsCanonicalCompanyId}/contractors`
          : `https://neon.dev.justworks.com/manage/${cdmsCanonicalCompanyId}/contractors`;
      }
    }
    if (path === "/firms") {
      if (cdmsCanonicalCompanyId) {
        return isProduction
          ? `https://neon.justworks.com/manage/${cdmsCanonicalCompanyId}/third-party-admins`
          : `https://neon.dev.justworks.com/manage/${cdmsCanonicalCompanyId}/third-party-admins`;
      }
    }
    if (path === "/firms/invitation/new") {
      if (cdmsCanonicalCompanyId) {
        return isProduction
          ? `https://neon.justworks.com/manage/${cdmsCanonicalCompanyId}/invite-firm`
          : `https://neon.dev.justworks.com/manage/${cdmsCanonicalCompanyId}/invite-firm`;
      }
    }
    if (path === "/company") {
      if (cdmsCanonicalCompanyId) {
        return isProduction
          ? `https://neon.justworks.com/company/${cdmsCanonicalCompanyId}/settings`
          : `https://neon.dev.justworks.com/company/${cdmsCanonicalCompanyId}/settings`;
      }
    }
  }

  if (clockfaceOriginalPaths.includes(path)) {
    return path;
  }

  return buildClockworkWebPath(path);
}

export {
  buildWebPath,
  buildCsToolsPath,
  buildGraphQLPath,
  buildStaticAssetPath,
  buildClockworkWebPath,
  useNeonPath,
};
