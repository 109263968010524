import { EorEmployeeProfile } from "types/employer-of-record";
import { Photo } from "types/generated/operations";
import * as Yup from "yup";

type ValuedValidation = {
  value: number;
  message: string;
};

type BooleanValidation = {
  enabled: boolean;
  message: string;
};

type DateValidation = {
  value: Date;
  message: string;
};

type RegexValidation = {
  value: RegExp;
  message: string;
};

type FunctionValidation<T> = {
  value: (
    value: T | undefined,
    context: Yup.TestContext<Yup.AnyObject>
  ) => boolean;
  message: string;
};

export type WhenValidation = Record<
  BaseFieldDataType["name"],
  (value: unknown) => boolean
>;

// TODO: Do not add custom validations here
export type ValidationType = {
  number?: {
    max?: ValuedValidation;
    min?: ValuedValidation;
  };
  text?: {
    bankCode?: BooleanValidation;
    bsbCode?: BooleanValidation;
    clabe?: BooleanValidation;
    clearingNumber?: BooleanValidation;
    ctsAccountNumber?: BooleanValidation;
    ctsFinancialEntity?: BooleanValidation;
    email?: BooleanValidation;
    iban?: BooleanValidation;
    ifsCode?: BooleanValidation;
    length?: ValuedValidation;
    number?: BooleanValidation;
    phoneNumber?: BooleanValidation;
    registrationNumber?: BooleanValidation;
    routingNumber?: BooleanValidation;
    sortCode?: BooleanValidation;
    swiftCode?: BooleanValidation;
    max?: ValuedValidation;
    min?: ValuedValidation;
    bankName?: BooleanValidation;
    matches?: RegexValidation;
    fn?: FunctionValidation<string>;
  };
  date?: {
    min?: DateValidation;
    max?: DateValidation;
  };
  array?: {
    min?: ValuedValidation;
  };
  file?: {
    maxSize?: ValuedValidation;
    allowedTypes?: {
      value: string[];
      message: string;
    };
  };
  required: BooleanValidation | { when: WhenValidation };
};

export enum FieldTypes {
  boolean = "boolean",
  checkbox = "checkbox",
  text = "text",
  sensitiveText = "sensitiveText",
  number = "number",
  date = "date",
  select = "select",
  group = "group",
  address = "address",
  phoneNumber = "phoneNumber",
  myChildrenInformation = "myChildrenInformation",
  multiSelect = "multiSelect",
  file = "file",
}

export type ArrayConfig = {
  limit: number;
  deletionStrategy?: "last" | "any";
};

export interface BaseFieldDataType {
  array?: ArrayConfig;
  informationType?: "address" | "contact" | "job";
  name: string;
  label?: string | null;
  message?: string;
  placeholder?: string;
  validations?: ValidationType;
  hideWhen?: Record<BaseFieldDataType["name"], (value: unknown) => boolean>;
  format?: (value: string) => string;
  compact?: (value: string) => string;
}

export type PhoneNumberFieldType = FieldDataType<FieldTypes.phoneNumber>;
export type RadioGroupFieldType = FieldData<FieldTypes.group>;
export type MultiSelectFieldType = FieldData<FieldTypes.multiSelect>;

export type InternationalAddress = NonNullable<
  NonNullable<EorEmployeeProfile["contactDetails"]>["homeAddress"]
>;

export type InternationalPhoneNumber = NonNullable<
  NonNullable<EorEmployeeProfile["contactDetails"]>["homePhoneNumber"]
>;

export const INFORMATION_TYPES = [
  "address",
  "benefits",
  "cartao",
  "contact",
  "contract",
  "education",
  "family",
  "job",
  "identification",
  "identifierNumber",
  "pension",
  "rg",
] as const;

export type InformationType = (typeof INFORMATION_TYPES)[number];

export type BaseFieldType<
  FieldType extends FieldTypes,
  FieldArrayConfig extends ArrayConfig | undefined
> = {
  type: FieldType;
  informationType?: InformationType;
  name: string;
  label?: string | null;
  message?: string;
  placeholder?: string;
  validations?: ValidationType;
  hideWhen?: WhenValidation;
  format?: (value: string) => string;
  compact?: (value: string) => string;
} & (FieldArrayConfig extends undefined
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : { array: ArrayConfig });

type Options = {
  options: readonly { value: string | number; description: string }[];
};

type ExtraFieldsByFieldType = {
  [FieldTypes.select]: Options;
  [FieldTypes.multiSelect]: Options;
  [FieldTypes.group]: Options;
  [FieldTypes.phoneNumber]: { withoutPhoneNumberType?: boolean };
  [FieldTypes.checkbox]: { description: string };
  [FieldTypes.address]: { supportedCountries?: string[] };
};

type DataTypeByFieldType = {
  [FieldTypes.address]: InternationalAddress;
  [FieldTypes.boolean]: boolean;
  [FieldTypes.checkbox]: boolean;
  [FieldTypes.date]: string;
  [FieldTypes.group]: string;
  [FieldTypes.number]: string;
  [FieldTypes.phoneNumber]: InternationalPhoneNumber;
  [FieldTypes.select]: string;
  [FieldTypes.sensitiveText]: string;
  [FieldTypes.text]: string;
  [FieldTypes.multiSelect]: string[];
  [FieldTypes.myChildrenInformation]: object;
  [FieldTypes.file]: Photo;
};

type TypeSpecificFields<FieldType extends FieldTypes> =
  FieldType extends keyof ExtraFieldsByFieldType
    ? ExtraFieldsByFieldType[FieldType]
    : // eslint-disable-next-line @typescript-eslint/ban-types
      {};

export type DataType<
  FieldType extends FieldTypes = FieldTypes,
  FieldArrayConfig extends ArrayConfig | undefined = ArrayConfig | undefined
> = FieldArrayConfig extends undefined
  ? DataTypeByFieldType[FieldType]
  : Array<DataTypeByFieldType[FieldType]>;

export type FieldDataType<
  FieldType extends FieldTypes = FieldTypes,
  FieldArrayConfig extends ArrayConfig | undefined = ArrayConfig | undefined
> = BaseFieldType<FieldType, FieldArrayConfig> & TypeSpecificFields<FieldType>;

export type SelectField = FieldDataType<
  FieldTypes.select | FieldTypes.group,
  undefined
>;

export type ArrayFieldDataType<FieldType extends FieldTypes = FieldTypes> =
  FieldDataType<FieldType, ArrayConfig>;

export type FieldData<FieldType extends FieldTypes = FieldTypes> =
  FieldDataType<FieldType> & {
    fields?: FieldDataType<FieldType>[] | null;
  };

export type FormType = {
  header: string;
  subheader?: string;
  fields: FieldData[];
  initialValues: {
    [key: string]: string | boolean | { [key: string]: string | boolean };
  };
};
