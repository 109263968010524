import { ValidationError } from "stdnum/src/exceptions";
import { ValidateReturn } from "stdnum/lib/cjs/types";
import { strings } from "stdnum/src/util";

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  return strings.cleanUnicode(input, " -.");
}

const roTaxIdValidator = {
  name: "Romanian Personal Numeric Code",
  localName: "Personal Numeric Code",
  abbreviation: "PNC",
  compact(input: string): string {
    const [value, err] = clean(input);
    if (err) {
      throw err;
    }
    return value;
  },

  format(input: string): string {
    const [value] = clean(input);
    return value;
  },

  // Documentation for validation: https://cran.r-project.org/web/packages/rocnp/rocnp.pdf
  validate(input: string): ValidateReturn {
    const [value, error] = clean(input);

    if (error) {
      return { isValid: false, error };
    }
    if (value.length !== 13) {
      return {
        isValid: false,
        error: new ValidationError("PNC must be 13 digits"),
      };
    }
    if (!/^[0-9]+$/.test(value)) {
      return {
        isValid: false,
        error: new ValidationError("PNC must consist of only digits"),
      };
    }

    // First digit should be 1-9
    if (!/^[1-9]/.test(value)) {
      return {
        isValid: false,
        error: new ValidationError("PNC first digit must be 1-9"),
      };
    }

    // Month should be 01-12
    const month = parseInt(value.substring(3, 5));
    if (month < 1 || month > 12) {
      return {
        isValid: false,
        error: new ValidationError("Invalid month in PNC"),
      };
    }

    // Day should be 01-31 (basic validation)
    const day = parseInt(value.substring(5, 7));
    if (day < 1 || day > 31) {
      return {
        isValid: false,
        error: new ValidationError("Invalid day in PNC"),
      };
    }

    return {
      isValid: true,
      compact: value,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { roTaxIdValidator };
