import { common } from "./countries/_common";
import { chileFields } from "./countries/clFields";
import { costaRicaFields } from "./countries/crFields";
import { irelandFields } from "./countries/ieFields";
import { mexicoFields } from "./countries/mxFields";
import { brazilFields } from "./countries/brFields";
import { portugalFields } from "./countries/ptFields";
import { spainFields } from "./countries/esFields";
import { colombiaFields } from "./countries/coFields";
import { indiaFields } from "./countries/inFields";
import { netherlandsFields } from "./countries/nlFields";
import { australiaFields } from "./countries/auFields";
import { philippinesFields } from "./countries/phFields";
import { singaporeFields } from "./countries/sgFields";
import { denmarkFields } from "./countries/dkFields";
import { swedenFields } from "./countries/seFields";
import { malaysiaFields } from "./countries/myFields";
import { canadaFields } from "./countries/caFields";
import { franceFields } from "./countries/frFields";
import { germanyFields } from "./countries/deFields";
import { greatBritainFields } from "./countries/gbFields";
import { italyFields } from "./countries/itFields";
import { greeceFields } from "./countries/grFields";
import { peruFields } from "./countries/peFields";
import { romaniaFields } from "./countries/roFields";

export const PROFILE_FIELDS = {
  ...common,
  ...irelandFields,
  ...italyFields,
  ...mexicoFields,
  ...brazilFields,
  ...portugalFields,
  ...spainFields,
  ...colombiaFields,
  ...indiaFields,
  ...netherlandsFields,
  ...australiaFields,
  ...philippinesFields,
  ...singaporeFields,
  ...denmarkFields,
  ...swedenFields,
  ...chileFields,
  ...malaysiaFields,
  ...canadaFields,
  ...franceFields,
  ...germanyFields,
  ...greatBritainFields,
  ...costaRicaFields,
  ...greeceFields,
  ...romaniaFields,
  ...peruFields,
};

export type ProfileFieldName = keyof typeof PROFILE_FIELDS;
export type ProfileFieldData = {
  [K in ProfileFieldName]: ReturnType<(typeof PROFILE_FIELDS)[K]["value"]>;
};
