import { Formik } from "formik";
import {
  AutoFormField,
  FieldDataType,
  FieldTypes,
  generateFormValidations,
} from "pages/employer-of-record/components/AutoForm";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useContext } from "react";
import styles from "./InviteLandingForm.module.css";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { Button } from "@justworkshr/milo-core";
import { Link } from "react-router-dom";
import { useGetEorCountryUnderwritingSubmissionsLazyQuery } from "types/generated/operations";
import { HiringGeography, InviteView } from "../../types";

type Props = {
  onSubmit(view: InviteView): void;
};

export function InviteLandingForm(props: Props) {
  const { t } = useTranslation();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const [getEorCountryUnderwritingSubmissions, { loading }] =
    useGetEorCountryUnderwritingSubmissionsLazyQuery();

  const countryOptions = Object.values(supportedCountriesData || []).map(
    (country) => ({
      value: country.code,
      description:
        country.commonName && country.emojiFlag
          ? `${country.emojiFlag} ${country.commonName}`
          : country.code,
    })
  );

  function subdivisionOptions(workCountry: string) {
    return (
      supportedCountriesData[workCountry]?.subdivisions?.map((sub) => ({
        value: sub.code,
        description: sub.name,
      })) ?? []
    );
  }

  async function onSubmit(values: { workCountry: string; zoneCode: string }) {
    const hiringGeography: HiringGeography = {
      countryCode: values.workCountry,
      zoneCode:
        values.zoneCode.length && !shouldHideRegionField(values.workCountry)
          ? values.zoneCode
          : undefined,
    };

    const queryResult = await getEorCountryUnderwritingSubmissions();
    const submissions = queryResult.data?.underwritingSubmissions;
    const alreadySubmittedUnderwriting = hiringGeography.zoneCode
      ? submissions?.some(
          (submission) =>
            submission.countryCode === values.workCountry &&
            submission.zoneCode === values.zoneCode
        )
      : submissions?.some(
          (submission) => submission.countryCode === values.workCountry
        );

    props.onSubmit({
      mode: alreadySubmittedUnderwriting ? "invite" : "underwriting",
      hiringGeography,
    });
  }

  function shouldHideRegionField(workCountry: unknown) {
    if (typeof workCountry !== "string") {
      return true;
    }

    const countryConfig = supportedCountriesData[workCountry];

    return (
      !countryConfig ||
      countryConfig.underwritingSubdivisionGroups?.length === 1
    );
  }

  const workCountryField: FieldDataType<FieldTypes.select, undefined> = {
    type: FieldTypes.select,
    name: "workCountry",
    label: "Country",
    placeholder: "Select country",
    options: countryOptions,
    validations: {
      required: {
        enabled: true,
        message: "Country is required",
      },
    },
  };

  const zoneCodeField: FieldDataType<FieldTypes.select, undefined> = {
    type: FieldTypes.select,
    name: "zoneCode",
    label: "Region",
    placeholder: "Select region",
    options: [],
    validations: {
      required: {
        enabled: true,
        message: "Region is required",
      },
    },
    hideWhen: {
      workCountry: shouldHideRegionField,
    },
  };

  return (
    <Formik
      initialValues={{ workCountry: "", zoneCode: "" }}
      validationSchema={generateFormValidations(
        [workCountryField, zoneCodeField],
        t
      )}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => {
        zoneCodeField.options = subdivisionOptions(values.workCountry);

        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles.fields}>
              {[workCountryField, zoneCodeField].map((fieldData) => (
                <AutoFormField {...fieldData} key={fieldData.name} />
              ))}
            </div>

            <ActionFooter
              actions={[
                <Button
                  key="cancel-btn"
                  variant="ghost"
                  as={Link}
                  to="/invitation-center"
                >
                  {t("Cancel")}
                </Button>,
                <Button key="submit-btn" type="submit" loading={loading}>
                  {t("Next")}
                </Button>,
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
