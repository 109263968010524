import {
  FieldDataType,
  InformationType,
  SelectField,
} from "../../components/AutoForm";
import { useDepartmentOptions } from "../../employee-profile/components/EditableProfile/hooks/useDepartmentOptions";
import { useManagerOptions } from "../../employee-profile/components/EditableProfile/hooks/useManagerOptions";
import { PROFILE_FIELDS, ProfileFieldName } from "../profileFields";
import { Scenario } from "../types";
import { useInScopeMemberData } from "./useInScopeMemberData";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useContext } from "react";

type UseFieldsOptions = {
  informationType?: InformationType | InformationType[];
  overrides?: { [K in ProfileFieldName]?: Partial<FieldDataType> };
  scenario: Scenario;
};

export function useFields(options: UseFieldsOptions) {
  const departments = useDepartmentOptions();
  const managers = useManagerOptions();
  const memberDataScope = useInScopeMemberData();
  const fields = merge(cloneDeep(PROFILE_FIELDS), options.overrides);
  const { getSupportedProvinces } = useContext(SupportedCountriesContext);

  const frRegions = getSupportedProvinces("FR").map((region) => ({
    value: region.code,
    description: region.name,
  }));

  if (!memberDataScope) {
    return [];
  }
  const { eorEmployeeProfile, authenticatedMember } = memberDataScope;
  const isTargetInformationType = (informationType?: InformationType) => {
    // Ignore any fields that have not specified an informationType
    if (!informationType) return false;
    // If no information types have been provided, match all.
    if (!options.informationType) return true;
    if (Array.isArray(options.informationType)) {
      return options.informationType.includes(informationType);
    }

    return informationType === options.informationType;
  };
  const informationTypeFields = Object.values(fields).filter(
    ({ informationType }) => isTargetInformationType(informationType)
  );

  const managerField = informationTypeFields.find(
    ({ name }) => name === fields.managerUuid.name
  );
  if (managerField) {
    (managerField as SelectField).options = managers;
  }

  const departmentField = informationTypeFields.find(
    ({ name }) => name === fields.departmentUuid.name
  );
  if (departmentField) {
    (departmentField as SelectField).options = departments;
  }

  const frBirthProvince = informationTypeFields.find(
    ({ name }) => name === fields.frBirthProvince.name
  );
  if (frBirthProvince) {
    (frBirthProvince as SelectField).options = frRegions;
  }

  return informationTypeFields.filter((field) =>
    field.scenarios[options.scenario]?.(eorEmployeeProfile, authenticatedMember)
  );
}
