import { Card } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import Row from "../../Row/Row";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import {
  GetEoREmployeeProfileQuery,
  useGetAdminCapabilitiesQuery,
} from "types/generated/operations";
import {
  convertFromCents,
  formatDate,
} from "pages/employer-of-record/employee-profile/utils";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useContext } from "react";
import RoleFragment from "../../EditCompensation/RoleFragment";
import styles from "./ContractDetails.module.css";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { useParams, useNavigate } from "react-router-dom";
import CardAction from "../../CardAction/CardAction";
import ProfileInfoRow from "../../PersonalInfo/ProfileInfoRow";
import { useFields } from "pages/employer-of-record/configs";

const CONTRACT_DETAILS_LABELS: Record<string, string> = {
  startDate: "Start Date",
  contractTerm: "Contract term",
  title: "Title",
  office: "Office",
  workCountry: "Work country",
  pay: "Pay",
};

const HIDDEN_VALUES = ["pay"];

function editableContractDetails(
  {
    employment,
    contract,
    role,
    jobInformation,
  }: NonNullable<GetEoREmployeeProfileQuery["eorEmployeeProfile"]>,
  supportedCountriesContext: React.ContextType<typeof SupportedCountriesContext>
): Record<string, RowProps | undefined> {
  const countryCode = employment?.workCountry;
  const startDate = contract?.startDate;
  const formattedStartDate = formatDate(startDate);
  const pay = convertFromCents(role?.payRate);
  const formattedPay = `${
    supportedCountriesContext.getCurrency(countryCode).symbol
  }${pay?.toLocaleString()} ${role?.currency?.toUpperCase()} ${
    role?.payBasis === "salary" ? "per year" : "per hour"
  }`;

  return {
    startDate: { value: formattedStartDate },
    contractTerm: { value: contract?.term || "" },
    title: role?.title ? { value: role.title } : undefined,
    office: { value: jobInformation?.office || "" },
    workCountry: {
      value: supportedCountriesContext.getCountryName(countryCode),
    },
    pay: { value: formattedPay, subTag: `Paid ${role?.payFrequency}` },
  };
}

type RowProps = { value: string; subTag?: string };

function EditCompensationAction({ isAdmin }: { isAdmin?: boolean }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  function navigateToEditPage() {
    navigate(`/employer-of-record/${EOR_PROFILE_PATH}/${id}/compensation`);
  }
  function navigateToContractDetailsEorEmployee() {
    navigate("/account-settings/basic-information/edit-profile#contract");
  }

  if (isAdmin) {
    return (
      <CardAction name={t("Edit")} icon="edit" onClick={navigateToEditPage} />
    );
  } else {
    return (
      <CardAction
        name={t("Edit")}
        icon="edit"
        onClick={navigateToContractDetailsEorEmployee}
      />
    );
  }
}

export default function ContractDetails({
  editInPlace,
}: {
  editInPlace?: boolean;
}) {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const { data: capabilities } = useGetAdminCapabilitiesQuery();
  const supportedCountriesContext = useContext(SupportedCountriesContext);
  const fields = useFields({
    scenario: "viewProfile",
    informationType: "contract",
  });

  const hideFields = editInPlace ? ["pay", "title"] : [];

  const employeeProfile = profileInfo?.eorEmployeeProfile;

  if (!employeeProfile) throw new Error("Profile should never be undefined");

  const isAdmin =
    capabilities?.authenticatedMember.capabilities.hasAccessToAdminProfileView;

  const contractDetailsRows = Object.entries(
    editableContractDetails(employeeProfile, supportedCountriesContext)
  )
    .filter(([key]) => (hideFields.length ? !hideFields.includes(key) : true))
    .flatMap(([key, value]) => {
      return [
        <Row
          key={key}
          label={CONTRACT_DETAILS_LABELS[key]}
          value={value?.value}
          showHideButton={HIDDEN_VALUES.includes(key)}
          subtag={value?.subTag}
          capitalizeValue={!HIDDEN_VALUES.includes(key)}
        />,
        key === "workCountry" && editInPlace ? (
          <div className={styles.editableFragmentWrapper}>
            <RoleFragment />
          </div>
        ) : undefined,
      ];
    });

  const countrySpecificRows = fields.map((field) => (
    <ProfileInfoRow {...field} />
  ));

  // Only show these fields to admins.
  const staticRows = isAdmin ? (
    <>
      <Row label="Exemption status" value="Exempt" />
      <Row label="Justworks service" value="Employer of Record (EOR)" />
    </>
  ) : null;

  return (
    <Card
      title={t("Contract Details")}
      actions={
        editInPlace ? undefined : [<EditCompensationAction isAdmin={isAdmin} />]
      }
    >
      {contractDetailsRows}
      {countrySpecificRows}
      {staticRows}
    </Card>
  );
}
