import { FieldName } from "@shopify/address";

// If we need to collect specific address information that is not generated by the @shopify/address package,
// we will have to define the fields explicitly
export function getCustomOrderedFields(
  countryCode: string | null | undefined
): FieldName[][] {
  if (countryCode == null) return [];
  const customFields: Record<string, FieldName[][]> = {
    DE: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.PostalCode, FieldName.City, FieldName.Zone],
      [FieldName.Phone],
    ],
    DK: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.PostalCode, FieldName.City, FieldName.Zone],
      [FieldName.Phone],
    ],
    FR: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.PostalCode, FieldName.City, FieldName.Zone],
      [FieldName.Phone],
    ],
    GB: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.City, FieldName.Zone, FieldName.PostalCode],
      [FieldName.Phone],
    ],
    GR: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.City, FieldName.Zone, FieldName.PostalCode],
      [FieldName.Phone],
    ],
    IL: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.City, FieldName.Zone, FieldName.PostalCode],
      [FieldName.Phone],
    ],
    NL: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.PostalCode, FieldName.City, FieldName.Zone],
      [FieldName.Phone],
    ],
    SE: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.PostalCode, FieldName.City, FieldName.Zone],
      [FieldName.Phone],
    ],
    SG: [
      [FieldName.Country],
      [FieldName.FirstName, FieldName.LastName],
      [FieldName.Company],
      [FieldName.Address1],
      [FieldName.Address2],
      [FieldName.PostalCode, FieldName.City, FieldName.Zone],
      [FieldName.Phone],
    ],
  };
  return customFields[countryCode];
}
