import * as Yup from "yup";
import GBFragment from "./GBFragment";

export type ConfigType = {
  component: JSX.Element;
  schema: typeof schema;
  initialValues: SchemaType;
  keysToExclude?: Partial<keyof SchemaType>[];
};

export type SchemaType = Yup.InferType<typeof schema>;

const schema = Yup.object({
  workCountry: Yup.string().oneOf(["GB"]).required("Required"),
  gbPtoAllowanceDays: Yup.number()
    .nonNullable()
    .required("Required")
    .min(28, "Must be 28 or greater."),
  gbProbationPeriod: Yup.boolean().required("Required"),
});

const CONFIG: ConfigType = {
  component: <GBFragment />,
  schema: schema,
  initialValues: {
    workCountry: "GB",
    gbPtoAllowanceDays: 28,
    gbProbationPeriod: false,
  },
  keysToExclude: [],
};

export default CONFIG;
