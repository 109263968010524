import { Dispatch, SetStateAction, useEffect } from "react";
import { loadCountryConfigFiles } from "../countryConfigLoader";
import { addDisbursementDate } from "pages/employer-of-record/payments/utils";
import { AdditionalPayContextProps } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig } from "../../SetupFormGenerator";
import {
  BenefitsAndAllowancesPayloadType,
  CountryConfigKeyType,
} from "../types";
import { useFormikContext } from "formik";
import { AdditionalPayFormData } from "../../../types";
import { MessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";

const useCountryConfigFetcher = (
  additionalPayContext: AdditionalPayContextProps | null,
  setPaymentSettingsFields: Dispatch<SetStateAction<FieldConfig[]>>,
  setTaxableBenefitsValues: Dispatch<SetStateAction<string[]>>,
  setLink: Dispatch<SetStateAction<string | undefined>>,
  setMessage: Dispatch<SetStateAction<MessageWithLinkType | null>>
) => {
  const selectedCountry = additionalPayContext?.selectedCountry;

  const { values } =
    useFormikContext<AdditionalPayFormData<BenefitsAndAllowancesPayloadType>>();

  useEffect(() => {
    if (selectedCountry) {
      const fetchCountryConfigs = () => {
        const configs = loadCountryConfigFiles(
          selectedCountry as CountryConfigKeyType
        );
        let paymentSettingsFormFields = configs.PAYMENT_SETTINGS_FIELDS;
        if (additionalPayContext?.disbursementDateOptions) {
          paymentSettingsFormFields = addDisbursementDate(
            paymentSettingsFormFields,
            Object.values(additionalPayContext.disbursementDateOptions)
          );
        }
        setPaymentSettingsFields(paymentSettingsFormFields);
        setTaxableBenefitsValues(configs.TAXABLEBENFITSVALUES);

        "TAXABLEBENEFITSLINK" in configs
          ? setLink(configs.TAXABLEBENEFITSLINK)
          : setLink(undefined);

        const defaultMessage = configs.TaxableBenefitsTypeMessage?.[""] || null;

        const message =
          configs.TaxableBenefitsTypeMessage?.[
            values.setUp.payload.paymentSettings.benefitType || ""
          ] || defaultMessage;

        setMessage(message);
      };

      fetchCountryConfigs();
    }
  }, [
    selectedCountry,
    additionalPayContext?.disbursementDateOptions,
    setTaxableBenefitsValues,
    setPaymentSettingsFields,
    setLink,
    setMessage,
    values.setUp.payload.paymentSettings.benefitType,
  ]);
};

export default useCountryConfigFetcher;
