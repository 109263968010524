import { ADDITIONAL_PAY_FORM_ROUTES } from "pages/employer-of-record/payments/constants";
import { EmployeePay } from "../EmployeePay/EmployeePay";
import Acknowledgement from "../Acknowledgment/Acknowledgment";
import { Reimbursement } from "../Reimbursement/Reimbursement";
import { BenefitsAndAllowances } from "../TaxableBenefitsAndAllowances/BenefitsAndAllowances";

const OrderSettingsContent = ({
  formType,
  handleDisclaimersChecked,
}: {
  formType: string | undefined;
  handleDisclaimersChecked: (value: boolean) => void;
}) => {
  switch (formType) {
    case ADDITIONAL_PAY_FORM_ROUTES.EMPLOYEE_PAY:
      return <EmployeePay />;
    case ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT:
      return (
        <>
          <Reimbursement />
          <Acknowledgement
            formType={formType}
            disclaimersChecked={handleDisclaimersChecked}
          />
        </>
      );
    case ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES:
      return <BenefitsAndAllowances />;
    default:
      return null;
  }
};

export default OrderSettingsContent;
