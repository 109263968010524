import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { StepperProvider } from "./stepperFormHandler";
import { AdditionalPaySteps, ExchangeRateType } from "../types";
import {
  useGetEoRDisbursementDatesQuery,
  useGetEorExchangeRatesQuery,
  useGetEorPayPeriodsQuery,
} from "types/generated/operations";
import { ApolloError } from "@apollo/client";
import { Option } from "../components/Setup/components/SetupFormGenerator";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { getMonthsAgoISODate } from "../utils";

export interface AdditionalPayContextProps {
  currentStep?: AdditionalPaySteps;
  disbursementDateOptions: { [key: string]: Option };
  pastPayPeriodOptions: Option[];
  pastPayPeriodErrors?: ApolloError;
  disbursementDateError?: ApolloError;
  errorMessage: string;
  exchangeRates: ExchangeRateType[];
  selectedCountry: string;
  isEdit: boolean;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setCurrentStep: (step: AdditionalPaySteps) => void;
  setSelectedCountry: Dispatch<SetStateAction<string>>;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}

interface AdditionalPayProviderProps {
  children: ReactNode;
}

export const AdditionalPayContext =
  createContext<AdditionalPayContextProps | null>(null);

export const AdditionalPayContextProvider: FC<AdditionalPayProviderProps> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState<AdditionalPaySteps>("set-up");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { data: disbursementDates, error: disbursementDateError } =
    useGetEoRDisbursementDatesQuery({
      variables: { country: selectedCountry },
      skip: !selectedCountry,
    });

  const disbursementDateOptions: { [key: string]: Option } = {};
  disbursementDates?.upcomingPayDate.payPeriods.forEach((period) => {
    const date = formatDate(period.disbursementDate, "MM/DD/YYYY");
    const option: Option = {
      value: period.id || "",
      label: date || "",
    };
    disbursementDateOptions[period.id] = option;
  });

  const { data: pastPayPeriods, error: pastPayPeriodErrors } =
    useGetEorPayPeriodsQuery({
      variables: {
        country: selectedCountry,
        startDate: getMonthsAgoISODate(6).split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
      },
      skip: !selectedCountry,
    });

  const pastPayPeriodOptions: Option[] = [];
  pastPayPeriods?.payPeriodDates.payPeriods.forEach((period) => {
    const startDate = formatDate(period.startDate, "MM/DD/YYYY");
    const endDate = formatDate(period.endDate, "MM/DD/YYYY");
    const option: Option = {
      value: `${startDate} - ${endDate}`,
      label: `${startDate} - ${endDate}`,
    };
    pastPayPeriodOptions.push(option);
  });

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const currencies = Array.from(
    new Set(
      Object.values(supportedCountriesData).flatMap((country) =>
        (country.currencies ?? []).map((currency) => currency.currencyCode)
      )
    )
  );

  const ratesQuery = useGetEorExchangeRatesQuery({
    variables: { source: currencies.join(","), targets: ["USD"], time: null },
    skip: !currencies || currencies.length === 0,
  });

  let exchangeRates: ExchangeRateType[] = [];
  if (ratesQuery.data && ratesQuery.data?.exchangeRateData?.rates) {
    exchangeRates = ratesQuery.data.exchangeRateData.rates;
  }

  return (
    <StepperProvider>
      <AdditionalPayContext.Provider
        value={{
          currentStep,
          errorMessage,
          disbursementDateError,
          disbursementDateOptions,
          exchangeRates,
          selectedCountry,
          pastPayPeriodOptions,
          pastPayPeriodErrors,
          isEdit,
          setSelectedCountry,
          setErrorMessage,
          setCurrentStep,
          setIsEdit,
        }}
      >
        {children}
      </AdditionalPayContext.Provider>
    </StepperProvider>
  );
};
