import {
  ADDITIONAL_PAY_FORM_ROUTES,
  BenefitsAndAllowancesType,
  EmployeePayType,
  FormPayType,
  ReimbursementPayType,
} from "../constants";

import {
  BonusFrequency,
  BonusType,
  NonDiscretionaryType,
} from "../components/Setup/components/EmployeePay/BonusPay";
import { GetDisbursementOrderQuery } from "types/generated/operations";
import { getFormInitialValues } from "../utils";
import { FormPayloadType } from "../components/Setup/types";
import { loadFormInitialValues as employeeFormBasePayload } from "../components/Setup/components/EmployeePay/initialValueLoader";
import { CountryConfigKeyType } from "../components/Setup/components/EmployeePay/types";

export const buildEditFormValues = (
  orderData: GetDisbursementOrderQuery | undefined,
  payType: FormPayType
) => {
  if (!orderData) return null;

  const data = orderData.disbursementOrder.data;

  const workPeriodStart = data.paymentDetails.workPeriodStart
    ? data.paymentDetails.workPeriodStart
    : undefined;
  const workPeriodEnd = data.paymentDetails.workPeriodEnd
    ? data.paymentDetails.workPeriodEnd
    : undefined;

  const payload = getFormPayload(orderData, payType);

  return {
    setUp: {
      workCountry: orderData.disbursementOrder.country,
      type: payType,
      benefitType: data.paymentSettings?.benefitType || "",
      expenseType: data.paymentSettings?.expenseType || "",
      payload: payload,
    },
    paymentDetails: [
      {
        grossAmount: orderData.disbursementOrder.amount.toString(),
        memberId: orderData.disbursementOrder.memberId,
        currency: orderData.disbursementOrder.currency,
        workPeriodStart: workPeriodStart,
        workPeriodEnd: workPeriodEnd,
      },
    ],
    validationData: {
      members: [
        {
          id: orderData.disbursementOrder.memberId,
          workStartDate: orderData.disbursementOrder.member.workStartDate,
          workEndDate: orderData.disbursementOrder.member.workEndDate,
        },
      ],
    },
  };
};

const buildEmployeePayPayload = (
  orderData: GetDisbursementOrderQuery,
  payType: EmployeePayType
) => {
  const basePayload = employeeFormBasePayload(
    payType,
    orderData.disbursementOrder.country as CountryConfigKeyType
  );
  const data = orderData.disbursementOrder.data;
  if (basePayload) {
    basePayload.paymentSettings = {
      ...data.paymentSettings,
      disbursementDate: data.paymentSettings?.disbursementDate || "",
      notes: data.paymentSettings?.notes || "",
    };

    if ("taxes" in basePayload) {
      basePayload.taxes = {
        ...data.taxes,
        reason: data?.taxes?.reason || undefined,
        nonDiscretionaryType:
          (data?.taxes?.nonDiscretionaryType as NonDiscretionaryType) ||
          undefined,
        frequency: (data?.taxes?.frequency as BonusFrequency) || undefined,
        bonusType: (data?.taxes?.bonusType as BonusType) || undefined,
      };
    }
  }

  return basePayload;
};

const buildBenefitsAndAllowancesPayload = (
  orderData: GetDisbursementOrderQuery
) => {
  const payload = getFormInitialValues(
    ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES
  ).setUp.payload;

  const data = orderData.disbursementOrder.data;

  payload.paymentSettings = {
    payPeriodId: data.paymentSettings?.payPeriodId || "",
    formOfIncome: data.paymentSettings?.formOfIncome || "",
    disbursementDate: data.paymentSettings?.disbursementDate || "",
    notes: data.paymentSettings?.notes || "",
    benefitType: data.paymentSettings?.benefitType || "",
  };

  return payload;
};

const buildReimbursementPayload = (orderData: GetDisbursementOrderQuery) => {
  const payload = getFormInitialValues(ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT)
    .setUp.payload;
  const data = orderData.disbursementOrder.data;
  payload.paymentSettings = {
    payPeriodId: data.paymentSettings?.payPeriodId || "",
    disbursementDate: data.paymentSettings?.disbursementDate || "",
    expenseType: data.paymentSettings?.expenseType || "",
    notes: data.paymentSettings?.notes || "",
  };

  if ("expenseDetails" in payload) {
    payload.expenseDetails = {
      receipts:
        data.expenseDetails?.receipts?.map(
          (receipt) =>
            new File([], receipt.name, {
              type: `${receipt.type}:${receipt.uuid}`,
              lastModified: new Date(receipt.lastModified).getTime(),
            })
        ) || undefined,
      transactionDate: data.expenseDetails?.transactionDate || "",
      distance: data.expenseDetails?.distance || "",
      additionalInformation: data.expenseDetails?.additionalInformation || "",
    };
  }

  return payload;
};

const getFormPayload = (
  orderData: GetDisbursementOrderQuery,
  payType: FormPayType
): FormPayloadType | null => {
  switch (payType) {
    case BenefitsAndAllowancesType.BENEFITSANDALLOWANCES:
      return buildBenefitsAndAllowancesPayload(orderData);

    case ReimbursementPayType.REIMBURSEMENT:
      return buildReimbursementPayload(orderData);

    default:
      return buildEmployeePayPayload(orderData, payType);
  }
};
