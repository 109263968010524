import * as Yup from "yup";
import { ContractTerm, EmailTypes, PayBasis } from "../types";

export default Yup.object({
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  homeEmail: Yup.string().email("Invalid email format."),
  workEmail: Yup.string().email("Invalid email format.").required("Required"),
  contractTerm: Yup.string()
    .oneOf(Object.values(ContractTerm))
    .required("Required"),
  jobTitle: Yup.string().trim().required("Required"),
  workCountry: Yup.string()
    .oneOf(["CA", "GB", "MX", "NL", "IN", ""])
    .required("Required"),
  payBasis: Yup.string().oneOf(Object.values(PayBasis)).required("Required"),
  payRate: Yup.string()
    .required("Required")
    .test("is-greater-than-1", "Must be $1 or greater.", (value) => {
      const number = parseInt(value, 10);
      return !isNaN(number) && number >= 1;
    }),
  managerUuid: Yup.string(),
  departmentUuid: Yup.string(),
  sendInvitationTo: Yup.string()
    .oneOf(Object.values(EmailTypes))
    .required("Required"),
  startDate: Yup.string().required("Required"),
  honoredStartDate: Yup.string().test(
    "is-before-start-date",
    "Honored start date must be before start date.",
    function (value) {
      const { startDate } = this.parent;
      return !value || !startDate || new Date(value) < new Date(startDate);
    }
  ),
  roleResponsibilities: Yup.string().trim().required("Required"),
});
