import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import * as CHILE from "pages/employer-of-record/onboarding/profile-info/form/cl/constants.cl";

export const chileFields = {
  clHealthPlan: field({
    type: FieldTypes.select,
    name: "clHealthPlan",
    label: "Health Plan",
    placeholder: "Select Health Plan",
    validations: REQUIRED,
    options: CHILE.HEALTH_PLAN_OPTIONS,
    scenarios: {
      viewProfile: countryIs("CL"),
      onboarding: countryIs("CL"),
    },
    informationType: "identification",
    value: personalInfo("clHealthPlan", ""),
  }),
  clHealthAgencyName: field({
    type: FieldTypes.select,
    name: "clHealthAgencyName",
    label: "Health Agency Name",
    placeholder: "Select Health Agency",
    validations: REQUIRED,
    options: CHILE.HEALTH_AGENCY_OPTIONS,
    scenarios: {
      viewProfile: countryIs("CL"),
      onboarding: countryIs("CL"),
    },
    informationType: "identification",
    value: personalInfo("clHealthAgencyName", ""),
  }),
  clAfpAgencyName: field({
    type: FieldTypes.select,
    name: "clAfpAgencyName",
    label: "AFP Agency Name",
    placeholder: "Select AFP Agency",
    validations: REQUIRED,
    options: CHILE.AFP_AGENCY_OPTIONS,
    scenarios: {
      viewProfile: countryIs("CL"),
      onboarding: countryIs("CL"),
    },
    informationType: "identification",
    value: personalInfo("clAfpAgencyName", ""),
  }),
  clRut: identifierNumberFieldConfig("CL"),
  clConfirmRut: confirmIdentifierNumberFieldConfig("CL"),
};
