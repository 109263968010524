import React from "react";
import styles from "./JobInformation.module.css";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { getEmployeeName } from "../../utils";
import Row from "../Row/Row";
import { useTranslation } from "react-i18next";
import EditAction from "../EditAction/EditAction";
import { Card } from "@justworkshr/milo-core";
import ContractDetails from "./components/ContractDetails";
import { useGetFeatureFlag } from "lib/launch-darkly";

interface StringMap {
  [key: string]: string;
}

interface DetailsMap {
  [key: string]: string | undefined;
}

const JobInformation: React.FC<{ editable: boolean }> = ({ editable }) => {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const { getFeatureFlag } = useGetFeatureFlag();

  const generalInfoValues: DetailsMap = {
    workId: profileInfo?.eorEmployeeProfile.jobInformation?.workId || "",
    department:
      profileInfo?.eorEmployeeProfile.jobInformation?.department || "",
    manager: getEmployeeName(
      profileInfo?.eorEmployeeProfile.jobInformation?.managerInfo
    ),
  };

  const generalInfoMap: StringMap = {
    workId: "Work ID",
    department: "Department",
    manager: "Manager",
  };

  if (getFeatureFlag("release-eor-honored-start-date")) {
    generalInfoValues.honoredStartDate =
      profileInfo?.eorEmployeeProfile.jobInformation?.honoredStartDate ||
      undefined;
    generalInfoMap.honoredStartDate = "Honored Start Date";
  }

  const managerLink =
    profileInfo?.eorEmployeeProfile.jobInformation?.managerInfo?.uuid;
  const linkMap: StringMap = {
    manager: `members/${managerLink || "#"}`,
  };

  const renderRows = (
    data: DetailsMap,
    map: StringMap,
    hiddenValues: string[] = []
  ) =>
    Object.entries(data).map(([key, value]) => (
      <Row
        key={key}
        label={map[key]}
        value={value}
        showHideButton={hiddenValues.includes(key)}
        capitalizeValue={!hiddenValues.includes(key)}
        link={Object.keys(linkMap).includes(key) ? linkMap[key] : null}
      />
    ));

  return (
    <div className={styles.cardSection}>
      <Card
        title={t("General Information")}
        actions={[<EditAction target="job" enabled={editable} />]}
      >
        {renderRows(generalInfoValues, generalInfoMap)}
      </Card>

      <ContractDetails />
    </div>
  );
};

export default JobInformation;
