import React, { createContext, useContext, ReactNode } from "react";
import { useGetAllFeatureFlagsQuery } from "types/generated/operations";
import { asyncThrow } from "@justworkshr/fe-utility-kit";

export type FeatureFlagValueTypes = string | number | boolean | object;

export type FeatureFlagContextType = {
  featureFlags: LDFeatureFlags;
};

/**
 * Enumeration of all LD feature flags supported by Clockface
 *
 * Are you tyring to add a feature flag to be used for the first time?
 * Add it to this union of all possible LD flags
 */
export type FeatureFlagIds =
  | "postlogin-multinav-dropdown"
  | "show-redesigned-peo-logo"
  | "release-wc-claims-request"
  | "release-upload-file-with-presigned-url"
  | "release-benefits-empower-401k"
  | "release-benefits-empower-401k-transition-pdp"
  | "release-benefits-empower-401-k-employee-pdp"
  | "release-benefits-401-k-empower-er-pdp"
  | "waiver_coi_supporting_documentation"
  | "people_analytics_planned_maintenance"
  | "release-peeps-pay-transaction-page"
  | "release-basic-information-page-cf"
  | "release-compensation-history-page-cf"
  | "expenses-fesk"
  | "release-form-8973"
  | "enable-eor-employee-termination"
  | "peeps-terms-and-conditions-revision"
  | "release-eor-ee-profile-time-off"
  | "release-automated-eor-country-underwriting"
  | "release-eor-honored-start-date"
  | "release-resend-invitation-email";

type LDFeatureFlags = {
  [key in FeatureFlagIds]: FeatureFlagValueTypes;
};

const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: {} as LDFeatureFlags,
});

export const useGetFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      "useGetFeatureFlag must be used within a FeatureFlagProvider"
    );
  }
  return {
    getFeatureFlag: (flagId: FeatureFlagIds) =>
      context.featureFlags[flagId] || false,
  };
};

export const FeatureFlagsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const queryResult = useGetAllFeatureFlagsQuery();
  const { data, loading, error } = queryResult;

  if (error) {
    asyncThrow(
      `An error occurred while fetching feature flags: ${error.message}`
    );
  }

  if (loading || !data) {
    return <></>;
  }

  const featureFlags = data.allFeatureFlags as LDFeatureFlags;

  return (
    <FeatureFlagContext.Provider value={{ featureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
