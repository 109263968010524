import BankAccount from "../BankAccount/BankAccount";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { useContext } from "react";
import styles from "../PersonalInfo/PersonalInfo.module.css";
import ProfileInfoCard from "../PersonalInfo/ProfileInfoCard";
import BadgeTitle from "../PersonalInfo/components/BadgeTitle";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useFields } from "pages/employer-of-record/configs";

export default function Finances() {
  const { getCountryName } = useContext(SupportedCountriesContext);
  const { profileInfo } = useProfileContextData();
  const pensionFields = useFields({
    scenario: "viewProfile",
    informationType: "pension",
  });
  const workCountry = profileInfo?.eorEmployeeProfile?.employment?.workCountry;

  return (
    <div className={styles.cardSection}>
      <BankAccount />
      {!!pensionFields.length && (
        <ProfileInfoCard
          title={
            (
              <BadgeTitle
                title="Pensions"
                badgeText={getCountryName(workCountry)}
              />
            ) as unknown as string
          }
          editable
          informationType="pension"
          fields={pensionFields}
        />
      )}
    </div>
  );
}
