export const MARITAL_STATUS_OPTIONS = [
  {
    value: "single",
    description: "Single",
  },
  {
    value: "married",
    description: "Married",
  },
  {
    value: "other",
    description: "Other",
  },
];

export const crCedulaDeIdentidadRegEx = /^\d(?:[ -]?\d{4}){2}$/;
