const TAX_OPTIONS = [
  {
    value: "professional_tax",
    description: "Professional tax",
  },
  {
    value: "house_rent_allowance",
    description: "House Rent Allowance (HRA)",
  },
  {
    value: "leave_and_travel_allowance",
    description: "Leave and Travel allowance",
  },
  {
    value: "special_allowance",
    description: "Special Allowance",
  },
];

const TAX_REGIME = [
  {
    value: "new",
    description: "New tax regime",
  },
  {
    value: "old",
    description: "Old tax regime",
  },
];

export { TAX_OPTIONS, TAX_REGIME };
