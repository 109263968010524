export const EDUCATION_LEVEL = [
  {
    // Nessun titolo
    value: "no_education",
    description: "No formal education",
  },
  {
    // Licenza elementare
    value: "primary_school",
    description: "Primary school diploma",
  },
  {
    // Diploma di scuola superiore
    value: "middle_school",
    description: "Middle school diploma",
  },
  {
    value: "high_school",
    description: "High school diploma",
  },
  {
    // Qualifica professionale
    value: "vocational_qualification",
    description: "Vocational qualification",
  },
  {
    // Diploma tecnico
    value: "technical_diploma",
    description: "Technical diploma",
  },
  {
    // Laurea triennale
    value: "bachelors",
    description: "Bachelor's degree (3 years)",
  },
  {
    // Laurea magistrale
    value: "masters",
    description: "Master's degree (5 years)",
  },
  {
    // Dottorato di ricerca
    value: "doctorate",
    description: "PhD / Doctorate",
  },
];

export const itCodiceFiscaleRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
