import "@justworkshr/milo-design-tokens/build/css/tokens.css";
import "@justworkshr/milo-font/src/index.css";
import "@justworkshr/milo-base-css/src/index.css";
import "@justworkshr/milo-base-css/src/grid/grid.css";
import { AppLayout } from "../../app-frame";
import { Frameless } from "no-app-frame";
import Heap from "../analytics/heap";
import { Route, Routes } from "react-router-dom";
import Pendo from "../analytics/pendo";
import LoggedInAsHeader from "./logged-in-as-header";
import { FeatureFlagsProvider } from "lib/launch-darkly";
import Redirector from "./redirector";
import MultiMemberNavigationValidator from "./multi-member-navigation-validator";
import { RequireAuth } from "app/okta";
import { LoginCallbackPage } from "pages";
import { NeonSkyProvider } from "lib/neon-sky/neon-sky-provider";

function SharedRoutes(): JSX.Element {
  return (
    <FeatureFlagsProvider>
      <NeonSkyProvider>
        <LoggedInAsHeader
          isReadOnly={window.location.hostname.includes("read-only")}
        />
        <Routes>
          <Route path="/focus/*" element={<Frameless />} />
          <Route path="*" element={<AppLayout />} />
        </Routes>
        <Heap />
        <Pendo />
      </NeonSkyProvider>
    </FeatureFlagsProvider>
  );
}

export default function AppRoutes(): JSX.Element {
  const multiMemberNavigationPaths = [
    "cuid/:companyUuid/*",
    "ccid/:companyCanonicalId/*",
  ];

  return (
    <Routes>
      <Route path="/login/callback" element={<LoginCallbackPage />} />
      <Route path="/" element={<RequireAuth />}>
        <Route path="" element={<Redirector />} />
        <Route path="*" element={<SharedRoutes />} />
        {multiMemberNavigationPaths.map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <MultiMemberNavigationValidator>
                <SharedRoutes />
              </MultiMemberNavigationValidator>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}
