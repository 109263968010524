import Tooltip from "@justworkshr/milo-tooltip";
import {
  formatMinutesAsDays,
  formatMinutesAsHours,
  formatOrdinals,
} from "../../../../utils";
import { Policy } from "../../Policies";
import styles from "../../Policies.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Box, Row } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
const { row, rowEnd } = styles;
const AccrualSummary = ({ policy }: { policy: Policy }) => {
  const minutesAccruedRate = policy.minutesAccruedRate ?? 0;
  const minutesPerYear = policy.minutesPerYear ?? 0;
  const annualTenureMinutes = policy.annualTenureMinutes ?? 0;
  const minutesWorkedRate = policy.minutesWorkedRate ?? 0;

  const { t } = useTranslation();
  const hasTenure =
    policy.capped && policy.accrualLevels && policy.accrualLevels.length > 0;
  const accrualType =
    policy.capped && policy.awardMethod === "daily" ? "Accrual" : "Granted";

  if (policy.awardMethod === "hourly") {
    return (
      <Box>
        <Row className={row}>
          <strong>{t("Hourly accrual rate")}</strong>
          <span>{`${formatMinutesAsHours(minutesAccruedRate)} PTO per`}</span>
        </Row>
        <Row className={[row, rowEnd].join(" ")}>
          <span>{`${formatMinutesAsHours(minutesWorkedRate)} worked`}</span>
        </Row>
      </Box>
    );
  } else {
    return (
      <>
        <Box>
          <Row className={row}>
            <span>{t(`${accrualType} per year`)}</span>
            <span>{formatMinutesAsDays(minutesPerYear, policy.capped)}</span>
          </Row>
          {hasTenure && (
            <Row className={row}>
              <span>
                {t("Tenure")}
                <Tooltip
                  title={
                    (
                      <div>
                        {policy.accrualLevels?.map((accrualLevel, index) => {
                          return (
                            <div key={index}>
                              {policy.timePeriodType === "anniversary"
                                ? `${formatOrdinals(
                                    accrualLevel.anniversaryNumber
                                  )} anniversary`
                                : `${formatOrdinals(
                                    accrualLevel.anniversaryNumber + 1
                                  )} January`}
                              :
                              {`+${formatMinutesAsDays(
                                accrualLevel.additionalMinutes,
                                policy.capped
                              )}`}
                            </div>
                          );
                        })}
                      </div>
                    ) as unknown as string
                  }
                >
                  <SystemIcon
                    iconName="info-circle"
                    color="brand"
                    size="small"
                  />
                </Tooltip>
              </span>
              <span>
                {`+${formatMinutesAsDays(annualTenureMinutes, policy.capped)}`}
              </span>
            </Row>
          )}
        </Box>
        <Box>
          <Row className={row}>
            <strong>{t("Total per year")}</strong>
            <strong>
              {formatMinutesAsDays(
                minutesPerYear + annualTenureMinutes,
                policy.capped
              )}
            </strong>
          </Row>
        </Box>
      </>
    );
  }
};

export { AccrualSummary };
