import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import {
  MARITAL_STATUS_OPTIONS,
  SEX_OPTIONS,
} from "../../onboarding/profile-info/form/gr/constants.gr";
import { getDescription } from "pages/employer-of-record/utils";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const greeceFields = {
  grSex: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "grSex",
    label: "Sex",
    placeholder: "Select Sex",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: SEX_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grSex", ""),
    format: (value) => getDescription(SEX_OPTIONS, value) ?? value,
  }),
  grNameOfFather: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "grNameOfFather",
    label: "ΟΝΟΜΑ ΠΑΤΡΟΣ/Father's Name",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grNameOfFather", ""),
  }),
  grNameOfMother: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "grNameOfMother",
    label: "ΟΝΟΜΑ ΜΗΤΡΟΣ/Mother's Name",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grNameOfMother", ""),
  }),
  grRevenueOffice: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "grRevenueOffice",
    label: "ΕΦΟΡΙΑ (ΔΟΥ)/Revenue Office",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grRevenueOffice", ""),
  }),
  grMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "grMaritalStatus",
    label: "ΟΙΚΟΓΕΝΕΙΑΚΗ ΚΑΤΑΣΤΑΣΗ/Marital Status",
    placeholder: "Select Marital Status",
    options: MARITAL_STATUS_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grMaritalStatus", ""),
    format: (value) => getDescription(MARITAL_STATUS_OPTIONS, value) ?? value,
  }),
  grNumberOfChildren: field({
    type: FieldTypes.number,
    informationType: "identification",
    name: "grNumberOfChildren",
    label: "ΣΥΝΟΛΟ ΤΕΚΝΩΝ/Number of Children",
    validations: {
      ...REQUIRED,
      number: {
        min: {
          value: 0,
          message: "Must be a positive number",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: (profile) =>
      profile.personalInfo?.grNumberOfChildren?.toString() || "0",
  }),
  grIdentityCardNumber: field({
    type: FieldTypes.sensitiveText,
    informationType: "identification",
    name: "grIdentityCardNumber",
    label: "Identity Card Number",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grIdentityCardNumber", ""),
  }),
  grSocialInsuranceNumber: field({
    type: FieldTypes.sensitiveText,
    informationType: "identification",
    name: "grSocialInsuranceNumber",
    label: "ΑΜ ΙΚΑ/Social Insurance Number",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grSocialInsuranceNumber", ""),
  }),
  grFirstInsuranceDate: field({
    type: FieldTypes.date,
    informationType: "identification",
    name: "grFirstInsuranceDate",
    label: "ΗΜΕΡ. ΠΡΩΤΗΣ ΑΣΦΑΛΙΣΗΣ/First Insurance Date",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grFirstInsuranceDate", ""),
  }),
  grSocialSecurityNumber: field({
    type: FieldTypes.sensitiveText,
    informationType: "identification",
    name: "grSocialSecurityNumber",
    label: "ΑΜΚΑ/Social Security Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 11,
          message: "Invalid ΑΜΚΑ. Please try again.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("GR"),
    },
    value: personalInfo("grSocialSecurityNumber", ""),
  }),
  grVatNumber: identifierNumberFieldConfig("GR"),
  grConfirmVatNumber: confirmIdentifierNumberFieldConfig("GR"),
};
