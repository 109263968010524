import { Dispatch, SetStateAction, useEffect } from "react";
import { loadCountryConfigFiles } from "../countryConfigLoader";
import { addDisbursementDate } from "pages/employer-of-record/payments/utils";
import { AdditionalPayContextProps } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig } from "../../SetupFormGenerator";
import { CountryConfigKeyType, ReimbursementPayloadType } from "../types";
import { useFormikContext } from "formik";
import { AdditionalPayFormData } from "../../../types";
import { DecoratedMessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";

const useCountryConfigFetcher = (
  additionalPayContext: AdditionalPayContextProps | null,
  setPaymentSettingsFields: Dispatch<SetStateAction<FieldConfig[]>>,
  setExpenseDetailsFields: Dispatch<SetStateAction<FieldConfig[]>>,
  setExpenseTypes: Dispatch<SetStateAction<string[]>>,
  setMessage: Dispatch<SetStateAction<DecoratedMessageWithLinkType | null>>
) => {
  const selectedCountry = additionalPayContext?.selectedCountry;

  const { values } =
    useFormikContext<AdditionalPayFormData<ReimbursementPayloadType>>();

  useEffect(() => {
    if (selectedCountry) {
      const fetchCountryConfigs = () => {
        const configs = loadCountryConfigFiles(
          selectedCountry as CountryConfigKeyType
        );
        let paymentSettingsFormFields = configs.PAYMENT_SETTINGS_FIELDS;

        if (additionalPayContext?.disbursementDateOptions) {
          paymentSettingsFormFields = addDisbursementDate(
            paymentSettingsFormFields,
            Object.values(additionalPayContext?.disbursementDateOptions)
          );
        }

        configs.EXPENSE_DETAILS_FIELDS.map((val) => {
          if (val.name === "distance") {
            val.renderCheck = () => {
              return (
                configs.ReimbursementType.MILEAGE ===
                values.setUp.payload.paymentSettings?.expenseType
              );
            };
          }
        });

        setPaymentSettingsFields(paymentSettingsFormFields);
        setExpenseDetailsFields(configs.EXPENSE_DETAILS_FIELDS);
        setExpenseTypes(configs.EXPENSETYPES);

        const defaultMessage = configs.ReimbursementTypeMessage?.[""] || null;
        const message =
          configs.ReimbursementTypeMessage?.[
            values.setUp.payload.paymentSettings.expenseType || ""
          ] || defaultMessage;
        setMessage(message);
      };
      fetchCountryConfigs();
    }
  }, [
    selectedCountry,
    additionalPayContext?.disbursementDateOptions,
    values.setUp.payload.paymentSettings.expenseType,
    setExpenseDetailsFields,
    setExpenseTypes,
    setPaymentSettingsFields,
    setMessage,
  ]);
};

export default useCountryConfigFetcher;
