export type BankAccountTypeOption = {
  value: string;
  description: string;
};

export const BankAccountTypeOptions: Record<string, BankAccountTypeOption[]> = {
  AU: [
    {
      value: "transaction",
      description: "Current (Transaction)",
    },
    {
      value: "savings",
      description: "Savings (Savings)",
    },
  ],
  BR: [
    {
      value: "corrente",
      description: "Current (Corrente)",
    },
    {
      value: "poupança",
      description: "Savings (Poupança)",
    },
  ],
  CA: [
    {
      value: "chequing",
      description: "Chequing",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  CL: [
    {
      value: "corriente",
      description: "Chequing (Corriente)",
    },
    {
      value: "ahorro",
      description: "Savings (Ahorro)",
    },
  ],
  CO: [
    {
      value: "corriente",
      description: "Current (Corriente)",
    },
    {
      value: "ahorros",
      description: "Savings (Ahorros)",
    },
  ],
  CR: [
    {
      value: "checking",
      description: "Checking",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  DE: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  EE: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  ES: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  FR: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  GB: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  GR: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  IE: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  IN: [
    {
      value: "चालू खाता",
      description: "Current (चालू खाता)",
    },
    {
      value: "बचत खाता",
      description: "Savings (बचत खाता)",
    },
  ],
  IT: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  MX: [
    {
      value: "current",
      description: "Current",
    },
  ],
  NL: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  PT: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  PE: [
    {
      value: "corriente",
      description: "Current (Corriente)",
    },
    {
      value: "ahorro",
      description: "Savings (Ahorro)",
    },
  ],
  IL: [
    {
      value: "current",
      description: "Current (חשבון עובר ושב)",
    },
    {
      value: "savings",
      description: "Savings (חשבון חיסכון)",
    },
  ],
  RO: [
    {
      value: "current",
      description: "Current (Cont curent)",
    },
    {
      value: "savings",
      description: "Savings (Cont de economii)",
    },
  ],
};
