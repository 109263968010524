import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const portugalFields = {
  ptCc: field({
    type: FieldTypes.text,
    informationType: "cartao",
    name: "ptCc",
    label: "Number",
    scenarios: {
      viewProfile: countryIs("PT"),
      onboarding: countryIs("PT"),
    },
    value: personalInfo("ptCc", ""),
    validations: {
      required: {
        when: {
          nationality: (value: unknown) => value === "PT",
        },
      },
    },
  }),
  ptCcIssDate: field({
    type: FieldTypes.date,
    informationType: "cartao",
    name: "ptCcIssDate",
    label: "Issue date",
    scenarios: {
      editProfile: countryIs("PT"),
      viewProfile: countryIs("PT"),
      onboarding: countryIs("PT"),
    },
    value: personalInfo("ptCcIssDate", ""),
    serialize: (value) => (value === "" ? null : value),
  }),
  ptCcExpDate: field({
    type: FieldTypes.date,
    informationType: "cartao",
    name: "ptCcExpDate",
    label: "Expiration date",
    scenarios: {
      editProfile: countryIs("PT"),
      viewProfile: countryIs("PT"),
      onboarding: countryIs("PT"),
    },
    value: personalInfo("ptCcExpDate", ""),
    serialize: (value) => (value === "" ? null : value),
  }),
  ptNiss: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "ptNiss",
    label: "Instituto Nacional do Seguro Social (NISS)",
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: /^\d{11}$/,
          message: "Must be exactly 11 digits.",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("PT"),
      onboarding: countryIs("PT"),
    },
    value: personalInfo("ptNiss", ""),
  }),
  ptNif: identifierNumberFieldConfig("PT"),
  ptConfirmNif: confirmIdentifierNumberFieldConfig("PT"),
};
