export const MARITAL_STATUS_OPTIONS = [
  { value: "single", description: "Single" },
  { value: "married", description: "Married" },
  { value: "divorced", description: "Divorced" },
  { value: "widowed", description: "Widowed" },
  { value: "separated", description: "Separated" },
  { value: "civil_partnership", description: "Civil Partnership" },
];

export const SEX_OPTIONS = [
  { value: "male", description: "Male" },
  { value: "female", description: "Female" },
];
