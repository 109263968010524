import { useGetAdminCapabilitiesQuery } from "types/generated/operations";

export default function useIsAdmin() {
  const { loading, data: capabilityData } = useGetAdminCapabilitiesQuery();
  return {
    loading,
    isAdmin:
      capabilityData?.authenticatedMember.capabilities
        .hasAccessToAdminProfileView,
  };
}
