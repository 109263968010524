import { Avatar, Card } from "@justworkshr/milo-core";
import { Label } from "@justworkshr/milo-form";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  getActiveCurrency,
  getExchangeRate,
  hasWorkPeriod,
} from "pages/employer-of-record/payments/utils";
import styles from "./Details.module.css";
import { Link } from "react-router-dom";
import { PayDateComponent } from "../../PaymentDetails/components/PaymentDetailsRow/PayDateComponent";
import { useFormikContext } from "formik";
import { AdditionalPayFormData, FormPayloadType } from "../../Setup/types";
import { GetDisbursementOrderQuery } from "types/generated/operations";
import { useContext } from "react";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { GrossAmountInput } from "../../GrossAmountInput/GrossAmountInput";

type Props = {
  member: GetDisbursementOrderQuery["disbursementOrder"]["member"];
};

export const Details = ({ member }: Props) => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const { values } = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const countryConfig = supportedCountriesData[values.setUp.workCountry || ""];
  const currencyData = getActiveCurrency(countryConfig.currencies);
  const exchangeRate = currencyData?.currencyCode
    ? getExchangeRate(
        currencyData?.currencyCode,
        additionalPayContext?.exchangeRates
      )
    : undefined;

  const memberName = member.preferredFirstName
    ? member.preferredFirstName
    : member.firstName;

  return (
    <>
      <Card title="Payment details">
        <div className={styles.cardWrapper}>
          <Label>Payee</Label>
          <div className={styles.memberInfoWrapper}>
            <Avatar name={memberName} size="xs" />
            <div className={styles.memberInfo}>
              <Link
                to={buildClockworkWebPath(`members/${member.memberUuid}`)}
                target="_blank"
              >
                <span className={styles.name}>
                  {memberName} {member.lastName}
                </span>
              </Link>
              <span className={styles.memberCountry}>
                {countryConfig.commonName}
              </span>
            </div>
          </div>

          <GrossAmountInput
            index={0}
            currencySymbol={currencyData?.symbol || ""}
            currency={currencyData?.currencyCode || ""}
            exchangeRate={exchangeRate}
          />
          <PayDateComponent
            countryInfo={countryConfig}
            member={member}
            hasDatePeriod={hasWorkPeriod(values.setUp)}
            hasLabels
          />
        </div>
      </Card>
    </>
  );
};
