import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { array, countryIs, field, personalInfo, REQUIRED } from "./utils";
import {
  GENDER_OPTIONS,
  DISABILITY_OPTIONS,
  INCOME_TAX_STATUS_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  CITIZENSHIP_OPTIONS,
  RACE_OPTIONS,
  myPhoneRegex,
} from "pages/employer-of-record/onboarding/profile-info/form/my/constants.my";

export const malaysiaFields = {
  myHasCitizenship: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "myHasCitizenship",
    label: "Malaysian Citizenship",
    placeholder: "Select Malaysian Citizenship",
    validations: REQUIRED,
    options: CITIZENSHIP_OPTIONS,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myHasCitizenship", ""),
  }),
  myPassportNumber: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "myPassportNumber",
    label: "Passport Number",
    validations: REQUIRED,
    hideWhen: {
      myHasCitizenship: (value) => typeof value === "string" && value !== "no",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myPassportNumber", ""),
  }),
  myEpf: field({
    type: FieldTypes.text,
    informationType: "pension",
    name: "myEpf",
    label: "EPF Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 8,
          message: "Must be a valid EPF Number.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myEpf", ""),
  }),
  myGender: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "myGender",
    label: "Gender",
    placeholder: "Select Gender",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: GENDER_OPTIONS,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myGender", ""),
  }),
  myHasDisability: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "myHasDisability",
    label: "Disability",
    placeholder: "Select Disability",
    validations: REQUIRED,
    options: DISABILITY_OPTIONS,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myHasDisability", ""),
  }),
  myIcNumber: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "myIcNumber",
    label: "IC Number",
    hideWhen: {
      myHasCitizenship: (value) => typeof value === "string" && value !== "yes",
    },
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: /^\d{6}\d{2}\d{4}$/,
          message: "Must be a valid Identity Card Number.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myIcNumber", ""),
  }),
  myIncomeTaxBranch: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "myIncomeTaxBranch",
    label: "Income Tax Branch",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myIncomeTaxBranch", ""),
  }),
  myIncomeTaxNumber: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "myIncomeTaxNumber",
    label: "Income Tax Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 13,
          message: "Must be a valid Income Tax Number.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myIncomeTaxNumber", ""),
  }),
  myIncomeTaxStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "myIncomeTaxStatus",
    label: "Income Tax Status",
    placeholder: "Select Income Tax Status",
    validations: REQUIRED,
    options: INCOME_TAX_STATUS_OPTIONS,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myIncomeTaxStatus", ""),
  }),
  myMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "family",
    name: "myMaritalStatus",
    label: "Marital Status",
    placeholder: "Select Marital Status",
    validations: REQUIRED,
    options: MARITAL_STATUS_OPTIONS,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myMaritalStatus", ""),
  }),
  mySpouseName: field({
    type: FieldTypes.text,
    informationType: "family",
    name: "mySpouseName",
    label: "Spouse Name",
    validations: REQUIRED,
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseName", ""),
  }),
  mySpouseIcNumber: field({
    type: FieldTypes.text,
    informationType: "family",
    name: "mySpouseIcNumber",
    label: "Spouse IC Number",
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: /^\d{6}\d{2}\d{4}$/,
          message: "Must be a valid Identity Card Number.",
        },
      },
    },
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseIcNumber", ""),
  }),
  mySpouseWorkingStatus: field({
    type: FieldTypes.select,
    informationType: "family",
    name: "mySpouseWorkingStatus",
    label: "Spouse is working?",
    placeholder: "Select Marital Working status",
    validations: REQUIRED,
    options: DISABILITY_OPTIONS,
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseWorkingStatus", ""),
  }),
  mySpouseDisability: field({
    type: FieldTypes.select,
    informationType: "family",
    name: "mySpouseDisability",
    label: "Spouse Disability",
    placeholder: "Select Spouse Disability",
    options: DISABILITY_OPTIONS,
    validations: REQUIRED,
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseDisability", ""),
  }),
  mySpouseIncomeTaxNumber: field({
    type: FieldTypes.text,
    informationType: "family",
    name: "mySpouseIncomeTaxNumber",
    label: "Spouse Income Tax Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 13,
          message: "Must be a valid Income Tax Number.",
        },
      },
    },
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseIncomeTaxNumber", ""),
  }),
  mySpouseIncomeTaxBranch: field({
    type: FieldTypes.text,
    informationType: "family",
    name: "mySpouseIncomeTaxBranch",
    label: "Spouse Income Tax Branch",
    validations: REQUIRED,
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseIncomeTaxBranch", ""),
  }),
  mySpouseTelephoneNumber: field({
    type: FieldTypes.text,
    informationType: "family",
    name: "mySpouseTelephoneNumber",
    label: "Spouse Telephone Number",
    validations: {
      ...REQUIRED,
      text: {
        matches: {
          value: myPhoneRegex,
          message: "Must be a valid Phone Number.",
        },
      },
    },
    hideWhen: {
      myMaritalStatus: (value) =>
        typeof value === "string" && value !== "married",
    },
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySpouseTelephoneNumber", ""),
  }),
  myRace: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "myRace",
    label: "Race",
    placeholder: "Select Race",
    validations: REQUIRED,
    options: RACE_OPTIONS,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myRace", ""),
  }),
  mySocsoNumber: field({
    type: FieldTypes.text,
    informationType: "benefits",
    name: "mySocsoNumber",
    label: "SOCSO Number",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("mySocsoNumber", ""),
  }),
  myHasChildren: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "myHasChildren",
    label: "Do you have children?",
    placeholder: "Select if you have children",
    validations: REQUIRED,
    options: DISABILITY_OPTIONS,
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myHasChildren", ""),
  }),
  myChildrenInformation: array({
    type: FieldTypes.myChildrenInformation,
    informationType: "family",
    array: {
      limit: 10,
    },
    hideWhen: {
      myHasChildren: (value) => typeof value === "string" && value !== "yes",
    },
    name: "myChildrenInformation",
    label: "Children Information",
    scenarios: {
      onboarding: countryIs("MY"),
    },
    value: personalInfo("myChildrenInformation", []),
  }),
};
