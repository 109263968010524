import { ReactElement, useContext } from "react";
import { Card } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./Review.module.css";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";
import SettingsRows from "./components/SettingsRows/SettingsRows";
import PaymentDetailsTable from "./components/PaymentDetailsTable/PaymentDetailsTable";
import SummaryCard from "./components/SummaryCard/SummaryCard";

export const Review = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);

  return (
    <div className={styles.container}>
      <div className={styles.cardWrapper}>
        <div className={styles.column}>
          <Card
            title="Settings"
            actions={[
              <div
                className={styles.cardAction}
                onClick={() => {
                  additionalPayContext?.setCurrentStep("set-up");
                }}
              >
                <SystemIcon iconName="edit" title="Edit" color="brand" />
                <span id="edit-label">Edit</span>
              </div>,
            ]}
          >
            <SettingsRows />
          </Card>
          <div className={styles.noHeaderBorder}>
            <Card
              title="Payment Details"
              actions={[
                <div
                  className={styles.cardAction}
                  onClick={() => {
                    additionalPayContext?.setCurrentStep("payment-details");
                  }}
                >
                  <SystemIcon iconName="edit" title="Edit" color="brand" />
                  <span id="edit-label">Edit</span>
                </div>,
              ]}
            >
              <PaymentDetailsTable />
            </Card>
          </div>
        </div>

        <div className={styles.column}>
          <SummaryCard isEdit={false} />
        </div>
      </div>
    </div>
  );
};
