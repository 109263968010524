import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, OPTIONAL, personalInfo, REQUIRED } from "./utils";
import {
  mxCivilStatusOptions,
  mxPersonalIdRegEx,
  mxSexOptions,
} from "pages/employer-of-record/onboarding/profile-info/form/mx/constants.mx";
import { stdnum } from "stdnum";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const mexicoFields = {
  mxChristmasBonusDays: field({
    type: FieldTypes.number,
    informationType: "contract",
    name: "mxChristmasBonusDays",
    label: "Christmas bonus (aguinaldo) days",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("MX"),
    },
    value: (profile) => profile.role?.mxChristmasBonusDays?.toString() ?? "",
  }),
  mxSex: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "mxSex",
    label: "Sex",
    placeholder: "Select Sex",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    options: mxSexOptions,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("MX"),
    },
    value: () => "", // TODO: Expose mxSex in the EOR profile response?
  }),
  mxCivilStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "mxCivilStatus",
    label: "Civil Status",
    placeholder: "Select Civil Status",
    options: mxCivilStatusOptions,
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("MX"),
    },
    value: () => "", // TODO: Expose mxCivilStatus in the EOR profile response?
  }),
  mxPersonalId: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "mxPersonalId",
    label: "ID Number (INE/Passport)",
    validations: {
      ...OPTIONAL,
      text: {
        matches: {
          value: mxPersonalIdRegEx,
          message: "Must be a valid Personal ID.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("MX"),
    },
    value: personalInfo("mxPersonalId", ""),
  }),
  mxNationalId: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "mxNationalId",
    label: "National ID Number (CURP)",
    validations: {
      ...REQUIRED,
      text: {
        fn: {
          value: (value) =>
            value ? stdnum.MX.curp.validate(value).isValid : false,
          message: "Must be a valid National ID.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("MX"),
    },
    value: personalInfo("mxNationalId", ""),
  }),
  mxWorkPermitId: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "mxWorkPermitId",
    label: "Work Permit ID",
    validations: OPTIONAL,
    hideWhen: {
      nationality: (value) => typeof value === "string" && value === "MX",
    },
    scenarios: {
      onboarding: countryIs("MX"),
    },
    value: personalInfo("mxWorkPermitId", ""),
  }),
  mxSocialSecurityNumber: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "mxSocialSecurityNumber",
    label: "Social Security Number",
    validations: {
      ...REQUIRED,
      text: {
        length: {
          value: 11,
          message: "Must be a valid Social Security Number.",
        },
      },
    },
    scenarios: {
      onboarding: countryIs("MX"),
    },
    value: personalInfo("mxSocialSecurityNumber", ""),
  }),
  mxHasInfonavitLoan: field({
    type: FieldTypes.boolean,
    informationType: "contract",
    name: "mxHasInfonavitLoan",
    label: "Do you have an Infonavit loan?",
    validations: REQUIRED,
    scenarios: {
      editProfile: countryIs("MX"),
      viewProfile: countryIs("MX"),
      onboarding: countryIs("MX"),
    },
    value: personalInfo("mxHasInfonavitLoan", false),
    format: (value) => (value.toString() === "true" ? "Yes" : "No"),
  }),
  mxHasFonacotLoan: field({
    type: FieldTypes.boolean,
    informationType: "contract",
    name: "mxHasFonacotLoan",
    label: "Do you have a Fonacot loan?",
    validations: REQUIRED,
    scenarios: {
      editProfile: countryIs("MX"),
      viewProfile: countryIs("MX"),
      onboarding: countryIs("MX"),
    },
    value: personalInfo("mxHasFonacotLoan", false),
    format: (value) => (value.toString() === "true" ? "Yes" : "No"),
  }),
  mxTin: identifierNumberFieldConfig("MX"),
  mxConfirmTin: confirmIdentifierNumberFieldConfig("MX"),
};
