import { ReactElement } from "react";
import styles from "./TimeOff.module.css";
import WorkingOnAHoliday from "./components/StatutoryHolidayHours/WorkingOnAHoliday";
import AdditionalLeave from "./components/UnpaidLeave/AdditionalLeave";
import History from "./components/History/History";
import { Policies } from "./components/Policies/Policies";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { UpcomingRequests } from "./components/UpcomingRequests/UpcomingRequests";

export default function TimeOff(): ReactElement {
  const { getFeatureFlag } = useGetFeatureFlag();
  return (
    <>
      <div className={styles.cardSection}>
        {getFeatureFlag("release-eor-ee-profile-time-off") && <Policies />}
        <AdditionalLeave />
        <WorkingOnAHoliday />
        {getFeatureFlag("release-eor-ee-profile-time-off") && (
          <UpcomingRequests />
        )}
        {getFeatureFlag("release-eor-ee-profile-time-off") && <History />}
      </div>
    </>
  );
}
