import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import CompanyBankNotice, {
  NoticeTypes,
} from "pages/company-settings/components/CompanyBankNotice";
import NewAccountDetails from "pages/company-settings/components/NewAccountDetails";
import { buildClockworkWebPath, useNeonPath } from "lib/resource-finder";
import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useSearchParams } from "react-router-dom";
import BankAccountPageHeader from "./BankAccountPageHeader";
import { useBankForm } from "pages/company-settings/hooks/useBankForm";

const { content, notice, noticeText, page, footer } = styles;

export default function SuccessfulMicrodepositInitiatedPage() {
  const { isOnboarding } = useBankForm();
  const companyPath = useNeonPath("/company");
  const redirect = () => {
    if (isRtp) {
      window.location.assign(
        "/cf/company-settings/verify-microdeposit-amounts"
      );
      return;
    }
    if (isOnboarding) {
      window.location.assign(
        buildClockworkWebPath("/onboarding_checklist/bank_verify_success")
      );
      return;
    }

    window.location.assign(companyPath);
  };

  const [searchParams] = useSearchParams();

  const isRtp = searchParams.get("rail_type") == "rtp";

  const description = isRtp ? (
    <>
      Check the new bank account for 2 small deposits from JUSTWORKS VERIFY.
      They should be there in a minute or two. Confirm the amounts to verify
      your account.
    </>
  ) : (
    <>
      In the next 2-3 business days, you should see 2 small deposits from
      JUSTWORKS VERIFY in the new bank account. If you don’t see them, contact
      customer support at <b>support@justworks.com</b> or <b>(888) 534-1711</b>.
    </>
  );

  const buttonText = isRtp ? "Confirm deposit amounts" : "Done";

  return (
    <div className={page}>
      <BankAccountPageHeader />
      <div className={content}>
        <div className={notice}>
          <CompanyBankNotice
            title="We’ve sent the microdeposits"
            type={NoticeTypes.SUCCESS}
          >
            <p className={noticeText}>{description}</p>
          </CompanyBankNotice>
        </div>
        <NewAccountDetails />
        <ActionFooter
          className={footer}
          actions={[
            <Button
              data-testid="submit-button"
              type="submit"
              onClick={redirect}
              key="save-button"
            >
              {buttonText}
            </Button>,
          ]}
        />
      </div>
    </div>
  );
}
