import { Badge, Box } from "@justworkshr/milo-core";
import React from "react";
import styles from "./RequestItem.module.css";
import { formatMinutesAsDays, formatMinutesAsHours } from "../../utils";

interface RequestItemProps {
  effectiveDate: string;
  minutes: number;
  policyName: string;
  isAdjustment?: boolean;
  responsibleAdminName?: string;
  status?: string;
}

const RequestItem: React.FC<RequestItemProps> = ({
  effectiveDate,
  minutes,
  policyName,
  isAdjustment = false,
  responsibleAdminName,
  status,
}) => {
  return (
    <Box padding="sm">
      <div role="listitem" className={styles.historyItemBox}>
        <div>
          <b>{effectiveDate}</b>
          <div>
            {`${formatMinutesAsDays(minutes)} (${formatMinutesAsHours(
              minutes
            )}) of ${policyName}`}
          </div>
          {isAdjustment && (
            <div className={styles.adjustmentText}>
              {`ADJUSTMENT - MADE BY ${responsibleAdminName?.toUpperCase()}`}
            </div>
          )}
        </div>
        {!isAdjustment && (
          <Badge
            className={styles.statusBadge}
            role={status == "APPROVED" ? "positive" : "neutral"}
          >
            {status || "No status"}
          </Badge>
        )}
      </div>
    </Box>
  );
};

export { RequestItem };
