import { ReactElement, useContext } from "react";
import styles from "./Confirmation.module.css";
import { Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import {
  getEmployeeName,
  getTotalGrossAmount,
  getTotalNumberOfPayees,
  getExchangeRate,
  getTypeDisplayValue,
  removeEntriesWithNoGrossAmount,
} from "../../utils";
import { toCurrencyFormat } from "pages/employer-of-record/utils";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";
import { useLocation } from "react-router-dom";
import { ADDITIONAL_PAY_FORM_ROUTES } from "../../constants";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useMemberData } from "../../contexts/memberDataContext";
import { useFormikContext } from "formik";
import { AdditionalPayFormData, FormPayloadType } from "../Setup/types";

export const Confirmation = (): ReactElement => {
  const { values } = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { getCurrency } = useContext(SupportedCountriesContext);
  const additionalPayContext = useContext(AdditionalPayContext);
  const memberDataContext = useMemberData();

  const currencyData = getCurrency(additionalPayContext?.selectedCountry);

  const location = useLocation();

  const payDate =
    additionalPayContext?.disbursementDateOptions[
      values.setUp.payload.paymentSettings.payPeriodId
    ].label;

  const payType = getTypeDisplayValue(values.setUp.type);

  const totalGrossAmount = toCurrencyFormat(
    Number(
      getTotalGrossAmount(values.paymentDetails, currencyData.currencyCode)
    ),
    currencyData.currencyCode,
    true,
    false,
    currencyData?.symbol
  );

  const exchangeRate = getExchangeRate(
    currencyData?.currencyCode,
    additionalPayContext?.exchangeRates
  );

  const getReimbursedEmployeeName = () => {
    // currently we only do one employee at a time
    const memberEntry = removeEntriesWithNoGrossAmount(values.paymentDetails);
    const memberId = memberEntry[0].memberId;
    const member = memberDataContext?.membersData?.find(
      (member) => member.id === memberId
    );
    return getEmployeeName(member);
  };

  const renderPayeeRow = () => {
    const isMassPay = location.pathname.includes(
      `${ADDITIONAL_PAY_FORM_ROUTES.EMPLOYEE_PAY}`
    );
    const isExpenseReimbursement = location.pathname.includes(
      `${ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT}`
    );
    if (isMassPay) {
      return (
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Payees</div>
          <div className={styles.rowValue}>
            {getTotalNumberOfPayees(values.paymentDetails)}
          </div>
        </div>
      );
    } else if (isExpenseReimbursement) {
      return (
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Payee</div>
          <div className={styles.rowValue}>{getReimbursedEmployeeName()}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Box padding="2xl" backgroundColor="neutral">
          <div className={styles.headerSection}>
            <SystemIcon
              iconName="check-circle-inverted"
              size="extra-large"
              color="inverse"
            />
            <div className={styles.scheduled}>
              Payment {additionalPayContext?.isEdit ? "updated" : "scheduled"}!
            </div>
            <div className={styles.amount}>{totalGrossAmount}</div>
          </div>
          <div className={`${styles.smallText} ${styles.cardMsg}`}>
            <p>
              The payment and the applied exchange rate will appear on your
              finalized invoice.
            </p>
            {!additionalPayContext?.isEdit && (
              <p>
                To cancel this payment or make changes, it'll be in Payment
                Activity in the Payments center.
              </p>
            )}
          </div>
          <div className={`${styles.body}`}>
            {renderPayeeRow()}
            <div className={styles.summaryRow}>
              <div className={styles.rowLabel}>Pay type</div>
              <div className={styles.rowValue}>{payType}</div>
            </div>
            {exchangeRate && (
              <div className={styles.summaryRow}>
                <div className={styles.rowLabel}>Estimated FX rate</div>
                <div className={styles.rowValue}>{exchangeRate}</div>
              </div>
            )}
            <div className={styles.summaryRow}>
              <div className={styles.rowLabel}>Pay date</div>
              <div className={styles.rowValue}>{payDate}</div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};
