import { Button, Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Dropdown } from "pages/employer-of-record/components";
import { useEffect, useState } from "react";
import styles from "./HeaderActions.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { ViewType } from "app-frame/header/context/view-context-provider";
import { useTranslation } from "react-i18next";
import { useResendEorInvitationEmailMutation } from "types/generated/operations";
import { useProfileContextData } from "../../../contexts/ProfileInfoContext";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { getEmployeeDisplayName } from "../../../utils";
import type { AlertState } from "pages/employer-of-record/invite/types";

function RequestTerminationButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      variant="outlined"
      type="button"
      size="sm"
      leftIcon="close"
      id="requestTermProfile"
      data-testid="requestTerminationButton"
      onClick={onClick}
    >
      {t("Request separation")}
    </Button>
  );
}

function useNavigateToEditCompensation() {
  const navigate = useNavigate();
  const { id } = useParams();

  return () => {
    navigate(`/employer-of-record/employee-profile/${id}/compensation`);
  };
}

function EditCompensationButton() {
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      variant="outlined"
      size="sm"
      data-testid="editCompensationButton"
      onClick={useNavigateToEditCompensation()}
    >
      {t("Edit compensation")}
    </Button>
  );
}

function ResendInviteEmailButton(props: {
  onResendInviteEmail?: () => void;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      variant="filled"
      size="sm"
      onClick={props.onResendInviteEmail}
      disabled={props.isLoading}
    >
      {t("Resend invite")}
    </Button>
  );
}

type DropdownAction = {
  name: string;
  onClick?: () => void;
  disabled?: boolean;
};

function HeaderActionsDropdown({ actions }: { actions: DropdownAction[] }) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  function openDropdown() {
    setDropdownOpen(true);
  }

  function closeDropdown() {
    setDropdownOpen(false);
  }

  return (
    <Dropdown
      dropdownName="header-mobile-actions"
      contentClassName={styles.dropdownContent}
      onClickOutside={closeDropdown}
      onOpenChange={openDropdown}
      content={
        <Box
          padding="xs"
          border={{
            borderWidth: "md",
            borderColor: "brand",
            borderRadius: "md",
          }}
        >
          {actions.map((action) => (
            <div
              key={action.name}
              className={`${styles.mobileAction} ${
                action.disabled ? styles.disabled : ""
              }`}
              onClick={() => {
                if (!action.disabled) {
                  closeDropdown();
                  action.onClick?.();
                }
              }}
            >
              {action.name}
            </div>
          ))}
        </Box>
      }
      open={dropdownOpen}
    >
      <SystemIcon iconName="ellipsis" color="brand" className="" />
    </Dropdown>
  );
}

function useResendInvite(setAlert: (alert: AlertState | undefined) => void) {
  const { profileInfo } = useProfileContextData();
  const [resendEorInvitationEmailMutation, { loading: isResendInviteLoading }] =
    useResendEorInvitationEmailMutation();
  const { getFeatureFlag } = useGetFeatureFlag();
  const { t } = useTranslation();

  async function onResendInvite() {
    const memberUuid = profileInfo?.eorEmployeeProfile.personalInfo?.memberUuid;

    if (!memberUuid) {
      throw Error("this should never happen");
    }

    try {
      const result = await resendEorInvitationEmailMutation({
        variables: {
          memberUuid: memberUuid,
        },
      });

      if (result.data) {
        const employeeName = getEmployeeDisplayName(
          profileInfo?.eorEmployeeProfile.personalInfo
        );
        setAlert({
          visible: true,
          message: t("Invite sent to {{name}}", { name: employeeName }),
          type: "additive",
        });
      }
    } catch (error) {
      console.error("Error resending invitation:", error);
      setAlert({
        visible: true,
        message: t(
          "We weren't able to resend the invite. Please try again later."
        ),
        type: "destructive",
      });
    }
  }

  function shouldShowResendInvite() {
    const inCorrectState =
      profileInfo?.eorEmployeeProfile.onboarding?.workflowState !== "complete";
    return getFeatureFlag("release-resend-invitation-email") && inCorrectState;
  }

  return {
    onResendInvite,
    shouldShowResendInvite,
    isResendInviteLoading,
  };
}

function DesktopHeaderActions({
  onRequestTermination,
  setAlert,
}: {
  onRequestTermination: () => void;
  setAlert: (alert: AlertState | undefined) => void;
}) {
  const { onResendInvite, shouldShowResendInvite, isResendInviteLoading } =
    useResendInvite(setAlert);

  return (
    <div className={styles.container}>
      {shouldShowResendInvite() && (
        <ResendInviteEmailButton
          onResendInviteEmail={onResendInvite}
          isLoading={isResendInviteLoading}
        />
      )}
      <EditCompensationButton />
      <RequestTerminationButton onClick={onRequestTermination} />
    </div>
  );
}

function MobileHeaderActions({
  onRequestTermination,
  setAlert,
}: {
  onRequestTermination: () => void;
  setAlert: (alert: AlertState | undefined) => void;
}) {
  const { t } = useTranslation();
  const navigateToEditCompensation = useNavigateToEditCompensation();
  const { onResendInvite, shouldShowResendInvite, isResendInviteLoading } =
    useResendInvite(setAlert);

  const actions: DropdownAction[] = [
    {
      name: t("Edit compensation"),
      onClick: navigateToEditCompensation,
    },
    {
      name: t("Request separation"),
      onClick: onRequestTermination,
    },
  ];

  if (shouldShowResendInvite()) {
    actions.push({
      name: t("Resend invite"),
      onClick: onResendInvite,
      disabled: isResendInviteLoading,
    });
  }

  return <HeaderActionsDropdown actions={actions} />;
}

export default function HeaderActions({
  onRequestTermination,
  setAlert,
}: {
  onRequestTermination: () => void;
  setAlert: (alert: AlertState | undefined) => void;
}) {
  function getLayout() {
    return window.innerWidth >= 769 ? ViewType.DESKTOP : ViewType.MOBILE;
  }

  const [layout, setLayout] = useState<ViewType.DESKTOP | ViewType.MOBILE>(
    getLayout()
  );

  useEffect(() => {
    function handleResize() {
      setLayout(getLayout());
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Component =
    layout === ViewType.DESKTOP ? DesktopHeaderActions : MobileHeaderActions;

  return (
    <Component
      onRequestTermination={onRequestTermination}
      setAlert={setAlert}
    />
  );
}
