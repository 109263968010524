import { Button, Notice, Spinner } from "@justworkshr/milo-core";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { Formik } from "formik";
import {
  AutoFormField,
  generateFormValidations,
} from "pages/employer-of-record/components/AutoForm";
import styles from "./InviteUnderwritingForm.module.css";
import { useTranslation } from "react-i18next";
import { HiringGeography } from "../../types";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useContext } from "react";
import { useUnderwritingQuestions } from "./useUnderwritingQuestions";
import { useSubmitEorCompanyUnderwritingMutation } from "types/generated/operations";
import Tooltip from "@justworkshr/milo-tooltip";

type Props = {
  hiringGeography: HiringGeography;
  onError: (message: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
};

export function InviteUnderwritingForm(props: Props) {
  const { t } = useTranslation();
  const hiringGeographyDisplayName = useDisplayName(props.hiringGeography);
  const underwritingQuestions = useUnderwritingQuestions(props.hiringGeography);
  const [submitCompanyUnderwriting, { loading }] =
    useSubmitEorCompanyUnderwritingMutation();

  if (!underwritingQuestions) return <Spinner />;

  async function onSubmit(values: Record<string, unknown>) {
    try {
      await submitCompanyUnderwriting({
        variables: {
          countryCode: props.hiringGeography.countryCode,
          zoneCode: props.hiringGeography.zoneCode ?? null,
          answers: values,
        },
      });

      props.onSubmit();
    } catch (e) {
      console.error(e);
      props.onError("An unexpected error occurred. Please try again later.");
    }
  }

  const initialValues = Object.fromEntries(
    underwritingQuestions.map((question) => [question.name, ""])
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={generateFormValidations(underwritingQuestions, t)}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles.notice}>
              <Notice title={`Start hiring in ${hiringGeographyDisplayName}`}>
                <div>
                  Since this is your first time hiring in{" "}
                  {hiringGeographyDisplayName}, we need some more information to
                  make sure you can add EOR employees there. If everything
                  checks out, we’ll be in touch to help you set up a{" "}
                  <Tooltip title="A SOW is required for each country in which you hire EOR employees. ">
                    <span className={styles.tooltipped}>
                      Statement of Work (SOW)
                    </span>
                  </Tooltip>
                  .
                </div>
              </Notice>
            </div>

            <div className={styles.fields}>
              {underwritingQuestions.map((fieldData) => (
                <AutoFormField {...fieldData} key={fieldData.name} />
              ))}
            </div>

            <ActionFooter
              actions={[
                <Button
                  key="cancel-btn"
                  variant="ghost"
                  onClick={props.onCancel}
                >
                  {t("Cancel")}
                </Button>,
                <Button key="submit-btn" type="submit" loading={loading}>
                  {t("Next")}
                </Button>,
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

function useDisplayName({ countryCode, zoneCode }: HiringGeography) {
  const { getCountryName, getSubdivisionName } = useContext(
    SupportedCountriesContext
  );
  const countryName = getCountryName(countryCode);

  if (zoneCode) {
    return `${countryName} (${getSubdivisionName(countryCode, zoneCode)})`;
  }

  return countryName;
}
