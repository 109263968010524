import {
  Accordion,
  AccordionPanel,
  Box,
  Divider,
  Row,
  Stack,
} from "@justworkshr/milo-core";
import styles from "./Policies.module.css";
import { Card } from "../../../Card";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatMinutesAsDays, formatMinutesAsHours } from "../../utils";
import { AccrualSummary } from "./components/AccrualSummary/AccrualSummary";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { GetEoREmployeeProfileQuery } from "types/generated/operations";

type Policy = NonNullable<
  GetEoREmployeeProfileQuery["eorEmployeeProfile"]["activePtoPolicies"]
>[number];

const { row, policySubTotal, policyCap } = styles;
const Policies = () => {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const policies = profileInfo?.eorEmployeeProfile.activePtoPolicies ?? [];

  return (
    <Card title="Policies" cardIconName="folder">
      {policies.length > 0 ? (
        <Accordion>
          {policies.map((policy, index) => {
            const balance = policy.balance ?? 0;
            const balanceAtBeginningOfCurrentTimePeriod =
              policy.balanceAtBeginningOfCurrentTimePeriod ?? 0;
            const adjustmentMinutesDuringCurrentTimePeriod =
              policy.adjustmentMinutesDuringCurrentTimePeriod ?? 0;

            const balancePerYear =
              balance -
              balanceAtBeginningOfCurrentTimePeriod +
              policy.approvedMinutesDuringCurrentTimePeriod -
              adjustmentMinutesDuringCurrentTimePeriod;

            const carryoverConfig =
              policy.carryoverMinutes === 0
                ? "Unused days do not carry over."
                : policy.capped && policy.carryoverMinutes
                ? `Carryover cap is ${formatMinutesAsDays(
                    policy.carryoverMinutes,
                    policy.capped
                  )}.`
                : "There is no carryover cap.";

            const accrualConfig =
              policy.capped && policy.accrualCapMinutes
                ? `Accrual cap is ${formatMinutesAsDays(
                    policy.accrualCapMinutes,
                    policy.capped
                  )}.`
                : "There is no accrual cap.";

            return (
              <AccordionPanel
                key={policy.uuid}
                title={policy.name}
                isOpen={index === 0}
              >
                <Stack>
                  <Box backgroundColor="neutralSubtle" padding="lg">
                    <h3>{t("Time Off Overview")}</h3>
                    <Divider />
                    <Row className={row}>
                      <Stack spacing="0">
                        <strong>{t("Upcoming")}</strong>
                        <span>
                          {formatMinutesAsDays(policy.approvedUpcomingMinutes)}
                        </span>
                        <span className={policySubTotal}>
                          {formatMinutesAsHours(policy.approvedUpcomingMinutes)}
                        </span>
                      </Stack>
                      <Stack spacing="0">
                        <strong>{t("Used")}</strong>
                        <span>
                          {formatMinutesAsDays(
                            policy.approvedMinutesDuringCurrentTimePeriod,
                            policy.capped
                          )}
                        </span>
                        <span className={policySubTotal}>
                          {formatMinutesAsHours(
                            policy.approvedMinutesDuringCurrentTimePeriod
                          )}
                        </span>
                      </Stack>
                      <Stack spacing="0">
                        <strong>{t("Balance")}</strong>
                        <strong>
                          {formatMinutesAsDays(balance, policy.capped)}
                        </strong>
                        {policy.capped && (
                          <span className={policySubTotal}>
                            {formatMinutesAsHours(balance)}
                          </span>
                        )}
                      </Stack>
                    </Row>
                  </Box>
                  <Box backgroundColor="neutralSubtle" padding="lg">
                    <h3>{t("Accrual & Balance Details")}</h3>
                    <Divider />
                    <Stack>
                      <AccrualSummary policy={policy} />
                      <Box>
                        <Stack spacing="0" className={policyCap}>
                          <span>{carryoverConfig}</span>
                          <span>{accrualConfig}</span>
                        </Stack>
                      </Box>
                      <Box>
                        <Row className={row}>
                          <span className={policySubTotal}>
                            {`Since ${moment(
                              policy.currentTimePeriodStartDate
                            ).format("MMM D, Y")}`}
                          </span>
                        </Row>
                        <Row className={row}>
                          <span>{t("Starting balance")}</span>
                          <span>
                            {formatMinutesAsDays(
                              balanceAtBeginningOfCurrentTimePeriod,
                              policy.capped
                            )}
                          </span>
                        </Row>
                        <Row className={row}>
                          <span>
                            {policy.capped &&
                            ["daily", "hourly"].includes(policy.awardMethod)
                              ? "Accrued"
                              : "Granted"}
                          </span>
                          <span>
                            {formatMinutesAsDays(balancePerYear, policy.capped)}
                          </span>
                        </Row>
                        <Row className={row}>
                          <span>{t("Approved requests")}</span>
                          <span>
                            {policy.approvedMinutesDuringCurrentTimePeriod
                              ? "-"
                              : ""}
                            {formatMinutesAsDays(
                              policy.approvedMinutesDuringCurrentTimePeriod,
                              policy.capped
                            )}
                          </span>
                        </Row>
                        {policy.capped && (
                          <Row className={row}>
                            <span>{t("Adjustments")}</span>
                            <span>
                              {formatMinutesAsDays(
                                adjustmentMinutesDuringCurrentTimePeriod
                              )}
                            </span>
                          </Row>
                        )}
                      </Box>
                      <Box>
                        <Row className={row}>
                          <strong>{t("Current balance")}</strong>
                          <strong>
                            {formatMinutesAsDays(balance, policy.capped)}
                          </strong>
                        </Row>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </AccordionPanel>
            );
          })}
        </Accordion>
      ) : (
        <Box backgroundColor="neutralSubtle" padding="lg">
          No policies assigned to this employee
        </Box>
      )}
    </Card>
  );
};

export { Policies, type Policy };
