import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import {
  EmployeePayType,
  FormPayTypeValue,
} from "pages/employer-of-record/payments/constants";
import * as CA_BONUS_CONFIGS from "./BonusPay/countryConfigs/CA/constants";
import * as CA_COMMISSION_CONFIGS from "./Comission/countryConfigs/CA/constants";
import * as CO_BONUS_CONFIGS from "./BonusPay/countryConfigs/CO/constants";
import * as IE_BONUS_CONFIGS from "./BonusPay/countryConfigs/IE/constants";
import * as PH_BONUS_CONFIGS from "./BonusPay/countryConfigs/PH/constants";
import * as DEFAULT_BONUS_CONFIGS from "./BonusPay/countryConfigs/DEFAULT/constants";
import * as DEFAULT_COMMISSION_CONFIGS from "./Comission/countryConfigs/DEFAULT/constants";
import * as DEFAULT_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/DEFAULT/constants";
import * as DEFAULT_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/DEFAULT/constants";

import { CountryConfigKeyType } from "./types";

const defaultConfigs = {
  [EmployeePayType.BONUS]: DEFAULT_BONUS_CONFIGS,
  [EmployeePayType.COMISSION]: DEFAULT_COMMISSION_CONFIGS,
  [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_CONFIGS,
  [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_CONFIGS,
};

const CONFIG_MAPS = {
  [CountryCode.AU]: defaultConfigs,
  [CountryCode.BR]: defaultConfigs,
  [CountryCode.CA]: {
    [EmployeePayType.BONUS]: CA_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: CA_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_CONFIGS,
  },
  [CountryCode.CL]: defaultConfigs,
  [CountryCode.CO]: {
    [EmployeePayType.BONUS]: CO_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: DEFAULT_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_CONFIGS,
  },
  [CountryCode.CR]: defaultConfigs,
  [CountryCode.DK]: defaultConfigs,
  [CountryCode.ES]: defaultConfigs,
  [CountryCode.GB]: defaultConfigs,
  [CountryCode.IE]: {
    [EmployeePayType.BONUS]: IE_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: DEFAULT_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_CONFIGS,
  },
  [CountryCode.IN]: defaultConfigs,
  [CountryCode.MX]: defaultConfigs,
  [CountryCode.MY]: defaultConfigs,
  [CountryCode.NL]: defaultConfigs,
  [CountryCode.PT]: defaultConfigs,
  [CountryCode.PH]: {
    [EmployeePayType.BONUS]: PH_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: DEFAULT_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: DEFAULT_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: DEFAULT_RETROACTIVE_CONFIGS,
  },
  [CountryCode.SE]: defaultConfigs,
  [CountryCode.SG]: defaultConfigs,
} as const;

export const loadCountryConfigFiles = (
  type: FormPayTypeValue,
  country: CountryConfigKeyType
) => {
  const configs =
    CONFIG_MAPS[country][
      type as keyof (typeof CONFIG_MAPS)[CountryConfigKeyType]
    ];
  return configs;
};
