import {
  FormField,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ConfigType, SchemaType } from "./config";

export default function FormFragment() {
  const { t } = useTranslation();
  const { errors, touched, handleChange, handleBlur, values, setFieldValue } =
    useFormikContext<SchemaType>();

  const handleGroceryAllowanceCardOnChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("mxMonthlyGroceryAllowance", null);
    return handleChange(event);
  };

  const fieldErrorHandler = (name: keyof ConfigType["initialValues"]) =>
    errors[name] && touched[name] ? errors[name] : undefined;

  return (
    <>
      <FormField
        required
        name="mxProbationPeriodDays"
        label={t("Probation Period")}
        error={fieldErrorHandler("mxProbationPeriodDays")}
      >
        <Select
          name="mxProbationPeriodDays"
          placeholder={t("Select probation period days")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.mxProbationPeriodDays}
        >
          <SelectOption value="0">{t("None")}</SelectOption>
          <SelectOption value="30">{t("30 days")}</SelectOption>
        </Select>
      </FormField>
      <FormField
        required
        name="mxChristmasBonusDays"
        label={t("Christmas Bonus Days")}
        error={fieldErrorHandler("mxChristmasBonusDays")}
        message={t(
          `In addition to regular pay and benefits, all employees in Mexico must be paid an Aguinaldo equal to at least 15 days of their regular wages before December 20.`
        )}
      >
        <TextInput
          required
          type="number"
          name="mxChristmasBonusDays"
          onChange={handleChange}
          onBlur={handleBlur}
          value={String(values.mxChristmasBonusDays)}
        />
      </FormField>
      <FormField
        required
        name="mxVacationPremiumPercentage"
        label={t("Vacation Premium %")}
        error={fieldErrorHandler("mxVacationPremiumPercentage")}
        message={t(
          "In Mexico, employees earn at least an additional 25% during vacation."
        )}
      >
        <TextInput
          type="number"
          required
          name="mxVacationPremiumPercentage"
          onChange={handleChange}
          onBlur={handleBlur}
          value={String(values.mxVacationPremiumPercentage)}
        />
      </FormField>
      <FormField
        required
        name="mxVacationDays"
        label={t("Vacation Days")}
        error={fieldErrorHandler("mxVacationDays")}
      >
        <TextInput
          required
          type="number"
          name="mxVacationDays"
          onChange={handleChange}
          onBlur={handleBlur}
          value={String(values.mxVacationDays)}
        />
      </FormField>
      <FormField
        name="mxHasGroceryAllowanceCard"
        required
        label={t("Grocery Allowance Card")}
        error={fieldErrorHandler("mxHasGroceryAllowanceCard")}
        message={t(
          "Grocery allowance cards are a common benefit in Mexico, but are not technically required."
        )}
      >
        <RadioButtonGroup
          name="mxHasGroceryAllowanceCard"
          onChange={handleGroceryAllowanceCardOnChange}
          onBlur={handleBlur}
          value={values.mxHasGroceryAllowanceCard}
        >
          <RadioButton name="yes" value="true" label={t("Yes")} />
          <RadioButton name="no" value="false" label={t("No")} />
        </RadioButtonGroup>
      </FormField>
      {values.mxHasGroceryAllowanceCard === "true" && (
        <FormField
          required
          name="mxMonthlyGroceryAllowance"
          label={t("Monthly Grocery Allowance (MXN)")}
          error={fieldErrorHandler("mxMonthlyGroceryAllowance")}
        >
          <TextInput
            required
            type="number"
            name="mxMonthlyGroceryAllowance"
            onChange={handleChange}
            onBlur={handleBlur}
            value={String(values.mxMonthlyGroceryAllowance)}
          />
        </FormField>
      )}
    </>
  );
}
