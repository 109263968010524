import { ReactElement, useContext } from "react";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../Setup/types";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import { SupportedCountriesDataType } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import DateDropdown from "../DateDropdown/DateDropdown";
import DateRangePicker from "../DateRangePicker/DateRangerPicker";

type Props = {
  countryInfo: SupportedCountriesDataType;
  member: EorMembersType;
  hasDatePeriod: boolean;
  hasApplyToAllButton?: boolean;
  applyToAll?: (fields: string[]) => void;
  hasLabels?: boolean;
};

export const PayDateComponent = ({
  member,
  hasDatePeriod,
  hasApplyToAllButton,
  applyToAll,
  hasLabels = false,
}: Props): ReactElement => {
  const formik = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { values, touched, errors } = formik;
  const additionalPayContext = useContext(AdditionalPayContext);
  const index = values.paymentDetails.findIndex(
    (paymentDetail) => paymentDetail.memberId === member.id
  );

  const touchedRow: FormikTouched<PaymentDetailsType> | null =
    touched.paymentDetails && touched.paymentDetails[index]
      ? touched.paymentDetails[index]
      : null;

  const errorRow: FormikErrors<PaymentDetailsType> | null =
    errors.paymentDetails && errors.paymentDetails[index]
      ? (errors.paymentDetails[index] as PaymentDetailsType)
      : null;

  const getUpdatedMemberDetail = (
    name: string,
    value: string,
    index: number
  ) => {
    const paymentDetails = [...values.paymentDetails];
    paymentDetails[index] = {
      ...paymentDetails[index],
      [name]: value,
    };
    return paymentDetails;
  };

  const props = {
    index: index,
    member: member,
    errorRow: errorRow,
    hasApplyToAllButton: hasApplyToAllButton,
    touchedRow: touchedRow,
    applyToAll: applyToAll,
    hasLabels: hasLabels,
  };

  if (!hasDatePeriod) {
    return <></>;
  }

  return (
    <>
      {values.setUp.type === EmployeePayType.OFF_CYCLE ? (
        <DateDropdown
          {...props}
          pastPayPeriodOptions={additionalPayContext?.pastPayPeriodOptions}
        />
      ) : (
        <DateRangePicker
          {...props}
          getUpdatedMemberDetail={getUpdatedMemberDetail}
        />
      )}
    </>
  );
};
