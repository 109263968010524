import { DataType } from "pages/employer-of-record/components/AutoForm";
import Row from "../Row/Row";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import {
  initialValues,
  ProfileField,
  useFields,
} from "pages/employer-of-record/configs";
import { shouldRequire } from "pages/employer-of-record/components/AutoForm/AutoFormField";

type Props = ProfileField;

export default function ProfileInfoRow(props: Props) {
  const value = useFormattedValue(props);
  const fields = useFields({ scenario: "viewProfile" });
  const { profileInfo } = useProfileContextData();

  const values = initialValues(fields, profileInfo!.eorEmployeeProfile!);

  return (
    <Row
      label={props.label ?? props.name}
      required={shouldRequire(props, values)}
    >
      {value}
    </Row>
  );
}

function isEmpty(value: DataType): boolean {
  if (Array.isArray(value) || typeof value === "string") {
    return value.length === 0;
  }

  return false;
}

function useFormattedValue(field: ProfileField) {
  const { profileInfo } = useProfileContextData();
  const valueOrValues = field.value(profileInfo!.eorEmployeeProfile!);

  if (isEmpty(valueOrValues)) return null;

  const formattedValues = [];
  const values = Array.isArray(valueOrValues) ? valueOrValues : [valueOrValues];

  for (const value of values) {
    if (typeof value === "string") {
      const formattedValue = field.format?.(value) ?? value;
      formattedValues.push(<div>{formattedValue}</div>);
    } else if (typeof value === "boolean") {
      const formattedValue = field.format?.(value.toString()) ?? value;
      formattedValues.push(<div>{formattedValue}</div>);
    }
  }

  return formattedValues;
}
