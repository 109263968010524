import { ReactElement, useContext } from "react";
import { Card } from "@justworkshr/milo-core";
import { SetupFormGeneratorProps } from "./types";
import {
  AdditionalPayFormData,
  FieldValue,
  FormPayloadType,
} from "../../types";
import { useFormikContext } from "formik";
import FieldFactory from "./components/FieldFactory";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";

export function SetupFormGenerator<T extends FormPayloadType>({
  title,
  fields,
  formikFieldName,
}: SetupFormGeneratorProps<T>): ReactElement {
  const formik = useFormikContext<AdditionalPayFormData<T>>();
  const additionalPayContext = useContext(AdditionalPayContext);
  const width = additionalPayContext?.isEdit ? "100%" : "50%";

  const handleFieldsChanges = (
    changes: {
      fieldName: string;
      value: FieldValue;
    }[]
  ) => {
    const valuesObj = { ...formik.values };

    changes.forEach(({ fieldName, value }) => {
      valuesObj.setUp.payload[formikFieldName as keyof T][
        fieldName as keyof T[keyof T]
      ] = value as T[keyof T][keyof T[keyof T]];
    });

    formik.setValues(valuesObj);
  };

  const props = {
    formikFieldName,
    formik,
    handleFieldsChanges,
  };

  const generateFields = () => {
    return (
      <>
        {fields.map((field) => (
          <div key={field.name} style={{ width }}>
            <FieldFactory {...{ field, ...props }} />
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {!additionalPayContext?.isEdit ? (
        <Card title={title}>{generateFields()}</Card>
      ) : (
        generateFields()
      )}
    </>
  );
}
