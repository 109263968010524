import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { countryIs, field, personalInfo, REQUIRED } from "./utils";

export const colombiaFields = {
  coCedulaDeCiudadania: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "coCedulaDeCiudadania",
    label: "Cédula de Ciudadanía Number (National ID number)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("CO"),
      onboarding: countryIs("CO"),
    },
    value: personalInfo("coCedulaDeCiudadania", ""),
  }),
  coNombreEps: field({
    type: FieldTypes.text,
    informationType: "pension",
    name: "coNombreEps",
    label: "Nombre de EPS (EPS fund name)",
    scenarios: {
      viewProfile: countryIs("CO"),
    },
    value: personalInfo("coNombreEps", ""),
  }),
  coNombreFondoDePensiones: field({
    type: FieldTypes.text,
    informationType: "pension",
    name: "coNombreFondoDePensiones",
    label: "Fondo de Pensiones (Pension fund name)",
    scenarios: {
      viewProfile: countryIs("CO"),
    },
    value: personalInfo("coNombreFondoDePensiones", ""),
  }),
  coNombreArl: field({
    type: FieldTypes.text,
    informationType: "pension",
    name: "coNombreArl",
    label: "Nombre de ARL (Severance fund name)",
    scenarios: {
      viewProfile: countryIs("CO"),
    },
    value: personalInfo("coNombreArl", ""),
  }),
};
