import {
  Field,
  useGetUnderwritingQuestionsQuery,
} from "types/generated/operations";
import { HiringGeography } from "../../types";
import {
  FieldDataType,
  FieldTypes,
} from "pages/employer-of-record/components/AutoForm";

type FormField = NonNullable<
  ReturnType<typeof useGetUnderwritingQuestionsQuery>["data"]
>["underwritingQuestions"][number];

function toFieldDataType(formField: FormField): FieldDataType {
  return {
    ...formField,
    validations: {
      ...formField.validations,
      text: {
        email: {
          enabled: formField.validations.text?.email?.enabled ?? false,
          message: formField.validations.text?.email?.message ?? "",
        },
      },
    },
    placeholder: formField.placeholder ?? undefined,
    type: toFieldType(formField.type),
  };
}

function toFieldType(field: Field): FieldTypes {
  switch (field) {
    case Field.Boolean:
      return FieldTypes.boolean;
    case Field.Select:
      return FieldTypes.select;
    case Field.MultiSelect:
      return FieldTypes.multiSelect;
    case Field.Text:
    default:
      return FieldTypes.text;
  }
}

export function useUnderwritingQuestions(hiringGeography: HiringGeography) {
  const { data } = useGetUnderwritingQuestionsQuery({
    variables: {
      countryCode: hiringGeography.countryCode,
      zoneCode: hiringGeography.zoneCode ?? null,
    },
  });

  return data?.underwritingQuestions.map(toFieldDataType);
}
