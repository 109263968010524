import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SUBPATHS } from "./constants";
import { AdditionalPayContextProvider } from "./contexts/additionalPayContext";
import { MemberDataProvider } from "./contexts/memberDataContext";
import { Setup } from "./components/Setup";
import { PaymentDetails } from "./components/PaymentDetails";
import { Review } from "./components/Review";
import { Payments } from "./Payments";
import { useGetAdminCapabilitiesQuery } from "types/generated/operations";
import { Page404 } from "pages/page-404";
import { Spinner } from "@justworkshr/milo-core";
import styles from "./Payments.module.css";

const AdditionalPayments = (): ReactElement => {
  const { data, loading } = useGetAdminCapabilitiesQuery();
  const capabilities = data?.authenticatedMember?.capabilities;
  if (loading) {
    return (
      <div className={styles.layout}>
        <div className={styles.spinnerWrapper}>
          <Spinner data-testid="spinner" size="extraLarge" />
        </div>
      </div>
    );
  }

  return (
    <AdditionalPayContextProvider>
      <MemberDataProvider>
        <Routes>
          <Route
            path="/"
            element={
              capabilities?.hasAccessToPaymentCenter ? (
                <Payments />
              ) : (
                <Page404 />
              )
            }
          >
            <Route path={`/${SUBPATHS.SET_UP}`} element={<Setup />} />
            <Route
              path={`/${SUBPATHS.PAYMENT_DETAILS}`}
              element={<PaymentDetails />}
            />
            <Route path={`/${SUBPATHS.REVIEW}`} element={<Review />} />
            <Route
              path="/"
              element={<Navigate to={SUBPATHS.SET_UP} replace />}
            />
          </Route>
        </Routes>
      </MemberDataProvider>
    </AdditionalPayContextProvider>
  );
};

export default AdditionalPayments;
