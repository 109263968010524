import { useContext, useEffect } from "react";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { useFormikContext } from "formik";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useMemberData } from "pages/employer-of-record/payments/contexts/memberDataContext";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../types";

function useCountryConfigFetcher<
  T extends FormPayloadType = FormPayloadType
>(): void {
  const { setFieldValue, values } =
    useFormikContext<AdditionalPayFormData<T>>();
  const { getCurrency } = useContext(SupportedCountriesContext);
  const additionalPayContext = useContext(AdditionalPayContext);
  const memberDataContext = useMemberData();

  useEffect(() => {
    const formikMemberDetails: Array<PaymentDetailsType> = [];
    const validationMemberData: Array<
      Pick<EorMembersType, "id" | "workStartDate" | "workEndDate">
    > = [];
    memberDataContext?.membersData?.forEach((data: EorMembersType) => {
      const memberFormData = {
        memberId: data.id,
        grossAmount: "",
        currency: getCurrency(additionalPayContext?.selectedCountry)
          .currencyCode,
        workPeriodStart: "",
        workPeriodEnd: "",
      };
      formikMemberDetails.push(memberFormData);
      validationMemberData.push({
        id: data.id,
        workStartDate: data.workStartDate,
        workEndDate: data.workEndDate,
      });
    });

    setFieldValue("paymentDetails", formikMemberDetails);
    setFieldValue("validationData.members", validationMemberData);
  }, [
    memberDataContext?.membersData,
    setFieldValue,
    additionalPayContext?.selectedCountry,
    values.paymentDetails.length,
    getCurrency,
  ]);
}

export default useCountryConfigFetcher;
