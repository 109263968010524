import { FieldConfig } from "../../../../SetupFormGenerator";
import { COMPONENTLIST } from "../../../../SetupFormGenerator/constants";
import { BonusPayloadType } from "../../../types";
import { BonusType, NonDiscretionaryType } from "../../constants";

export const PAYMENT_SETTINGS_FORM_FIELDS: FieldConfig[] = [
  {
    label: "Pay date",
    name: "payPeriodId",
    type: COMPONENTLIST.SELECT,
    options: [{ value: "", label: "" }],
    required: true,
  },
  {
    label: "Delivery method",
    name: "deliveryMethod",
    type: COMPONENTLIST.RADIO,
    options: [
      {
        value: "electronic",
        label: "Electronic",
        description: "Funds will be delivered by direct deposit.",
      },
    ],
    required: true,
    message: {
      text: "We can only process electronic payments. To record a cash payment, reach out to <strong>international-support@justworks.com</strong>",
    },
  },
  {
    label: "Internal Notes",
    name: "notes",
    type: COMPONENTLIST.TEXTAREA,
    required: false,
    message: { text: "Only visible to admins" },
  },
];

export const TAX_FORM_FIELDS: FieldConfig<BonusPayloadType>[] = [
  {
    label: "Bonus Type",
    name: "bonusType",
    type: COMPONENTLIST.RADIO,
    options: [
      {
        value: BonusType.DISCRETIONARY,
        label: "Discretionary",
        description:
          "A bonus that is not announced, is unexpected, and is more like a gift.",
      },
      {
        value: BonusType.NONDISCRETIONARY,
        label: "Non-discretionary",
        description:
          "A bonus that’s required because of an agreement or contract.",
      },
    ],
    required: true,
    subFields: [
      {
        label: "Non-discretionary type",
        name: "nonDiscretionaryType",
        required: true,
        type: COMPONENTLIST.RADIO,
        renderCheck: (data) =>
          data?.setUp.payload.taxes.bonusType === BonusType.NONDISCRETIONARY,
        options: [
          {
            value: NonDiscretionaryType.PERFORMANCE,
            label: "Performance",
            description: "For achieving pre-set individual or company goals.",
          },
          {
            value: NonDiscretionaryType.TIME,
            label: "Time",
            description:
              "For time milestones like quarterly or for a work anniversary.",
          },
        ],
      },
      {
        label: "Reason",
        name: "reason",
        type: COMPONENTLIST.TEXTAREA,
        required: true,
        message: {
          text: "Explain why this bonus is being given.",
        },
        renderCheck: (data) => {
          return (
            data?.setUp.payload.taxes.nonDiscretionaryType ==
            NonDiscretionaryType.PERFORMANCE
          );
        },
      },
    ],
  },
];
