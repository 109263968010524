import { FC } from "react";
import useOnboardingForm from "../../hooks/useOnboardingForm";
import Layout from "../../Layout";
import { useInScopeMemberData } from "pages/employer-of-record/configs/hooks/useInScopeMemberData";
import ProfileInfoContent from "./ProfileInfoContent/ProfileInfoContent";

const state_key = "profile-info";

const ProfileInfo: FC = () => {
  const memberDataScope = useInScopeMemberData();
  const {
    loading: contextIsLoading,
    memberOnboarding: {
      stepNumber: actualStepNumber,
      redirecting,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const profile = memberDataScope?.eorEmployeeProfile;
  const loading = contextIsLoading || redirecting || !profile;
  const stepNumber = loading ? 0 : actualStepNumber;

  return (
    <Layout
      step={stepNumber}
      loading={loading}
      idVerificationStatus={idVerificationStatus}
    >
      {/* Don't render the form to the view until we know what fields are going to be on the form.
      We know what fields will be on the form once we see the EOR EE profile.
      This ensures that all validations and initial values are correctly setup before rendering the form. */}
      {!!profile && <ProfileInfoContent profile={profile} />}
    </Layout>
  );
};

export default ProfileInfo;
