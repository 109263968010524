import { getInitialLanguage } from "../components/LanguagePicker/LanguagePicker";

// Converts a number to the specified currency as a string.
// If a null number value is passed, an empty string is returned.
export function toCurrencyFormat(
  number: number | null,
  currency: string,
  displayCurrencyCode = false,
  isSmallestUnit: boolean = true,
  symbolOverride: string | null = null
) {
  if (number == null) return "";
  const currencyCodeSuffix = displayCurrencyCode ? ` ${currency}` : "";
  const currencyDisplay = symbolOverride ? "code" : "narrowSymbol";

  if (isSmallestUnit) {
    const resolverOptions = Intl.NumberFormat(getInitialLanguage(), {
      style: "currency",
      currency: currency,
      currencyDisplay: currencyDisplay,
    }).resolvedOptions();

    //for maximumFractionDigits === 0 do nothing as we're already in the smallest unit
    if (resolverOptions.maximumFractionDigits === 2) {
      number = number / 100;
    } else if (resolverOptions.maximumFractionDigits === 3) {
      number = number / 1000;
    }
  }

  let result = `${new Intl.NumberFormat(getInitialLanguage(), {
    style: "currency",
    currency: currency,
    currencyDisplay: currencyDisplay,
  }).format(number)}${currencyCodeSuffix}`;
  if (symbolOverride) {
    result = result.replace(currency, symbolOverride);
  }
  return result;
}
/* Filter out null and undefined values from the data object.
 *
 * This is necessary because the API will return null values for fields that
 * are not required, but the formik form will require empty strings for those.
 * This will remove all those values and allow the initial values to fill the
 * gaps for data we don't have yet.
 */
export const cleanData = <T extends Record<string, unknown>>(
  data: Record<string, unknown> = {},
  placeholderData: Record<string, unknown> = {}
) => {
  const cleanedData = Object.entries(data)
    // filter out null and undefined values
    .filter(
      ([key, value]) =>
        key !== "__typename" && value !== undefined && value !== null
    )
    // recursively clean nested objects and create a new object with the cleaned values.
    .reduce<Record<string, unknown>>((obj, [key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          obj[key] = value.map((item) =>
            item instanceof Object ? cleanData(item) : item
          );
        }
      } else if (value instanceof Object) {
        obj[key] = cleanData(
          value as Record<string, unknown>,
          placeholderData[key] as Record<string, unknown>
        );
      } else {
        obj[key] = value;
      }

      return obj;
    }, {}) as T;

  return {
    ...placeholderData,
    ...cleanedData,
  };
};

export function getDescription(
  from: readonly { value: string; description: string }[],
  targetValue: string | null | undefined
) {
  return from.find(({ value }) => value === targetValue)?.description;
}
