import { FieldTypes } from "pages/employer-of-record/components/AutoForm";
import { getDescription } from "pages/employer-of-record/utils";
import * as SPAIN from "pages/employer-of-record/onboarding/profile-info/form/es/constants.es";
import { array, countryIs, field, personalInfo, REQUIRED } from "./utils";
import {
  confirmIdentifierNumberFieldConfig,
  identifierNumberFieldConfig,
} from "./_common";

export const spainFields = {
  esSex: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "esSex",
    label: "Sex",
    placeholder: "Select Sex",
    message:
      "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences.",
    validations: REQUIRED,
    options: SPAIN.esSexOptions,
    scenarios: {
      onboarding: countryIs("ES"),
    },
    value: () => "", // TODO: IEORN-3827 expose esSex in ProfileInfo query
    format: (value) => getDescription(SPAIN.esSexOptions, value) ?? value,
  }),
  esDateSinceResidentOfSpain: field({
    type: FieldTypes.date,
    informationType: "identification",
    name: "esDateSinceResidentOfSpain",
    label: "Date since resident of Spain",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("ES"),
    },
    value: () => "", // TODO: IEORN-3827 expose esDateSinceResidentOfSpain in ProfileInfo query
  }),
  esUnemploymentStatus: field({
    type: FieldTypes.boolean,
    informationType: "identification",
    name: "esUnemploymentStatus",
    label: "Unemployment status (Desempleado)",
    validations: REQUIRED,
    scenarios: {
      onboarding: countryIs("ES"),
    },
    value: () => false, // TODO: IEORN-3827 expose esUnemploymentStatus in ProfileInfo query
  }),
  esMaritalStatus: field({
    type: FieldTypes.select,
    informationType: "identification",
    name: "esMaritalStatus",
    label: "Marital Status",
    validations: REQUIRED,
    placeholder: "Select Marital Status",
    options: SPAIN.esMaritalStatusOptions,
    scenarios: {
      editProfile: countryIs("ES"),
      viewProfile: countryIs("ES"),
      onboarding: countryIs("ES"),
    },
    value: personalInfo("esMaritalStatus", ""),
    format: (value) =>
      getDescription(SPAIN.esMaritalStatusOptions, value) ?? value,
  }),
  esEducationalQualification: field({
    type: FieldTypes.select,
    informationType: "education",
    name: "esEducationalQualification",
    label: "Educational Qualification",
    validations: REQUIRED,
    placeholder: "Select Educational Qualification",
    options: SPAIN.esEducationalQualificationOptions,
    scenarios: {
      editProfile: (profile) => profile.employment?.workCountry === "ES",
      viewProfile: countryIs("ES"),
      onboarding: countryIs("ES"),
    },
    value: personalInfo("esEducationalQualification", ""),
    format: (value) =>
      getDescription(SPAIN.esEducationalQualificationOptions, value) ?? value,
  }),
  esChildrensBirthDates: array({
    type: FieldTypes.date,
    informationType: "family",
    array: {
      limit: 10,
    },
    name: "esChildrensBirthDates",
    label: "Children's birth dates",
    scenarios: {
      editProfile: (profile) => profile.employment?.workCountry === "ES",
      viewProfile: (profile) => profile.employment?.workCountry === "ES",
      onboarding: countryIs("ES"),
    },
    value: personalInfo("esChildrensBirthDates", []),
  }),
  esSsn: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "esSsn",
    label: "Social Security ID (Número de afiliación)",
    validations: REQUIRED,
    scenarios: {
      viewProfile: countryIs("ES"),
      onboarding: countryIs("ES"),
    },
    value: personalInfo("esSsn", ""),
  }),
  esNie: field({
    type: FieldTypes.text,
    informationType: "identification",
    name: "esNie",
    label: "Número de Identificación de Extranjero (NIE)",
    validations: {
      required: {
        when: {
          nationality: (value) => value !== "ES",
        },
      },
    },
    scenarios: {
      viewProfile: countryIs("ES"),
      onboarding: countryIs("ES"),
    },
    value: personalInfo("esNie", ""),
  }),
  esDni: identifierNumberFieldConfig("ES"),
  esConfirmDni: confirmIdentifierNumberFieldConfig("ES"),
};
