import React, { useState } from "react";
import {
  TextInput,
  Select,
  SelectOption,
  SingleDatePicker,
} from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { formatToUSDate } from "pages/employer-of-record/utils";
import styles from "./MyChildrenInformationInput.module.css";
import { useFormikContext } from "formik";

type Props = {
  name: string;
  value: {
    name: string;
    icNumber: string;
    gender: string;
    dateOfBirth: string;
    educationLevel: string;
    taxClaim: string;
  };
  onChange: (e: React.ChangeEvent) => void;
  onBlur: (e: React.FocusEvent) => void;
};

const maxDOB = new Date();
const minDOB = new Date("1920-01-01");

const MyChildrenInformationInput = ({
  onBlur,
  onChange,
  value,
  name,
}: Props) => {
  const { t } = useTranslation();
  const [_localValue, setLocalValue] = useState(value.dateOfBirth);
  const { setFieldValue } = useFormikContext();

  const handleDateChange = (date: Date) => {
    const formattedDate = formatToUSDate(date);
    setLocalValue(formattedDate);
    setFieldValue(`${name}.dateOfBirth`, formattedDate);
  };

  return (
    <div>
      <h4>{t("Child Information")}</h4>

      <div className={styles.childrenValueInput}>
        <TextInput
          name={`${name}.name`}
          value={value.name}
          onChange={onChange}
          onBlur={onBlur}
          required
          placeholder={t("Enter Child's Name")}
        />
      </div>
      <div className={styles.childrenValueInput}>
        <TextInput
          name={`${name}.icNumber`}
          value={value.icNumber}
          onChange={onChange}
          onBlur={onBlur}
          required
          placeholder={t("Enter Child's IC Number")}
        />
      </div>
      <div className={styles.childrenValueInput}>
        <Select
          name={`${name}.gender`}
          onChange={onChange}
          onBlur={onBlur}
          value={value.gender}
          required
          placeholder={t("Gender")}
        >
          <SelectOption value="male" key="male">
            {t("Male")}
          </SelectOption>
          <SelectOption value="female" key="female">
            {t("Female")}
          </SelectOption>
          <SelectOption value="other" key="other">
            {t("Other")}
          </SelectOption>
        </Select>
      </div>
      <div className={styles.childrenValueInput}>
        <SingleDatePicker
          key={name}
          name={`${name}.dateOfBirth`}
          onChange={handleDateChange}
          maxDate={maxDOB}
          minDate={minDOB}
          value={value.dateOfBirth}
          data-testid={name}
        />
      </div>
      <div className={styles.childrenValueInput}>
        <TextInput
          name={`${name}.educationLevel`}
          value={value.educationLevel}
          onChange={onChange}
          onBlur={onBlur}
          required
          placeholder={t("Enter Child's Education Level")}
        />
      </div>
      <div className={styles.childrenValueInput}>
        <Select
          name={`${name}.taxClaim`}
          onChange={onChange}
          onBlur={onBlur}
          value={value.taxClaim}
          required
          placeholder={t("Tax Claim")}
        >
          <SelectOption value="0" key="0">
            {t("0%")}
          </SelectOption>
          <SelectOption value="50" key="50">
            {t("50%")}
          </SelectOption>
          <SelectOption value="100" key="100">
            {t("100%")}
          </SelectOption>
        </Select>
      </div>
    </div>
  );
};

export default MyChildrenInformationInput;
