import { BenefitsAndAllowancesPayloadType } from "./types";

export const BENEFITS_AND_ALLOWANCES_PAYLOAD_INITIAL_VALUES: BenefitsAndAllowancesPayloadType =
  {
    paymentSettings: {
      payPeriodId: "",
      disbursementDate: "",
      formOfIncome: "",
      notes: "",
      benefitType: "",
    },
  };
