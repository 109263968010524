import { ReactElement, useState } from "react";
import { Card } from "../../../Card";
import { DisplayIcon } from "@justworkshr/milo-icons";
import { Box, Stack } from "@justworkshr/milo-core";
import { CheckboxInput } from "@justworkshr/milo-form";
import { RequestItem } from "../RequestItem/RequestItem";
import styles from "./History.module.css";
import { usePtoEventHistory } from "./usePtoEventHistory";

export default function History(): ReactElement {
  const [showRequests, setShowRequests] = useState(true);
  const [showAdjustments, setShowAdjustments] = useState(true);

  const { createCombinedPtoEventList } = usePtoEventHistory();

  const NothingSelected = () => {
    return (
      <Box backgroundColor="neutralSubtle" padding="md">
        View past requests and adjustments by selecting an option above
      </Box>
    );
  };

  const ShowFilteredListOrNothingMessage = () => {
    const subdata = createCombinedPtoEventList().filter(
      (item) =>
        (showRequests && !item.isAdjustment) ||
        (showAdjustments && item.isAdjustment)
    );

    if (subdata.length > 0) {
      return (
        <>
          {subdata.map((item) => (
            <RequestItem key={item.uuid} {...item} />
          ))}
        </>
      );
    } else {
      return (
        <Box backgroundColor="neutralSubtle" padding="md">
          No records found
        </Box>
      );
    }
  };

  return (
    <>
      <Card
        title="History"
        cardIcon={<DisplayIcon iconName="calendar-with-clock" />}
      >
        <span className={styles.selectShown}>
          <span>Show: </span>
          <CheckboxInput
            label="Requests"
            name="Requests"
            value="requests"
            className={styles.checkbox}
            checked={showRequests}
            onChange={() => setShowRequests(!showRequests)}
          />
          <CheckboxInput
            label="Adjustments"
            name="Adjustments"
            value="adjustments"
            className={styles.checkbox}
            checked={showAdjustments}
            onChange={() => setShowAdjustments(!showAdjustments)}
          />
        </span>
        <Stack className={styles.historyList}>
          {showRequests || showAdjustments ? (
            <ShowFilteredListOrNothingMessage />
          ) : (
            <NothingSelected />
          )}
        </Stack>
      </Card>
    </>
  );
}
