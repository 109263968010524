import type { ChangeEvent, ReactElement } from "react";
import { useState } from "react";
import styles from "./SensitiveTextInput.module.css";
import { IconButton } from "@justworkshr/milo-core";
import { TextInput, TextInputProps } from "@justworkshr/milo-form";
import {
  FieldDataType,
  ValidationType,
} from "pages/employer-of-record/components/AutoForm";

const { obfuscatedInputWrapper } = styles;

export type IdentifierInputProps = Omit<TextInputProps, "type" | "onChange"> & {
  validations?: ValidationType;
  format: FieldDataType["format"];
  compact: FieldDataType["compact"];
  onChange: (string: string) => void;
};

export const SensitiveTextInput = ({
  disabled = false,
  required = false,
  value = "",
  name = "",
  onChange,
  ...props
}: IdentifierInputProps): ReactElement => {
  const [showAllInput, setShowAllInput] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.compact) {
      event.target.value = props.compact(event.target.value) as string;
    }

    const maxLength =
      props.validations?.text?.max?.value ??
      props.validations?.text?.length?.value ??
      Infinity;

    if (event.target.value.length <= maxLength) {
      onChange(event.target.value);
    }
  };

  function formattedValue() {
    if (props.format) return props.format(value);
    return value;
  }

  function compactedValue() {
    if (props.compact) return props.compact(value) as string;
    return value;
  }

  function toggleVisibility() {
    setShowAllInput(!showAllInput);
  }

  return (
    <div className={obfuscatedInputWrapper}>
      <TextInput
        {...props}
        name={name}
        value={showAllInput ? formattedValue() : compactedValue()}
        autoComplete={showAllInput ? "new-identifier" : props.autoComplete}
        data-heap-redact-text
        disabled={disabled}
        required={required}
        type={showAllInput ? "text" : "password"}
        onChange={handleChange}
      />
      <IconButton
        color="neutral"
        disabled={disabled}
        iconName={showAllInput ? "eye" : "eye-slash"}
        onClick={toggleVisibility}
        title={
          showAllInput ? `obfuscate ${name} input` : `unobfuscate ${name} input`
        }
        variant="ghost"
      />
    </div>
  );
};
