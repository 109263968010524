import * as Yup from "yup";

export const noEmojiTest = Yup.string()
  .max(500, "Less than 500 characters, please.")
  .test({
    name: "isNotEmojiTest",
    test: (value: string | undefined) => {
      if (!value) return true;
      return !/\p{Extended_Pictographic}/gu.test(value);
    },
    message: "No emoji, sorry 😔",
  });

export const maxFileSize = function (bytes: number) {
  return Yup.mixed().test(
    "file-size-max",
    "File size is too large",
    (value) => {
      const file = value as File;
      return file.size <= bytes;
    }
  );
};

export const supportedFileFormats = function (formats: string[]) {
  return Yup.mixed().test(
    "unsupported-file-format",
    "Unsupported file format",
    (value) => {
      const file = value as File;
      return (
        formats.includes(file.type) ||
        file.type.includes("reimbursement_receipt")
      );
    }
  );
};
