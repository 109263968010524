import styles from "../PaymentDetailsRow/PaymentDetailsRow.module.css";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../Setup/types";
import {
  FieldConfig,
  Option,
} from "../../../Setup/components/SetupFormGenerator";
import { COMPONENTLIST } from "../../../Setup/components/SetupFormGenerator/constants";
import { SelectInput } from "../../../Setup/components/SetupFormGenerator/components/FieldFactory/components";
import { ChangeEvent, ReactElement } from "react";
import { EorMembersType } from "pages/employer-of-record/payments/types";
import ApplyToAllButton from "../ApplyToAllButton/ApplyToAllButton";
import {
  formatDateToISO8601,
  parseISO8601Date,
} from "pages/employer-of-record/components/DatePicker";

type Props = {
  index: number;
  member: EorMembersType;
  pastPayPeriodOptions: Option[] | undefined;
  errorRow: FormikErrors<PaymentDetailsType> | null;
  touchedRow: FormikTouched<PaymentDetailsType> | null;
  hasApplyToAllButton?: boolean;
  applyToAll?: (fields: string[]) => void;
  hasLabels?: boolean;
};

const getValue = (detailRow: PaymentDetailsType) => {
  return detailRow.workPeriodStart && detailRow.workPeriodEnd
    ? `${parseISO8601Date(detailRow.workPeriodStart)} - ${parseISO8601Date(
        detailRow.workPeriodEnd
      )}`
    : "";
};

const DateDropdown = ({
  index,
  pastPayPeriodOptions,
  errorRow,
  touchedRow,
  hasApplyToAllButton,
  applyToAll,
  hasLabels = false,
}: Props): ReactElement => {
  const formik = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { values, setFieldValue } = formik;

  const field: FieldConfig<FormPayloadType> = {
    name: "",
    label: hasLabels ? "Work period" : "_",
    type: COMPONENTLIST.SELECT,
    options: pastPayPeriodOptions,
    required: true,
  };

  const props = {
    elementId: `date-dropdown-${index}`,
    formik: formik,
    handleChange: (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      if (value) {
        const [startDate, endDate] = event.target.value.split(" - ");
        const paymentDetails = [...values.paymentDetails];
        paymentDetails[index] = {
          ...paymentDetails[index],
          workPeriodStart: formatDateToISO8601(startDate),
          workPeriodEnd: formatDateToISO8601(endDate),
        };

        setFieldValue(`paymentDetails`, paymentDetails);
      }
    },
    handleBlur: formik.handleBlur,
    value: getValue(values.paymentDetails[index]),
    accessor: "",
    touchedField: touchedRow?.workPeriodStart || touchedRow?.workPeriodEnd,
    errorField:
      errorRow?.workPeriodStart || errorRow?.workPeriodEnd
        ? [errorRow?.workPeriodStart, errorRow?.workPeriodEnd].join("\n")
        : "",
  };

  return (
    <td className={styles.td}>
      <div
        className={`${styles.dateFieldWrapper} ${
          !hasLabels ? styles.noLabelWrapper : ""
        }`}
      >
        <SelectInput {...{ field, ...props }} />
      </div>
      {hasApplyToAllButton && applyToAll && (
        <ApplyToAllButton
          applyToAll={() => {
            applyToAll(["workPeriodStart", "workPeriodEnd"]);
          }}
        />
      )}
    </td>
  );
};

export default DateDropdown;
