import { FieldDataType } from "pages/employer-of-record/components/AutoForm";
import {
  addValidation,
  addOptions,
} from "../../../components/AutoForm/field-utils";
import { PaymentInfoAutoFormFields } from "./payment-info-autoform-fields";
import {
  BankAccountTypeOption,
  BankAccountTypeOptions,
} from "./bankAccountTypeOptions";

const IBANAccountFormFields = (accountTypeOptions: BankAccountTypeOption[]) => [
  PaymentInfoAutoFormFields["iban"],
  addOptions(PaymentInfoAutoFormFields["accountType"], accountTypeOptions),
  PaymentInfoAutoFormFields["accountNickname"],
];

export const PaymentInfoAutoFormConfig: Record<string, FieldDataType[]> = {
  AU: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["bsbNumber"],
    PaymentInfoAutoFormFields["accountHolderName"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 9, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 4, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.AU
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  BR: [
    addValidation(PaymentInfoAutoFormFields["bankCode"], {
      text: {
        length: {
          value: 3,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
      },
    }),
    addValidation(PaymentInfoAutoFormFields["branchCode"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 4, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 4, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["accountNumber"],
    PaymentInfoAutoFormFields["accountHolderName"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.BR
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CA: [
    PaymentInfoAutoFormFields["institutionNumber"],
    PaymentInfoAutoFormFields["transitNumber"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 12, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 6, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CA
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CL: [
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 20, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 1, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["bankName"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CL
    ),
  ],
  CO: [
    PaymentInfoAutoFormFields["accountNumber"],
    PaymentInfoAutoFormFields["bankName"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CO
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CR: [
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 14, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 1, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["bankName"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CR
    ),
    PaymentInfoAutoFormFields["iban"],
  ],
  DE: IBANAccountFormFields(BankAccountTypeOptions.DE),
  DK: [
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 10, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 1, message: "validations.text.min.message" },
      },
    }),
    addValidation(PaymentInfoAutoFormFields["registrationNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 4, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 1, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  EE: IBANAccountFormFields(BankAccountTypeOptions.EE),
  ES: IBANAccountFormFields(BankAccountTypeOptions.ES),
  FR: IBANAccountFormFields(BankAccountTypeOptions.FR),
  GB: [
    PaymentInfoAutoFormFields["sortCode"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        max: { value: 8, message: "validations.text.max.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.GB
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  GR: [
    PaymentInfoAutoFormFields["bankName"],
    ...IBANAccountFormFields(BankAccountTypeOptions.GR),
  ],
  IE: IBANAccountFormFields(BankAccountTypeOptions.IE),
  IN: [
    PaymentInfoAutoFormFields["ifscCode"],
    PaymentInfoAutoFormFields["accountNumber"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.IN
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  IT: IBANAccountFormFields(BankAccountTypeOptions.IT),
  MX: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["clabe"],
    PaymentInfoAutoFormFields["accountNumber"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.MX
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  MY: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["branchCode"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 20, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 6, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  NL: IBANAccountFormFields(BankAccountTypeOptions.NL),
  PE: [
    PaymentInfoAutoFormFields["bankName"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 20, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 1, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.PE
    ),
    PaymentInfoAutoFormFields["accountNickname"],
    PaymentInfoAutoFormFields["ctsFinancialEntity"],
    PaymentInfoAutoFormFields["ctsAccountNumber"],
  ],
  PH: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["swiftCode"],
    PaymentInfoAutoFormFields["accountHolderName"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 17, message: "validations.text.max.message" },
      },
    }),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  PT: IBANAccountFormFields(BankAccountTypeOptions.PT),
  SE: [
    PaymentInfoAutoFormFields["swiftCode"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 11, message: "validations.text.max.message" },
        // t("validations.text.min.message")
      },
    }),
    PaymentInfoAutoFormFields["clearingNumber"],
    PaymentInfoAutoFormFields["iban"],
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  SG: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["swiftCode"],
    PaymentInfoAutoFormFields["accountHolderName"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 19, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 6, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  IL: [
    addValidation(PaymentInfoAutoFormFields["bankCode"], {
      text: {
        length: {
          value: 3,
          message: "validations.text.length.message",
        },
        number: {
          enabled: true,
          message: "validations.text.number.message",
        },
      },
    }),
    addValidation(PaymentInfoAutoFormFields["branchCode"], {
      text: {
        max: { value: 4, message: "validations.text.max.message" },
        min: { value: 3, message: "validations.text.min.message" },
        number: {
          enabled: true,
          message: "validations.text.number.message",
        },
      },
    }),
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        max: { value: 9, message: "validations.text.max.message" },
        min: { value: 6, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["bankCertificate"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.IL
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  RO: [
    PaymentInfoAutoFormFields["bankName"],
    ...IBANAccountFormFields(BankAccountTypeOptions.RO),
  ],
};
